import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { useMediaQueryPortraitMobile } from '../hooks/responsive-design.hook';
import { RootState } from '../store/reducers/root.reducer';
import IconComponent from './icon.component';
import SectorIndustryCountryHeaderComponent from './sector-industry-country-header.component';
import TickerCompanyIdHeaderComponent from './ticker-company-id-header.component';
import WaveSkeleton from './wave-skeleton.component';

type Props = {
  loading?: boolean;
  small?: boolean;
};

const WEB_ICON_SIZE = '5rem';
const WEB_ICON_SMALL_SIZE = '4rem';
const MOBILE_ICON_SIZE = '4rem';

const CompanyHeaderInfoComponent = ({ loading, small }: Props) => {
  const isPortraitMobile = useMediaQueryPortraitMobile();

  const currentCompanyCompanyId = useSelector(
    (state: RootState) => state.companies.currentCompany?.companyId
  );
  const currentCompanyTickerId = useSelector(
    (state: RootState) => state.companies.currentCompany?.tickerId
  );
  const currentCompanyCompanyName = useSelector(
    (state: RootState) => state.companies.currentCompany?.companyName
  );

  return (
    <Stack
      direction={isPortraitMobile ? 'column' : 'row'}
      justifyContent="space-between"
      alignItems={isPortraitMobile ? 'flex-start' : 'center'}
      spacing={isPortraitMobile ? 2 : 0}
      sx={{
        margin: isPortraitMobile
          ? '1rem 1rem 0'
          : small
          ? '0rem 1.5rem'
          : '1rem 2rem 1rem',
        ...(small ? { padding: '1rem 1rem 0rem 0rem' } : {}),
        ...(isPortraitMobile ? { marginLeft: '1.5rem !important' } : {}),
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        // spacing={isPortraitMobile ? 1 : 0}
      >
        {!!loading ? (
          <WaveSkeleton
            variant="circular"
            animation="wave"
            width={
              isPortraitMobile
                ? MOBILE_ICON_SIZE
                : small
                ? WEB_ICON_SMALL_SIZE
                : WEB_ICON_SIZE
            }
            height={
              isPortraitMobile
                ? MOBILE_ICON_SIZE
                : small
                ? WEB_ICON_SMALL_SIZE
                : WEB_ICON_SIZE
            }
          />
        ) : (
          <IconComponent
            src={currentCompanyCompanyId}
            rest={{
              style: {
                width: isPortraitMobile
                  ? MOBILE_ICON_SIZE
                  : small
                  ? WEB_ICON_SMALL_SIZE
                  : WEB_ICON_SIZE,
                height: isPortraitMobile
                  ? MOBILE_ICON_SIZE
                  : small
                  ? WEB_ICON_SMALL_SIZE
                  : WEB_ICON_SIZE,
              },
            }}
          />
        )}
        <div style={!!loading ? { marginLeft: '1rem' } : {}}>
          {!!loading ? (
            <WaveSkeleton width="12rem" height="2.2rem" />
          ) : (
            <TickerCompanyIdHeaderComponent
              tickerId={currentCompanyTickerId}
              companyId={currentCompanyCompanyId}
              small={small}
            />
          )}
          {!!loading ? (
            <WaveSkeleton width="18rem" height="2rem" />
          ) : (
            <Typography
              variant={isPortraitMobile ? 'subtitle1' : small ? 'h6' : 'h5'}
              sx={(theme) => ({
                color: theme.palette.text.primary,
                marginLeft: '1rem',
                fontSize: isPortraitMobile ? '1.3rem' : undefined,
                marginTop: isPortraitMobile ? '-0.3rem' : undefined,
              })}
            >
              {currentCompanyCompanyName}
            </Typography>
          )}
        </div>
      </Stack>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        flex="1 1 0"
        sx={{
          marginTop: '0.5rem !important',
          marginBottom: '0.5rem !important',
        }}
      >
        <SectorIndustryCountryHeaderComponent skeleton={!!loading} />
      </Stack>
    </Stack>
  );
};

export default CompanyHeaderInfoComponent;
