import { Backdrop, drawerClasses, Stack, useTheme } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import { Theme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import { IStructureMacrosection } from '@prometeus/common';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  APP_BAR_HEIGHT_REM,
  CLOSED_DRAWER_WIDTH_REM,
  MOBILE_APP_BAR_HEIGHT_REM,
  OPEN_DRAWER_WIDTH_REM,
} from '../constants/general.constants';
import { useMediaQueryPortraitMobile } from '../hooks/responsive-design.hook';
import { RootState } from '../store/reducers/root.reducer';
import { COMMON_COLORS } from '../ui/colors';
import NavigationMenuItemComponent from './navigation-menu-item.component';

type Props = {
  currentPath: string;
};

const getStyles = (theme: Theme) => ({
  /* Drawer */
  drawer: {
    width: `${OPEN_DRAWER_WIDTH_REM}rem`,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: `${OPEN_DRAWER_WIDTH_REM}rem`,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `${CLOSED_DRAWER_WIDTH_REM}rem`,
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    borderWidth: 0,
    color: theme.palette.text.primary,
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '0.5rem',
    height: '100%',
  },
  scrollableList: {
    overflowX: 'hidden',
    overflowY: 'auto',
    height: '100%',
    borderRight: `0.05rem solid ${theme.palette.divider}`,
  },
  backdropRoot: {
    zIndex: 3,
    backgroundColor: `${COMMON_COLORS.menuBackdrop} !important`,
  },
  backdropRootClosed: {
    width: `${CLOSED_DRAWER_WIDTH_REM}rem !important`,
  },
  backdropRootOpen: {
    width: '100% !important',
  },
});

const NavigationMenuComponent = (props: Props) => {
  const isPortraitMobile = useMediaQueryPortraitMobile();

  /* State Management */
  const structure = useSelector(
    (state: RootState) => state.structure.structure
  );

  const theme = useTheme();

  const navMenu = useSelector((state: RootState) => state.modals.navMenuData);

  /* Styling */
  const styles = getStyles(theme);

  /* Drawer */
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const [preventClose, setPreventClose] = useState<boolean>(false);

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    if (preventClose && navMenu?.closeTrigger !== 'click') {
    } else {
      setDrawerOpen(false);
    }
    setPreventClose(false);
  };

  useEffect(() => {
    if (navMenu?.closeTrigger === 'click') {
      setDrawerOpen(false);
    }
  }, [navMenu]);

  const open = drawerOpen || !!navMenu?.open;

  return (
    <Backdrop
      open={open}
      invisible={!open}
      onClick={open ? toggleDrawer : undefined}
      sx={{
        ...styles.backdropRoot,
        ...(open ? styles.backdropRootOpen : {}),
        ...(!open ? styles.backdropRootClosed : {}),
      }}
    >
      <Drawer
        variant="permanent"
        sx={{
          ...styles.drawer,
          [`& .${drawerClasses.paper}`]: {
            ...styles.paper,
            ...(open ? styles.drawerOpen : {}),
            ...(!open ? styles.drawerClose : {}),
          },
        }}
        onMouseEnter={openDrawer}
        onMouseLeave={closeDrawer}
        onBlur={() => {
          if (!!navMenu?.open) {
            setPreventClose(true);
          }
        }}
      >
        <Toolbar
          sx={{
            height: `${
              isPortraitMobile ? MOBILE_APP_BAR_HEIGHT_REM : APP_BAR_HEIGHT_REM
            }rem`,
          }}
        />
        <List
          disablePadding
          sx={{ ...styles.scrollableList, marginTop: '0.3rem' }}
        >
          {structure.map((e: IStructureMacrosection) => (
            <NavigationMenuItemComponent
              path={e.path}
              key={e.path}
              displayName={e.displayName}
              icon={e.icon}
              onlyIcon={open}
              current={`/${props.currentPath}` === e.path}
              sections={e.sections}
              topDivider={e.topDivider}
            />
          ))}
        </List>
        <Stack
          sx={{
            fontSize: '0.75rem',
            margin: '0.5rem',
            marginTop: 'auto',
            minHeight: '4rem',
            opacity: open ? 1 : 0,
          }}
          direction="row"
          justifyContent="flex-start"
          alignItems="end"
        >{`v${process.env.REACT_APP_PROMETEUS_VERSION}`}</Stack>
      </Drawer>
    </Backdrop>
  );
};

export default NavigationMenuComponent;
