import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { useMediaQueryPortraitMobile } from '../../../../hooks/responsive-design.hook';
import { COMMON_COLORS } from '../../../../ui/colors';
import { CURRENT_ASSETS_LIABILITIES_TITLE_LABELS } from '../../utils/visuals.config';

const InventoriesComponent = () => {
  const isPortraitMobile = useMediaQueryPortraitMobile();

  return (
    <VisualChartWrapper
      type="line"
      dataLabel="inventories"
      pageLabel="balanceSheet"
      titleLabel={CURRENT_ASSETS_LIABILITIES_TITLE_LABELS.inventories}
      useDollarsCurrency
      useFiscalYear
      isTime
      customLineColor={COMMON_COLORS.assetCharts}
      hideLegend
      smallChart={!isPortraitMobile}
      mobileHeight={12}
    />
  );
};

export default InventoriesComponent;
