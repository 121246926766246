import { HighlightOff, Warning } from '@mui/icons-material';
import { Box, Palette, useTheme } from '@mui/material';
import { COMMON_COLORS } from '../ui/colors';

type Props = {
  message?: string;
  warningIcon?: boolean;
  textColor?: string;
};

const getStyles = (palette: Palette) => ({
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    color: palette.text.primary,
    fontSize: '1.3rem',
  },
  icon: {
    marginBottom: '1rem',
  },
});

const CardNoDataComponent = (props: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme.palette);

  return (
    <Box sx={styles.container}>
      {!!props.warningIcon ? (
        <Warning
          sx={{ ...styles.icon, color: COMMON_COLORS.accent }}
          fontSize="large"
        />
      ) : (
        <HighlightOff sx={{ ...styles.icon }} fontSize="large" />
      )}
      <span
        style={{
          textAlign: 'center',
          ...(props.textColor ? { color: props.textColor } : {}),
        }}
      >
        {props.message || 'No Data Available'}
      </span>
    </Box>
  );
};

export default CardNoDataComponent;
