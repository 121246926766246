import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { ROS_ROE_TITLE_LABELS } from '../../utils/visuals.config';

const RoeComponent = () => {
  return (
    <VisualChartWrapper
      type="line"
      dataLabel="roe"
      pageLabel="managementEffectiveness"
      titleLabel={ROS_ROE_TITLE_LABELS.roe}
      isTime
      useFiscalYear
    />
  );
};

export default RoeComponent;
