import {
  IScreenerTab,
  ScreenerFilterCardType,
  ScreenerFilterNameEnum,
  ScreenerManagementEffectivenessFilterCardEnum,
} from '@prometeus/common';
import { useDispatch, useSelector } from 'react-redux';
import ResponsiveRow from '../../../components/responsive-row.component';
import ScrollFillerComponent from '../../../components/scroll-filler.component';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { ScreenerTabNameEnum } from '../../../models/screener/structure.model';
import { setFilterValue } from '../../../store/actions/screener/structure.action';
import { RootState } from '../../../store/reducers/root.reducer';
import CommonBlockComponent from '../components/common-block.component';

const ManagementEffectivenessTab = () => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  const tabData: IScreenerTab | null = useSelector((state: RootState) =>
    state.screener.structure.screenerStructure
      ? state.screener.structure.screenerStructure.managementEffectiveness
      : null
  );

  const managementEffectivenessSetFilterValue = (
    block: ScreenerFilterCardType,
    filter: ScreenerFilterNameEnum,
    value: any
  ): void => {
    dispatch(
      setFilterValue(
        ScreenerTabNameEnum.MANAGEMENT_EFFECTIVENESS,
        block,
        filter,
        value
      )
    );
  };

  return (
    <>
      <ResponsiveRow height={45} mobileHeight={34}>
        <CommonBlockComponent
          tabName={ScreenerTabNameEnum.MANAGEMENT_EFFECTIVENESS}
          cardBlock={ScreenerManagementEffectivenessFilterCardEnum.ROS}
          data={tabData?.ROS}
          setFilterValueHandler={managementEffectivenessSetFilterValue}
          isLarge={true}
        />
        <CommonBlockComponent
          tabName={ScreenerTabNameEnum.MANAGEMENT_EFFECTIVENESS}
          cardBlock={ScreenerManagementEffectivenessFilterCardEnum.ROA}
          data={tabData?.ROA}
          setFilterValueHandler={managementEffectivenessSetFilterValue}
          isLarge={true}
        />
      </ResponsiveRow>
      <ResponsiveRow height={45} mobileHeight={34}>
        <CommonBlockComponent
          tabName={ScreenerTabNameEnum.MANAGEMENT_EFFECTIVENESS}
          cardBlock={ScreenerManagementEffectivenessFilterCardEnum.ROE}
          data={tabData?.ROE}
          setFilterValueHandler={managementEffectivenessSetFilterValue}
          isLarge={true}
        />
        <CommonBlockComponent
          tabName={ScreenerTabNameEnum.MANAGEMENT_EFFECTIVENESS}
          cardBlock={ScreenerManagementEffectivenessFilterCardEnum.ROIC}
          data={tabData?.ROIC}
          setFilterValueHandler={managementEffectivenessSetFilterValue}
          isLarge={true}
        />
      </ResponsiveRow>
      {isPortraitMobile && <ScrollFillerComponent />}
    </>
  );
};

export default ManagementEffectivenessTab;
