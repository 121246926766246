import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { PageIdEnum } from '../../../models/filters.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { RootState } from '../../../store/reducers/root.reducer';
import DividendHistoryComponent from '../components/company-dividends/dividend-history.component';
import DividendTtmComponent from '../components/company-dividends/dividend-ttm.component';
import DividendYieldDistributionComponent from '../components/company-dividends/dividend-yield-distribution.component';
import DividendYieldComponent from '../components/company-dividends/dividend-yield.component';
import PayoutRatioComponent from '../components/company-dividends/payout-ratio.component';

const CompanyDividendsTab = () => {
  const dispatch = useDispatch();

  /* Charts Data */
  const dividendYieldDataUpdating = useSelector(
    (state: RootState) => state.visuals.stock.dividendYieldDataUpdating
  );
  const dividendYieldDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.stock.dividendYieldDistributionDataUpdating
  );
  const payoutRatioDataUpdating = useSelector(
    (state: RootState) => state.visuals.stock.payoutRatioDataUpdating
  );
  const dividendHistoryDataUpdating = useSelector(
    (state: RootState) => state.visuals.stock.dividendHistoryDataUpdating
  );
  const dividendTTMDataUpdating = useSelector(
    (state: RootState) => state.visuals.stock.dividendTTMDataUpdating
  );

  /* Change Report Page */
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.COMPANY_DIVIDENDS));
  }, [dispatch]);

  return (
    <>
      {dividendYieldDataUpdating &&
        dividendYieldDistributionDataUpdating &&
        dividendHistoryDataUpdating &&
        dividendTTMDataUpdating &&
        payoutRatioDataUpdating && <LoadingComponent transparent={true} />}

      <ResponsiveRow>
        <DividendYieldComponent />
        <DividendYieldDistributionComponent />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom paddingBottom={10}>
        <DividendHistoryComponent />
        <DividendTtmComponent />
        <PayoutRatioComponent />
      </ResponsiveRow>
    </>
  );
};

export default CompanyDividendsTab;
