import { PageIdEnum } from '../models/filters.model';

export type FixMeLater = any;
export const DEFAULT_WIDTH = 1920;
export const DEFAULT_HEIGHT = 1080;

/***************************************************************************************************
 *                                              Values                                             *
 ***************************************************************************************************/

export const DESCRIPTION_CLIP = 480;
export const MOBILE_DESCRIPTION_CLIP = 300;
export const NA_LABEL = '-';

/* Styles */
export const TOOLTIP_FONT_SIZE = '0.8rem';
export const TOOLTIP_ENTER_DELAY = 500;
export const BACKDROP_FILTER_GLASS = 'blur(3rem) saturate(2) !important';
export const ROOT_FONT_SIZE_PIXELS = 14;
export const MINUTES_TO_REFRESH = 2; // How many minutes before the token expires to request renewal

/* Heights */
export const TAB_BAR_HEIGHT_REM = 2;
export const APP_BAR_HEIGHT_REM = 6;
export const MOBILE_APP_BAR_HEIGHT_REM = 4;
export const COMPANY_HEADER_HEIGHT_REM = 4;

/* Fiscal Year */
export const MIN_FISCAL_YEAR = 1985; // FIXME: Make it dynamic
export const MAX_FISCAL_YEAR = new Date().getFullYear();

/* PowerBI Embedded */
export const REPORT_CONTAINER = '#report-container'; // ID of report container

/* Drawer */
export const OPEN_DRAWER_WIDTH_REM = 24;
export const CLOSED_DRAWER_WIDTH_REM = 5;
export const ADMIN_DRAWER_WIDTH_REM = 18;
export const DRAWER_Z_INDEX = 1200;

/* Screener */
export const SCREENER_DISPLAY_NAME = 'S-1';
export const SCREENER_COMPANIES_VISUAL_NAME = 'Companies';
export const SCREENER_TABLE_RESULTS_VISUAL_NAME = 'Result';
export const ROWS_PER_PAGE_LIST: string[] = ['5', '10', '25', '50'];

/* Set new icon */
export const STANDARD_ICON_SIZE = 128;

// TODO: Add this to config on server
export const DEFAULT_COMPANY = {
  ticker: 'AAPL',
  tickerId: 'AAPL.US',
};

/* Screener Tabs */
export enum ScreenerTabIndexEnum {
  STOCK = 0,
  INCOME_STATEMENT,
  MANAGEMENT_EFFECTIVENESS,
  FINANCIAL_STRENGTH,
}

export const COMMON_STYLES = {
  flexTwoOneZero: {
    flex: '2 1 0',
  },
  flexOneOneZero: {
    flex: '1 1 0',
  },
  flexDisplay: {
    display: 'flex',
  },
  centered: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardRowBottomMargin: {
    marginBottom: '2%',
  },
  cardRowLastBottomMargin: {
    marginBottom: '4.15%',
  },
};

export const OPTION_PADDING_ITEM = '0.3rem 4rem 0.3rem 1.5rem';

export const DOLLAR_ST = 'USD';

export const SCREENER_PRE_FETCHING_THRESHOLD = 2000;

export const ICONS_MAX_SIZE = 5000;

export const PREMIUM_FEATURE_TOOLTIP = 'Premium Feature';

export const CONTACT_EMAIL_ADDRESS = 'info@prometeus.finance';

export const MAX_ICON_TICKERS_CHUNK_SIZE = 20;

export const B_PAGES = new Set([
  PageIdEnum.COMPANY_SUMMARY,
  PageIdEnum.COMPANY_STOCKS,
  PageIdEnum.COMPANY_DIVIDENDS,
]);