import { Avatar, ListItemAvatar, SvgIcon } from '@mui/material';
import { useEffect, useState } from 'react';
import companyPlaceholderLogo from '../assets/images/company_placeholder.png';
import { MANAGER_ICON_D } from '../constants/svg-icons';
import { getIconShapeStyle } from '../constants/utils.constants';
import { ICachedIcon } from '../models/icons.model';

export type IconLogoProps = {
  src?: string;
  rest?: any;
  map?: Map<string, ICachedIcon>;
  defaultImage?: any;
  color?: string;
};

const IconLogo = ({ src, rest, map, defaultImage, color }: IconLogoProps) => {
  const [srcImg, setSrcImg] = useState<boolean>(false);

  useEffect(() => {
    setSrcImg(false);
  }, [src]);

  const onError = () => {
    setSrcImg(true);
  };

  if (srcImg) {
    if (defaultImage === 'manager') {
      return (
        <ListItemAvatar>
          <Avatar
            sx={{
              color: color || 'gray',
              backgroundColor: '#757575',
              ...(rest?.style || {}),
            }}
          >
            <SvgIcon>
              <path fill="currentColor" d={MANAGER_ICON_D} />
            </SvgIcon>
          </Avatar>
        </ListItemAvatar>
      );
    } else {
      return (
        <img
          src={
            !defaultImage || defaultImage === 'company'
              ? companyPlaceholderLogo
              : defaultImage
          }
          alt=""
          {...rest}
        />
      );
    }
  }

  const cachedIcon = map?.get(src || '');

  return (
    <img
      src={(!!cachedIcon ? cachedIcon?.image : src) || ''}
      onError={onError}
      {...rest}
      style={{
        ...(!!cachedIcon && getIconShapeStyle(cachedIcon.shape)),
        ...rest.style,
      }}
      alt=""
    />
  );
};

export default IconLogo;
