import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { PageIdEnum } from '../../../models/filters.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { RootState } from '../../../store/reducers/root.reducer';
import GrossMarginDistributionComponent from './components/profit-margin-and-gross-margin/gross-margin-distribution.component';
import GrossMarginComponent from './components/profit-margin-and-gross-margin/gross-margin.component';
import ProfitMarginDistributionComponent from './components/profit-margin-and-gross-margin/profit-margin-distribution.component';
import ProfitMarginComponent from './components/profit-margin-and-gross-margin/profit-margin.component';

const ProfitMarginAndGrossMarginTab = () => {
  const dispatch = useDispatch();

  /* Charts Data */
  const profitMarginDataUpdating = useSelector(
    (state: RootState) => state.visuals.incomeStatement.profitMarginDataUpdating
  );

  const profitMarginDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.profitMarginDistributionDataUpdating
  );
  const grossMarginDataUpdating = useSelector(
    (state: RootState) => state.visuals.incomeStatement.grossMarginDataUpdating
  );
  const grossMarginDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.grossMarginDistributionDataUpdating
  );

  /* Change Report Page */
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.PROFIT_MARGIN_AND_GROSS_MARGIN));
  }, [dispatch]);

  return (
    <>
      {profitMarginDataUpdating &&
        profitMarginDistributionDataUpdating &&
        grossMarginDataUpdating &&
        grossMarginDistributionDataUpdating && (
          <LoadingComponent transparent={true} />
        )}

      <ResponsiveRow>
        <ProfitMarginComponent />
        <ProfitMarginDistributionComponent />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom paddingBottom={8}>
        <GrossMarginComponent />
        <GrossMarginDistributionComponent />
      </ResponsiveRow>
    </>
  );
};

export default ProfitMarginAndGrossMarginTab;
