import { isNullUndefined, MOMENT_FORMAT } from '@prometeus/common';
import moment from 'moment';
import XLSX from 'xlsx';
import {
  ExportableDataType,
  IChartRawData,
  IStatementsConfig,
  IStatementsExport,
  IVisualChart,
  IVisualChartRank,
  IVisualConfigData,
  StatementsMap,
} from '../../models/visuals.model';
import { IAVisualChart } from '../../pages/sector-analysis/utils/visuals.model';

/***************************************************************************************************
 *                                           Handling data                                         *
 ***************************************************************************************************/
export const visualDataSplit = (
  value: string,
  columns: string[]
): { split: string[]; map: Map<string, number> } => {
  const split = value.replace(/(?:\\[r]|[\r]+)+/g, '').split('\n');
  const columnLine: string[] = split[0].split('\t');
  const columnMap = new Map<string, number>();

  columns.forEach((e: string) => {
    const columnIndex = columnLine.findIndex((col: string) => col === e);
    columnMap.set(e, columnIndex);
  });

  return {
    split: split.filter((e: string, i: number) => i > 0 && !!e),
    map: columnMap,
  };
};

export const makeDate = (value: string): number => moment(value).valueOf();

export const inverseMakeDate = (value: number | string): string =>
  moment(+value).format(MOMENT_FORMAT);

const processDatas = (
  data: IChartRawData,
  columns: string[],
  isPercentage: boolean
): {
  data1: (number | null)[];
  data2: (number | null)[];
  data3: (number | null)[];
  data4: (number | null)[];
} => {
  let data1 = data[columns[1]] as (number | null)[];
  let data2 = data[columns[2]] as (number | null)[];
  let data3 = data[columns[3]] as (number | null)[];
  let data4 = data[columns[4]] as (number | null)[];

  if (isPercentage) {
    data1 = data1?.map((e: number | null) =>
      !isNullUndefined(e) ? (e as number) * 100 : null
    );
    data2 = data2?.map((e: number | null) =>
      !isNullUndefined(e) ? (e as number) * 100 : null
    );
    data3 = data3?.map((e: number | null) =>
      !isNullUndefined(e) ? (e as number) * 100 : null
    );
    data4 = data4?.map((e: number | null) =>
      !isNullUndefined(e) ? (e as number) * 100 : null
    );
  }
  return {
    data1,
    data2,
    data3,
    data4,
  };
};

/* Columns should be in the following order:
 * label
 * data1
 * data2
 * data3
 * data4
 * currencyRate
 */
export const processVisualData = (
  data: IChartRawData,
  configData: IVisualConfigData,
  dateLabel?: boolean
): IVisualChart => {
  const { columns, isPercentage, datasetLabels, isCurrency, isDistr, isXPercentage } = configData;

  const commonReturn = {
    columns,
    datasetLabels,
  };

  try {
    let labels = data[columns[0]] as string[];
    if (!!dateLabel) {
      labels = labels.map((e: string) => `${makeDate(e)}`);
    }

    /* State update */
    return {
      ...commonReturn,
      labels,
      ...processDatas(data, columns, !!isPercentage),
      currencyRate: data['Currency Rate'] as number[],
      hasData: true,
      dateLabel: !!dateLabel,
      isCurrency: !!isCurrency,
      isPercentage: !!isPercentage,
      isDistr: !!isDistr,
      isXPercentage: !!isXPercentage,
    };
  } catch (err) {
    console.debug(columns, err);
    return {
      ...commonReturn,
      error: 'No Data Available.',
      hasData: true,
      data1: [],
      labels: [],
    };
  }
};

/* Columns should be sorted like the following */
export const processVisualRankData = (
  data: IChartRawData,
  configData: IVisualConfigData
): { full: IVisualChartRank; reduced: IVisualChartRank } => {
  try {
    const columns = configData.columns;

    // Get data
    const rank = data[columns[2]] as number[];
    const labels = data[columns[0]] as string[];
    const _data1 = data[columns[1]] as number[];
    const data1 = !!configData?.isPercentage
      ? _data1?.map((e: number) => e * 100)
      : _data1;
    const selectedTicker = (data[columns[3]] as string[])?.map(
      (e: string) => +e
    );
    const reduced = (data[columns[4]] as string[])?.map((e: string) => +e);

    const fullReducedShared = {
      hasData: true,
      columns: configData?.columns,
      datasetLabels: configData?.datasetLabels,
      isCurrency: !!configData?.isCurrency,
      isPercentage: !!configData?.isPercentage,
    };

    /* Update state */
    return {
      full: {
        labels: labels,
        data1,
        rank: rank,
        selectedTicker: selectedTicker,
        ...fullReducedShared,
      },
      reduced: {
        labels: labels?.filter((_, index: number) => !!reduced[index]),
        data1: data1?.filter((_, index: number) => !!reduced[index]),
        rank: rank?.filter((_, index: number) => !!reduced[index]),
        selectedTicker: selectedTicker?.filter(
          (_, index: number) => !!reduced[index]
        ),
        ...fullReducedShared,
      },
    };
  } catch (err) {
    console.debug(err);
    const shared = {
      hasData: true,
      error: 'No Data Available.',
      columns: configData?.columns,
      datasetLabels: configData?.datasetLabels,
      data1: [],
      labels: [],
      rank: [],
      selectedTicker: [],
    };
    return {
      full: shared,
      reduced: shared,
    };
  }
};

export const makeUpdatingVisuals = (
  state: any,
  allowedStrings: string[]
): any => {
  const set = new Set<string>(allowedStrings);
  const keys = Object.keys(state);
  const updated: {
    [key: string]: boolean;
  } = {};
  keys
    .filter((key: string) => set.has(key))
    .forEach((key: string) => {
      const keyUpdating = `${key}Updating`;
      updated[keyUpdating] = true;
    });

  return updated;
};

export const extractChartMetadata = <T extends IVisualChart>(
  data?: T
): [boolean, boolean, string | undefined] => [
    !!data?.hasData,
    !!data?.labels?.length,
    data?.error,
  ];

export const REPORT_DATE = 'Report Date';
export const FISCAL_YEAR = 'Fiscal Year';

export enum StatementsCommonRowEnum {
  REPORT_DATE = 'Report Date',
  FISCAL_YEAR = 'Fiscal Year',
  FISCAL_PERIOD = 'Fiscal Period',
  CURRENCY = 'Currency',
  CURRENCY_RATE = 'Currency Rate',
}

export const processStatementsData = (data: IChartRawData): StatementsMap => {
  const mappedValues = new Map<string, (number | string | null)[]>();

  Object.keys(data).forEach((key: string) => {
    mappedValues.set(key, data[key]);
  });

  return mappedValues;
};

export const exportExcel = (data: string, title: string): void => {
  const workSheet = XLSX.utils.aoa_to_sheet(
    data
      .replace(/(?:\\[r]|[\r]+)+/g, '')
      .split('\n')
      .map((e: string) => e.split('\t'))
  );
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet1');
  XLSX.writeFile(workBook, (title?.replaceAll(' ', '') || 'chart') + '.xlsx');
};

export const generateExcel = (data: ExportableDataType): string => {
  if ('data1' in data) {
    return generateNormalExcel(data as IVisualChart);
  } else if ('dataArr' in data) {
    return generateAExcel(data as IAVisualChart);
  } else {
    return generateStatementsExcel(data as IStatementsExport);
  }
};

const generateNormalExcel = (data: IVisualChart | IVisualChartRank): string => {
  let output: string[] = [];
  let columns = data.columns.filter((column: string) => column !== 'reduced');
  if (!!data.isCurrency) {
    columns = [...columns, 'Currency Rate'];
  }

  output.push(columns.join('\t'));
  data.labels.forEach((label: string, index: number) => {
    let row = `${!!data.dateLabel ? inverseMakeDate(label) : label}`;
    columns.slice(1).forEach((column, i: number) => {
      if (['Rank', 'selectedTicker'].includes(column)) {
        if (column === 'Rank') {
          row += `\t${(data as IVisualChartRank)?.rank[index]}`;
        } else if (column === 'selectedTicker') {
          row += `\t${(data as IVisualChartRank)?.selectedTicker[index]}`;
        }
      } else {
        const dataLabel = `data${i + 1}` as keyof IVisualChart;
        if (!isNullUndefined(data[dataLabel])) {
          row += `\t${(data[dataLabel] as any[])[index]}`;
        }
      }
    });
    if (!!data.isCurrency && !!data.currencyRate) {
      row += `\t${(data.currencyRate as number[])[index]}`;
    }
    output.push(row);
  });

  return output.join('\n');
};

const generateAExcel = (data: IAVisualChart): string => {
  let output: string[] = [];

  output.push(data.columns.join('\t'));
  data.labels.forEach((label: string, index: number) => {
    data.datasetLabels.forEach((lab, i: number) => {
      output.push(
        `${!!data.dateLabel ? inverseMakeDate(label) : label}\t${lab.label}\t${isNullUndefined(data.dataArr[i][index]) ? '' : data.dataArr[i][index]
        }`
      );
    });
  });

  return output.join('\n');
};

const generateStatementsExcel = (data: IStatementsExport): string => {
  let output: string[] = [];
  const { statements, statementsConfig } = data;

  [
    {
      label: StatementsCommonRowEnum.FISCAL_YEAR,
      column: StatementsCommonRowEnum.FISCAL_YEAR,
      hierarchy: -1,
    },
    {
      label: StatementsCommonRowEnum.REPORT_DATE,
      column: StatementsCommonRowEnum.REPORT_DATE,
      hierarchy: -1,
    },
    ...statementsConfig,
    {
      label: StatementsCommonRowEnum.CURRENCY_RATE,
      column: StatementsCommonRowEnum.CURRENCY_RATE,
      hierarchy: -1,
    },
  ].forEach((e: IStatementsConfig) => {
    let row = e.label;
    statements
      .get(e.column)
      ?.reverse()
      .forEach((value) => {
        row += `\t${isNullUndefined(value) ? '' : value}`;
      });
    output.push(row);
  });

  return output.join('\n');
};
