import VisualChartWrapper from '../../../../../components/charts/visual-chart-wrapper.component';
import { PROFIT_MARGIN_AND_GROSS_MARGIN_TITLE_LABELS } from '../../../utils/visuals.config';

const GrossMarginComponent = () => {
  return (
    <VisualChartWrapper
      dataLabel="grossMargin"
      pageLabel="incomeStatement"
      titleLabel={PROFIT_MARGIN_AND_GROSS_MARGIN_TITLE_LABELS.grossMargin}
      type="line"
      isTime
      useFiscalYear
    />
  );
};

export default GrossMarginComponent;
