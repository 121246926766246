import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import VisualChartComponent from '../../../components/charts/visual-chart.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { BAR_CHART_PLUGINS } from '../../../constants/charts/options.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
} from '../../../constants/charts/utils.constants';
import { RootState } from '../../../store/reducers/root.reducer';
import { CHART_OPTIONS } from '../utils/visuals.config';
import {
  createAChartDatasetsConfig,
  extractChartMetadata,
  getEmptyOptionMessage,
  makeDistributionTitle,
} from '../utils/visuals.constants';
import {
  ILabelValue,
  PageLabelType,
  PositionType,
} from '../utils/visuals.model';

type Props = {
  position: PositionType;
  pageLabel: PageLabelType;
  hasSelectedElements: () => boolean;
  noMarginBottom?: boolean;
  paddingBottom?: number;
};

const SectorAnalysisRowWrapper = ({
  position,
  pageLabel,
  hasSelectedElements,
  noMarginBottom,
  paddingBottom,
}: Props) => {
  const dispatch = useDispatch();

  const [metric, setMetric] = useState<ChartData>();
  const [metricDistribution, setMetricDistribution] = useState<ChartData>();
  const [metricTitle, setMetricTitle] = useState<string>('');

  /* State */
  const metricData = useSelector(
    (state: RootState) =>
      (state.visuals.sector as any)[`${position}${pageLabel}Data`]
  );
  const metricDataUpdating = useSelector(
    (state: RootState) =>
      (state.visuals.sector as any)[`${position}${pageLabel}DataUpdating}`]
  );
  const metricDistributionData = useSelector(
    (state: RootState) =>
      (state.visuals.sector as any)[`${position}Distribution${pageLabel}Data`]
  );
  const metricDistributionDataUpdating = useSelector(
    (state: RootState) =>
      (state.visuals.sector as any)[
        `${position}Distribution${pageLabel}DataUpdating}`
      ]
  );

  /* Common state */
  const metricOption = useSelector(
    (state: RootState) => (state.visuals.sector as any)[`${position}Option`]
  );
  const timeWindow = useSelector(
    (state: RootState) => state.visuals.sector.timeWindow
  );
  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );

  /* Chart data */
  const [metricHasData, metricHasLabels, metricError] =
    extractChartMetadata(metricData);
  useEffect(() => {
    setMetric({
      datasets: createAChartDatasetsConfig(metricData, screenWidth, {
        isTime: true,
        timeWindow,
      }),
    });
  }, [metricData, timeWindow, screenWidth]);

  /* Chart Distribution data */
  const [
    metricDistributionHasData,
    metricDistributionHasLabels,
    metricDistributionError,
  ] = extractChartMetadata(metricDistributionData);
  useEffect(() => {
    setMetricDistribution({
      labels: metricDistributionData?.isXPercentage
        ? metricDistributionData?.labels?.map(
            (label: string) => `${(+label * 100).toFixed(1)} %`
          )
        : metricDistributionData?.labels,
      datasets: createAChartDatasetsConfig(
        metricDistributionData,
        screenWidth,
        {
          isDistr: true,
        }
      ),
    });
  }, [metricDistributionData, screenWidth]);

  const makeErrorMessage = (
    option: boolean,
    hasLabels: boolean,
    error?: string
  ): string | undefined =>
    !option || !hasSelectedElements()
      ? getEmptyOptionMessage(pageLabel, hasSelectedElements(), !!option)
      : !!error
      ? GENERAL_ERROR_MESSAGE
      : !hasLabels
      ? GENERAL_NO_DATA_MESSAGE
      : undefined;

  useEffect(() => {
    if (!!metricOption) {
      setMetricTitle(
        CHART_OPTIONS.find((e: ILabelValue) => e.value === metricOption)
          ?.label || ''
      );
    }
  }, [dispatch, metricOption]);

  return (
    <ResponsiveRow
      noMarginBottom={noMarginBottom}
      paddingBottom={paddingBottom}
      height={47}
    >
      <VisualChartComponent
        type="line"
        data={metric}
        error={makeErrorMessage(!!metricOption, metricHasLabels, metricError)}
        warningIcon={!metricOption || !hasSelectedElements()}
        titleLabel={metricTitle}
        hasData={!!metricOption && hasSelectedElements() ? metricHasData : true}
        exportData={metricData}
        showLegend={true}
        updating={!!metricDataUpdating}
      />
      <VisualChartComponent
        type="bar"
        data={metricDistribution}
        error={makeErrorMessage(
          !!metricOption,
          metricDistributionHasLabels,
          metricDistributionError
        )}
        warningIcon={!metricOption || !hasSelectedElements()}
        titleLabel={metricTitle ? makeDistributionTitle(metricTitle) : ''}
        hasData={
          !!metricOption && hasSelectedElements()
            ? metricDistributionHasData
            : true
        }
        isPercentage={metricDistributionData?.isPercentage}
        isDistr={true}
        exportData={metricDistributionData}
        showLegend={true}
        xAxisType="category"
        options={BAR_CHART_PLUGINS}
        updating={!!metricDistributionDataUpdating}
      />
    </ResponsiveRow>
  );
};

export default SectorAnalysisRowWrapper;
