import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { PageIdEnum } from '../../../models/filters.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { RootState } from '../../../store/reducers/root.reducer';
import DebtPaybackDistributionComponent from '../components/debt-to-equity-debt-payback/debt-payback-distribution.component';
import DebtPaybackComponent from '../components/debt-to-equity-debt-payback/debt-payback.component';
import DebtToEquityDistributionComponent from '../components/debt-to-equity-debt-payback/debt-to-equity-distribution.component';
import DebtToEquityComponent from '../components/debt-to-equity-debt-payback/debt-to-equity.component';

const DebtToEquityDebtPaybackTab = () => {
  const dispatch = useDispatch();

  /* Chart data updating */
  const deDataUpdating = useSelector(
    (state: RootState) => state.visuals.financialStrength.deDataUpdating
  );
  const deDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.financialStrength.deDistributionDataUpdating
  );
  const debtPaybackDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.financialStrength.debtPaybackDataUpdating
  );
  const debtPaybackDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.financialStrength.debtPaybackDistributionDataUpdating
  );

  /* Change Report Page */

  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.DEBT_TO_EQUITY_DEBT_PAYBACK));
  }, [dispatch]);

  return (
    <>
      {deDataUpdating &&
        deDistributionDataUpdating &&
        debtPaybackDataUpdating &&
        debtPaybackDistributionDataUpdating && (
          <LoadingComponent transparent={true} />
        )}
      <ResponsiveRow>
        <DebtToEquityComponent />
        <DebtToEquityDistributionComponent />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom paddingBottom={7}>
        <DebtPaybackComponent />
        <DebtPaybackDistributionComponent />
      </ResponsiveRow>
    </>
  );
};

export default DebtToEquityDebtPaybackTab;
