import VisualChartWrapper from '../../../../../components/charts/visual-chart-wrapper.component';
import { BAR_CHART_PLUGINS } from '../../../../../constants/charts/options.constants';
import { REVENUE_AND_PROFIT_TITLE_LABELS } from '../../../utils/visuals.config';

const RevenueVsIncomeComponent = () => {
  return (
    <VisualChartWrapper
      dataLabel="revenueVsIncome"
      pageLabel="incomeStatement"
      titleLabel={REVENUE_AND_PROFIT_TITLE_LABELS.revenueVsIncome}
      type="bar"
      chartOptions={BAR_CHART_PLUGINS}
      useDollarsCurrency
      isTime
      isBar
      useFiscalYear
    />
  );
};

export default RevenueVsIncomeComponent;
