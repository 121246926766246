import { Business, Replay, Search, Visibility } from '@mui/icons-material';
import {
  Button,
  Palette,
  Stack,
  Typography,
  buttonClasses,
  useTheme,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { PeerGroupEnum } from '../../../models/peer-group.model';
import { openTableDialog } from '../../../store/actions/modals.action';
import {
  applyFilters,
  resetScreenerTabFilters,
  selectCountries,
  selectIndustries,
  selectSectors,
  setScreenerPeerGroup,
  setupNotActiveLicenceCountries,
} from '../../../store/actions/screener/structure.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { COMMON_COLORS } from '../../../ui/colors';
import { formBorder, maskBorderShadow, pxToRem } from '../../../ui/functions';
import OutlinedButton from '../../outlined-button.component';
import ButtonGroupComponent from '../filter-components/button-group.component';
import CountrySelectorComponent from '../filter-components/country-selector.component';
import FilterTitleComponent from '../filter-components/filter-title.component';
import SectorIndustrySelectorComponent from '../filter-components/sector-industry-selector.component';
import FiltersCardComponent from '../filters-card.component';

type Props = {
  parentHistory: RouteComponentProps['history'];
};

const getStyles = (palette: Palette) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
  },
  typographyStyle: {
    textAlign: 'left' as 'left',
    marginTop: '1rem',
  },
  buttonRoot: {
    padding: '0.7rem 0rem !important',
    borderRadius: '2rem !important',
  },
  halfButton: {
    flex: '1 1 0',
    marginRight: '1.5rem',
    '&:last-child': {
      marginRight: '0rem',
    },
    '& .MuiButton-label': {
      height: '2rem',
    },
  },
  buttonsContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '1.5rem',
    // marginTop: '1rem',
  },
  divider: {
    backgroundColor: palette.divider,
    marginTop: '1.5rem',
    marginBottom: '1.8rem',
    height: pxToRem(1),
  },
  externalContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '5rem 1rem 1rem',
  },
  externalButton: {
    margin: '0.5rem 1rem',
    marginTop: 0,
    padding: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& .MuiButton-label': {
      height: '2.2rem',
    },
    // '& button:not(button:last-child)': {
    //   marginBottom: '1rem',
    // },
  },
  iconButton: {
    marginBottom: '0.2rem',
    marginLeft: pxToRem(8),
    marginRight: pxToRem(-4),
    '& .MuiSvgIcon-root': {
      fontSize: '1.5rem',
    },
  },
  disabledButton: {
    '&.Mui-disabled': {
      color: palette.text.primary,
      opacity: 0.5,
    },
  },
  disabledMessage: {
    color: COMMON_COLORS.error,
    textAlign: 'center',
    fontSize: '0.9rem',
  },
  loading: {
    textAlign: 'center',
  },
  formControl: {
    width: '100%',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: '0.5rem',
    '&  label': {
      '&:first-child': {
        marginRight: '3rem',
      },
      flex: '1 1 0',
    },
  },
  fab: {
    marginLeft: '1.5rem',
  },
  outlinedButton: {
    flex: '1 1 0',
    ...maskBorderShadow(palette, true),
    color: palette.text.primary,
    borderColor: COMMON_COLORS.lightBlue,
    backgroundColor: palette.background.paper,
    '&:hover': {
      ...maskBorderShadow(palette, true),
      backgroundColor: palette.background.paper,
      color: 'white',
    },
  },
  containedButton: {
    border: formBorder(palette),
  },
  badge: {
    fontSize: '0.9rem',
  },
});

const ScreenerFiltersComponent = (props: Props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const styles = getStyles(theme.palette);

  /* Get data from state manager */
  const sectors = useSelector(
    (state: RootState) => state.screener.structure.sectors
  );
  const selectedIndustries = useSelector(
    (state: RootState) => state.screener.structure.selectedIndustries
  );
  const selectedCountries = useSelector(
    (state: RootState) => state.screener.structure.selectedCountries
  );
  const filteredTickers = useSelector(
    (state: RootState) => state.screener.structure.filteredTickers
  );
  const peerGroup = useSelector(
    (state: RootState) => state.screener.structure.peerGroup
  );
  const hasActiveLicence = useSelector(
    (state: RootState) => state.authentication.hasActiveLicence
  );

  const [
    setupNotActiveLicenceCountriesDone,
    setSetupNotActiveLicenceCountriesDone,
  ] = useState<boolean>();
  useEffect(() => {
    if (
      !hasActiveLicence &&
      !setupNotActiveLicenceCountriesDone &&
      selectedCountries.length
    ) {
      dispatch(setupNotActiveLicenceCountries());
      setSetupNotActiveLicenceCountriesDone(true);
    }
  }, [
    dispatch,
    setupNotActiveLicenceCountriesDone,
    hasActiveLicence,
    selectedCountries,
  ]);

  const countryIdsAction = useCallback(
    (optionIndex: number) => {
      if (hasActiveLicence) {
        dispatch(selectCountries(optionIndex));
      }
    },
    [dispatch, hasActiveLicence]
  );

  /***************************************
   *            Show Companies           *
   ***************************************/

  const onShowCompanies = () => {
    dispatch(openTableDialog('screener'));
  };

  const onApplyFilters = () => {
    dispatch(applyFilters());
  };
  /***************************************
   *             Reset Filters           *
   ***************************************/
  const resetCountrySectorIndustryHandler = useCallback(() => {
    dispatch(selectSectors(-1));
    dispatch(selectCountries(-1));
    dispatch(applyFilters());
  }, [dispatch]);

  const resetAllFilters = (): void => {
    dispatch(resetScreenerTabFilters(-1));
    if (hasActiveLicence) {
      resetCountrySectorIndustryHandler();
    }
  };

  return (
    <FiltersCardComponent>
      {/* Peer Group */}
      <Stack direction="column" spacing={2}>
        <FilterTitleComponent title="Peer Group" />
        <ButtonGroupComponent
          data={[
            { key: 'Regional', value: PeerGroupEnum.REGIONAL },
            { key: 'International', value: PeerGroupEnum.INTERNATIONAL },
          ]}
          onClick={(value: string) => {
            dispatch(setScreenerPeerGroup(value as PeerGroupEnum));
          }}
          isButtonSelected={(value: string) => value === peerGroup}
          isDisabled={(key: string) =>
            !hasActiveLicence && key === 'International'
          }
          groupSx={{
            '& button': {
              fontSize: '1rem !important',
            },
          }}
        />
      </Stack>

      {/* Country Sectory Industry */}
      <Stack direction="column" spacing={0}>
        <FilterTitleComponent title="Countries, Sectors & Industries" />

        <CountrySelectorComponent
          label="Countries"
          placeholder="Select countries"
          dataList={selectedCountries}
          onClickCheckHandler={countryIdsAction}
          onCloseHandler={() => {}}
        />
        <SectorIndustrySelectorComponent
          label="Sectors & Industries"
          placeholder="Select sectors & industries"
          dataList={sectors}
          sectorAction={selectSectors}
          industryAction={selectIndustries}
          selectedIndustries={selectedIndustries?.length || 0}
          onCloseHandler={() => {}}
        />
      </Stack>

      {/* Actions */}
      <Stack direction="column" spacing={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <FilterTitleComponent title="Filtered companies:" />
          <Stack direction="row" alignItems="center">
            <Business sx={{ fontSize: '2rem', marginRight: '0.5rem' }} />
            <Typography
              sx={{ fontSize: '1.4rem', fontWeight: '700 !important' }}
            >
              {filteredTickers?.length || 0}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2}>
          <OutlinedButton
            label="Apply Filters"
            onClick={onApplyFilters}
            buttonProps={{ endIcon: <Search /> }}
          />
          <OutlinedButton
            label="Reset Filters"
            onClick={resetAllFilters}
            buttonProps={{ endIcon: <Replay /> }}
          />
        </Stack>
        <Stack direction="row" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            sx={{
              ...styles.buttonRoot,
              width: 'max-content',
              paddingLeft: '3rem !important',
              paddingRight: '3rem !important',
              [`& .${buttonClasses.endIcon}`]: styles.iconButton,
              [`& .${buttonClasses.disabled}`]: styles.disabledButton,
            }}
            endIcon={<Visibility />}
            onClick={onShowCompanies}
            disabled={!filteredTickers?.length}
          >
            Show Companies
          </Button>
        </Stack>
      </Stack>
    </FiltersCardComponent>
  );
};

export default ScreenerFiltersComponent;
