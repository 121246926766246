import { Box, Stack } from '@mui/material';
import {
  IScreenerBetween,
  IScreenerSelect,
  IScreenerSlider,
  ScreenerFilterNameEnum,
} from '@prometeus/common';
import CardComponent from '../../../components/card.component';
import BetweenSliderComponent, {
  BetweenValuesType,
} from '../../../components/filters/screener/between.component';
import SelectItemComponent from '../../../components/filters/screener/select-item.component';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { BlockProps } from '../utils/constants';
import { screenerStyles } from '../utils/styles';
import {
  onBetweenValuesHandler,
  onMaxBetweenValueHandler,
  onMinBetweenValueHandler,
} from '../utils/tab-change.handler';

interface CommonBlockProps extends BlockProps {
  isLarge?: boolean;
  isStock?: boolean;
  column?: boolean;
}

const CommonBlockComponent = ({
  tabName,
  cardBlock,
  data,
  setFilterValueHandler,
  isLarge,
  isStock,
  column,
}: CommonBlockProps) => {
  const isPortraitMobile = useMediaQueryPortraitMobile();
  const isPortraitMobileOrColumn = isPortraitMobile || column;

  return (
    <CardComponent
      containerHeight={true}
      tabName={tabName}
      cardBlock={cardBlock}
      activeFilters={!!data?.active}
      titleLabel={data?.boxLabel || ''}
      isFilterCard
    >
      <Stack
        direction={isPortraitMobileOrColumn ? 'column' : 'row'}
        alignItems={isPortraitMobileOrColumn ? 'flex-start' : 'center'}
        justifyContent="space-around"
        sx={
          isPortraitMobileOrColumn
            ? { padding: '1.5rem', paddingTop: '0.5rem' }
            : { padding: '2rem 1.5rem 0rem' }
        }
      >
        <Box
          sx={
            isPortraitMobileOrColumn
              ? { width: '100%' }
              : { flex: isStock ? '1.2 1 0' : '1 1 0' }
          }
        >
          <SelectItemComponent
            label={(data?.components[0] as IScreenerSelect).label || ''}
            value={data?.peerGroupOperator}
            list={(data?.components[0] as IScreenerSelect).list || []}
            onSelect={(value: string) => {
              setFilterValueHandler(
                cardBlock,
                ScreenerFilterNameEnum.PEER_GROUP_OPERATOR,
                value
              );
            }}
          />
        </Box>
        <Stack
          direction="column"
          spacing={2}
          sx={{
            ...(isPortraitMobileOrColumn
              ? { width: '100%', marginTop: '1rem' }
              : { flex: '1 1 0' }),
            ...(!!isLarge ? screenerStyles.firstChildMarginBottom : {}),
          }}
        >
          <BetweenSliderComponent
            containerSx={screenerStyles.betweenContainer}
            title={(data?.components[2] as IScreenerBetween).label}
            step={(data?.components[2] as IScreenerBetween).step}
            min={(data?.components[2] as IScreenerBetween).min || 0}
            minValue={data?.minYear as number}
            setMinValue={(value: number) => {
              onMinBetweenValueHandler(
                setFilterValueHandler,
                cardBlock,
                value,
                true
              );
            }}
            max={(data?.components[2] as IScreenerBetween).max || 0}
            maxValue={data?.maxYear as number}
            setMaxValue={(value: number) => {
              onMaxBetweenValueHandler(
                setFilterValueHandler,
                cardBlock,
                value,
                true
              );
            }}
            setValues={(values: BetweenValuesType) => {
              onBetweenValuesHandler(
                setFilterValueHandler,
                cardBlock,
                values,
                true
              );
            }}
          />
          <BetweenSliderComponent
            title={(data?.components[1] as IScreenerSlider).label || ''}
            step={(data?.components[1] as IScreenerBetween).step}
            min={(data?.components[1] as IScreenerBetween).min || 0}
            minValue={data?.thresholdGreater as number}
            setMinValue={(value: number) => {
              onMinBetweenValueHandler(setFilterValueHandler, cardBlock, value);
            }}
            max={(data?.components[1] as IScreenerBetween).max || 0}
            maxValue={data?.thresholdLess as number}
            setMaxValue={(value: number) => {
              onMaxBetweenValueHandler(setFilterValueHandler, cardBlock, value);
            }}
            setValues={(values: BetweenValuesType) => {
              onBetweenValuesHandler(setFilterValueHandler, cardBlock, values);
            }}
            containerSx={screenerStyles.verticalSliderBoxContainer}
            isPercent={!!(data?.components[1] as IScreenerSlider).isPercent}
          />
        </Stack>
      </Stack>
    </CardComponent>
  );
};

export default CommonBlockComponent;
