import VisualChartWrapper from '../../../../../components/charts/visual-chart-wrapper.component';
import { OPERATING_EXPENSES_TITLE_LABELS } from '../../../utils/visuals.config';

const AverageOperationExpensesDistributionComponent = () => {
  return (
    <VisualChartWrapper
      dataLabel="averageOperationExpensesDistribution"
      pageLabel="incomeStatement"
      titleLabel={
        OPERATING_EXPENSES_TITLE_LABELS.averageOperationExpensesDistribution
      }
      type="pie"
      useDollarsCurrency
      isPie
      useFiscalYear
      chartOptions={{
        hover: {
          mode: 'nearest',
          intersect: true,
        },
        scales: {
          y: {
            display: false,
          },
        },
      }}
    />
  );
};

export default AverageOperationExpensesDistributionComponent;
