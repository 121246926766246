import { IStructureMacrosection, IStructureSection } from '@prometeus/common';
import { STRUCTURE_CONFIG } from '../../constants/config/structure.config';
import {
  DEFAULT_HEIGHT,
  DEFAULT_WIDTH,
} from '../../constants/general.constants';
import {
  DispatchActionTypes,
  ISectionsMap,
  IStructureState,
  SET_DISPLAY_NAME,
  SET_OPEN_MOBILE_FILTER_DIALOG,
  SET_OPEN_MOBILE_MENU,
  SET_SCREEN_SIZE,
  SET_STRUCTURE_ERROR,
} from '../../models/structure.model';

const processStructureData = (
  structure: IStructureMacrosection[]
): ISectionsMap => {
  const configPages: IStructureMacrosection[] = [...structure];

  const sections: ISectionsMap = {};

  // Loop through macro sections
  configPages.forEach((config) => {
    // Get macro section id
    const identifier = `${config.id}-`;

    // Loop through sections
    config.sections.forEach((section: IStructureSection) => {
      // Page identifier
      const secIdentifier = identifier + section.id;

      sections[secIdentifier] = {
        filters: section.filters,
        timeframeFilterQY: section?.timeframeFilterQY,
        timeframeFilterDWMQ: section?.timeframeFilterDWMQ,
        changeCurrency: section?.changeCurrency,
      };
    });
  });

  return sections;
};

export const initialStructureState: IStructureState = {
  structure: STRUCTURE_CONFIG,
  sections: processStructureData(STRUCTURE_CONFIG),
  displayName: '',
  loading: false,
  screenWidth: DEFAULT_WIDTH,
  screenHeight: DEFAULT_HEIGHT,

  openMobileMenu: false,
  openMobileFilterDialog: false
};

const structureReducer = (
  state: IStructureState = initialStructureState,
  action: DispatchActionTypes
): IStructureState => {
  switch (action.type) {
    case SET_SCREEN_SIZE:
      return {
        ...state,
        screenWidth: action.payload[0],
        screenHeight: action.payload[1],
      };

    case SET_DISPLAY_NAME:
      return {
        ...state,
        displayName: action.payload,
      };

    case SET_STRUCTURE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case SET_OPEN_MOBILE_MENU:
      return {
        ...state,
        openMobileMenu: !!action.payload,
      };
    case SET_OPEN_MOBILE_FILTER_DIALOG:
      return {
        ...state,
        openMobileFilterDialog: !!action.payload,
      };
    default:
      return state;
  }
};

export default structureReducer;
