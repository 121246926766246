import SideFiltersComponent from '../../components/filters/aggregated-filters/side-filters.component';
import PageSkeletonComponent, {
  ITab,
} from '../../components/page-skeleton/page-skeleton.component';
import { RouteEnum } from '../../navigation/routes.constants';

import CashflowTab from './tabs/cashflow.tab';
import CFStatementsTab from './tabs/statements.tab';

export enum CashFlowAnalysisRouteEnum {
  CASH_FLOW = 'cash-flow',
  CASH_FLOW_STATEMENT = 'cash-flow-statement',
}

const tabs: ITab[] = [
  {
    label: 'Cash Flow',
    route: CashFlowAnalysisRouteEnum.CASH_FLOW,
    component: CashflowTab,
  },
  {
    label: 'Cash Flow Statement',
    route: CashFlowAnalysisRouteEnum.CASH_FLOW_STATEMENT,
    component: CFStatementsTab,
  },
];

const CashFlowAnalysisPage = () => {
  return (
    <PageSkeletonComponent
      pageRoute={RouteEnum.CASH_FLOW_ANALYSIS}
      tabs={tabs}
      filtersComponent={SideFiltersComponent}
    />
  );
};

export default CashFlowAnalysisPage;
