import VisualChartWrapper from '../../../../../components/charts/visual-chart-wrapper.component';
import { OPERATING_EXPENSES_TITLE_LABELS } from '../../../utils/visuals.config';

const OperationExpensesRDComponent = () => {
  return (
    <VisualChartWrapper
      dataLabel="operationExpensesRD"
      pageLabel="incomeStatement"
      titleLabel={OPERATING_EXPENSES_TITLE_LABELS.operationExpensesRD}
      type="line"
      useDollarsCurrency
      isTime
      useFiscalYear
    />
  );
};

export default OperationExpensesRDComponent;
