import {
  Button,
  buttonClasses,
  ButtonProps,
  SxProps,
  useTheme,
} from '@mui/material';
import { COMMON_COLORS } from '../ui/colors';
import { maskBorderShadow, pxToRem } from '../ui/functions';

type Props = {
  label: string;
  onClick: () => void;
  sx?: SxProps;
  buttonProps?: ButtonProps;
};

const OutlinedButton = ({ label, sx, onClick, buttonProps }: Props) => {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      sx={{
        padding: '0.7rem 0rem !important',
        borderRadius: '2rem !important',
        flex: '1 1 0',
        ...maskBorderShadow(theme.palette, true),
        color: theme.palette.text.primary,
        borderColor: COMMON_COLORS.lightBlue,
        backgroundColor: theme.palette.background.paper,
        '&:hover': {
          ...maskBorderShadow(theme.palette, true),
          backgroundColor: theme.palette.background.paper,
          color: 'white',
        },
        //
        [`& .${buttonClasses.endIcon}`]: {
          marginBottom: '0.2rem',
          marginLeft: pxToRem(8),
          marginRight: pxToRem(-4),
          '& .MuiSvgIcon-root': {
            fontSize: '1.5rem',
          },
        },
        //
        ...sx,
      }}
      onClick={onClick}
      {...buttonProps}
    >
      {label}
    </Button>
  );
};

export default OutlinedButton;
