import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { PageIdEnum } from '../../../models/filters.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { RootState } from '../../../store/reducers/root.reducer';
import LongTermInvestmentsReceivablesComponent from '../components/noncurrent-asset-and-liabilities/long-term-investments-receivables.component';
import LongTermDebtComponent from '../components/noncurrent-asset-and-liabilities/lont-term-debt.component';
import NonCurrentAssetsComponent from '../components/noncurrent-asset-and-liabilities/noncurrent-assets.component';
import NonCurrentLiabilitiesComponent from '../components/noncurrent-asset-and-liabilities/noncurrent-liabilities.component';
import OtherLongTermAssetsComponent from '../components/noncurrent-asset-and-liabilities/other-long-term-assets.component';
import OtherLongTermLiabilitiesComponent from '../components/noncurrent-asset-and-liabilities/other-long-term-liabilities.component';
import PropertyPlantEquipmentComponent from '../components/noncurrent-asset-and-liabilities/property-plant-equipment.component';

const NoncurrentAssetAndLiabilitiesTab = () => {
  const dispatch = useDispatch();
  const isPortaitMobile = useMediaQueryPortraitMobile();

  /* Charts Data Updating */
  const nonCurrentAssetsDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.nonCurrentAssetsDataUpdating
  );
  const propertyPlantEquipmentDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.propertyPlantEquipmentDataUpdating
  );
  const longTermInvestmentsReceivablesDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.longTermInvestmentsReceivablesDataUpdating
  );
  const otherLongTermAssetsDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.otherLongTermAssetsDataUpdating
  );
  const nonCurrentLiabilitiesDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.nonCurrentLiabilitiesDataUpdating
  );
  const longTermDebtDataUpdating = useSelector(
    (state: RootState) => state.visuals.balanceSheet.longTermDebtDataUpdating
  );
  const otherLongTermLiabilitiesDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.otherLongTermLiabilitiesDataUpdating
  );

  /* Change Report Page */
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.NONCURRENT_ASSETS_AND_LIABILITIES));
  }, [dispatch]);

  return (
    <>
      {nonCurrentAssetsDataUpdating &&
        nonCurrentLiabilitiesDataUpdating &&
        propertyPlantEquipmentDataUpdating &&
        longTermInvestmentsReceivablesDataUpdating &&
        longTermDebtDataUpdating &&
        otherLongTermLiabilitiesDataUpdating &&
        otherLongTermAssetsDataUpdating && (
          <LoadingComponent transparent={true} />
        )}
      {isPortaitMobile ? (
        <>
          <ResponsiveRow paddingBottom={8}>
            <Typography variant="h5" sx={{ marginLeft: '1rem' }}>
              Assets
            </Typography>
            <NonCurrentAssetsComponent />
            <PropertyPlantEquipmentComponent />
            <LongTermInvestmentsReceivablesComponent />
            <OtherLongTermAssetsComponent />

            <Typography
              variant="h5"
              sx={{ marginLeft: '1rem', marginTop: '1rem' }}
            >
              Liabilities
            </Typography>
            <NonCurrentLiabilitiesComponent />
            <LongTermDebtComponent />
            <OtherLongTermLiabilitiesComponent />
          </ResponsiveRow>
        </>
      ) : (
        <>
          <ResponsiveRow sx={{ height: '40%' }}>
            <NonCurrentAssetsComponent />
            <NonCurrentLiabilitiesComponent />
          </ResponsiveRow>
          <div style={{ height: '52%' }}>
            <ResponsiveRow>
              <PropertyPlantEquipmentComponent />
              <LongTermInvestmentsReceivablesComponent />
              <LongTermDebtComponent />
              <OtherLongTermLiabilitiesComponent />
            </ResponsiveRow>
            <ResponsiveRow noMarginBottom>
              <OtherLongTermAssetsComponent />
              <div style={{ flex: '3 1 0', margin: '0 3rem' }}></div>
            </ResponsiveRow>
          </div>
        </>
      )}
    </>
  );
};

export default NoncurrentAssetAndLiabilitiesTab;
