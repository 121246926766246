import { ArrowDropDown, ArrowDropUp, Close, Search } from '@mui/icons-material';
import { InputAdornment, Palette, Stack, useTheme } from '@mui/material';
import { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import React, { MutableRefObject, Ref, useState } from 'react';
import { useSelector } from 'react-redux';
import { ScaleLoader } from 'react-spinners';
import { SCALE_LOADER_PROPS } from '../../../constants/loading';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { RootState } from '../../../store/reducers/root.reducer';
import { pxToRem } from '../../../ui/functions';
import TextField from '../../text-field.component';
import TooltipIconButton from '../../tooltip-icon-button.component';
import SearchCntSecIndFilterComponent from './search-cnt-sec-ind-filter.component';

const getStyles = (palette: Palette) => ({
  button: {
    '&:last-child': {
      marginLeft: '0.2rem',
      marginRight: '-0.5rem',
    },
  },
  paddingInput: {
    '& input': { padding: '1.5rem 0rem' },
  },
  backgroundIssues: {
    backgroundColor: 'transparent !important',
    '& fieldset': {
      backgroundColor: palette.background.default,
      zIndex: -1,
    },
  },
  notchedTextField: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',

      /* Pivels to Rem */
      top: pxToRem(-5),
      padding: `0 ${pxToRem(8)}`,
      '& legend': {
        maxWidth: pxToRem(1000),
        height: '0.3rem',
        '& span': {
          paddingLeft: pxToRem(5),
          paddingRight: pxToRem(5),
        },
      },
    },
  },
});

type Props = {
  label: string;
  placeholder: string;
  textFieldStyle: any;
  sx?: any; // SxProps;
  searchInputValue: string;
  setSearchInputValue: (value: string) => void;
  upFocusEndAdornment?: boolean;
  notArrowAdornment?: boolean;
  hasFilters?: boolean;
  hasFiltersType?: 'searchBar' | 'peerGroup' | 'modal';
  // Optional
  dataListLength?: number; // Used in AccordionCheckboxSelectorComponent
  setExpandedElements?: (value: React.SetStateAction<boolean[]>) => void; // Used in AccordionCheckboxSelectorComponent
  inputRef?: MutableRefObject<any>;
  isHeaderField?: boolean;
  icon?: any;
  onClick?: () => void;
  onChange?: (event: any) => void;
  // From autocomplete
  autoCompleteRef?: Ref<any>;
  inputProps?: object;
};

export const SearchInputComponent = (props: Props) => {
  const [searchFiltersOpen, setSearchFiltersOpen] = useState<boolean>(false);
  const theme = useTheme();

  const loadingSearchCompanies = useSelector(
    (state: RootState) => state.companies.loadingSearchCompanies
  );

  const styles = getStyles(theme.palette);

  /* Responsive design */
  const isPortraitMobile = useMediaQueryPortraitMobile();

  return (
    <TextField
      inputRef={props.inputRef}
      InputProps={{
        ref: props.autoCompleteRef,
        sx: {
          ...styles.paddingInput,
          ...(!props.hasFiltersType
            ? { ...styles.notchedTextField, ...styles.backgroundIssues }
            : {}),
          ...(props.sx || {}),
          ...(isPortraitMobile
            ? {
                fontSize: isPortraitMobile ? '1rem' : '1.3rem',
              }
            : {}),
        },
        startAdornment: (
          <InputAdornment
            position="start"
            sx={isPortraitMobile ? { '& svg': { fontSize: '1rem' } } : {}}
          >
            {!!props.icon ? props.icon : <Search />}
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <Stack direction="row" alignItems="center">
              {loadingSearchCompanies && (
                <ScaleLoader
                  {...SCALE_LOADER_PROPS}
                  css={
                    {
                      transform: isPortraitMobile
                        ? 'scale(0.35)'
                        : 'scale(0.45)',
                      marginRight: isPortraitMobile ? '-0.8rem' : '-0.5rem',
                    } as any
                  }
                />
              )}
              {props.searchInputValue.length > 0 && (
                <TooltipIconButton
                  iconButtonStyle={{
                    marginRight: '-0.5rem',
                  }}
                  onClick={() => {
                    props.setSearchInputValue('');
                    if (props.setExpandedElements) {
                      props.setExpandedElements(
                        Array(props.dataListLength).fill(false)
                      );
                    }
                  }}
                  icon={<Close />}
                  tooltip="Clear"
                />
              )}
              {!props.notArrowAdornment && (
                <>
                  {props.upFocusEndAdornment ? (
                    <ArrowDropUp />
                  ) : (
                    <ArrowDropDown />
                  )}
                </>
              )}
              {!!props.hasFilters && (
                <SearchCntSecIndFilterComponent
                  open={searchFiltersOpen}
                  setOpen={setSearchFiltersOpen}
                  hasFiltersType={props.hasFiltersType}
                />
              )}
            </Stack>
          </InputAdornment>
        ),
      }}
      inputProps={props.inputProps}
      variant="outlined"
      label={props.label}
      placeholder={props.placeholder}
      sx={{
        width: '100%',
        paddingTop: isPortraitMobile ? 0 : '0.1rem',
        ...props.textFieldStyle,
      }}
      onClick={props.onClick}
      onChange={props.onChange}
    />
  );
};

const AutocompleteRenderInputComponent = (props: Props) => {
  return (params: AutocompleteRenderInputParams) => {
    const { InputProps, inputProps } = params;
    const { ref } = InputProps;

    return (
      <SearchInputComponent
        {...props}
        inputProps={inputProps}
        autoCompleteRef={ref}
      />
    );
  };
};

export default AutocompleteRenderInputComponent;
