import { Stack } from '@mui/material';
import {
  BUTTON_YEAR_LABELS,
  IScreenerBetween,
  IScreenerButtonGroup,
  IScreenerSelect,
  ScreenerFilterNameEnum,
} from '@prometeus/common';
import CardComponent from '../../../components/card.component';
import BetweenSliderComponent, {
  BetweenValuesType,
} from '../../../components/filters/screener/between.component';
import ButtonGroupScreenerComponent from '../../../components/filters/screener/button-group.component';
import SelectItemComponent from '../../../components/filters/screener/select-item.component';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { BlockProps } from '../utils/constants';
import { screenerStyles } from '../utils/styles';
import {
  onBetweenValuesHandler,
  onMaxBetweenValueHandler,
  onMinBetweenValueHandler,
} from '../utils/tab-change.handler';

const GrowthBlockComponent = ({
  tabName,
  cardBlock,
  data,
  setFilterValueHandler,
}: BlockProps) => {
  const isPortraitMobile = useMediaQueryPortraitMobile();

  return (
    <CardComponent
      containerHeight={true}
      tabName={tabName}
      cardBlock={cardBlock}
      activeFilters={!!data?.active}
      titleLabel={data?.boxLabel || ''}
      isFilterCard
    >
      <Stack direction="column" sx={{ padding: '1rem', marginBottom: '2rem' }}>
        <Stack direction={isPortraitMobile ? 'column' : 'row'}>
          <SelectItemComponent
            label={(data?.components[0] as IScreenerSelect).label || ''}
            value={data?.peerGroupOperator}
            list={(data?.components[0] as IScreenerSelect).list || []}
            onSelect={(value: string) => {
              setFilterValueHandler(
                cardBlock,
                ScreenerFilterNameEnum.PEER_GROUP_OPERATOR,
                value
              );
            }}
            containerSx={{ ...screenerStyles.marginBottom, flex: '1.2 1 0' }}
          />
          <BetweenSliderComponent
            containerSx={{ ...screenerStyles.betweenContainer, flex: '1 1 0' }}
            title={(data?.components[1] as IScreenerBetween).label}
            min={(data?.components[1] as IScreenerBetween).min || 0}
            minValue={data?.minYear as number}
            setMinValue={(value: number) => {
              onMinBetweenValueHandler(setFilterValueHandler, cardBlock, value);
            }}
            max={(data?.components[1] as IScreenerBetween).max || 0}
            maxValue={data?.maxYear as number}
            setMaxValue={(value: number) => {
              onMaxBetweenValueHandler(setFilterValueHandler, cardBlock, value);
            }}
            setValues={(values: BetweenValuesType) => {
              onBetweenValuesHandler(setFilterValueHandler, cardBlock, values);
            }}
          />
        </Stack>
        <Stack direction={isPortraitMobile ? 'column' : 'row'}>
          <SelectItemComponent
            split={true}
            label={(data?.components[2] as IScreenerSelect).label || ''}
            value={data?.trend}
            list={(data?.components[2] as IScreenerSelect).list || []}
            onSelect={(value: string) => {
              setFilterValueHandler(
                cardBlock,
                ScreenerFilterNameEnum.TREND,
                value
              );
            }}
            containerSx={{
              ...screenerStyles.longSelectItemComponent,
              ...screenerStyles.noMarginRight,
            }}
          />
          <ButtonGroupScreenerComponent
            labels={(data?.components[3] as IScreenerButtonGroup).list || []}
            values={
              (data?.years as boolean[]) ||
              Array(BUTTON_YEAR_LABELS.length).fill(false)
            }
            handleClick={(value: boolean[]) => {
              setFilterValueHandler(
                cardBlock,
                ScreenerFilterNameEnum.YEARS,
                value
              );
            }}
            title={(data?.components[3] as IScreenerButtonGroup).label || ''}
          />
        </Stack>
      </Stack>
    </CardComponent>
  );
};

export default GrowthBlockComponent;
