import {
  IScreenerTab,
  ScreenerFilterCardType,
  ScreenerFilterNameEnum,
  ScreenerIncomeStatementFilterCardEnum,
} from '@prometeus/common';
import { useDispatch, useSelector } from 'react-redux';
import ResponsiveRow from '../../../components/responsive-row.component';
import ScrollFillerComponent from '../../../components/scroll-filler.component';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { ScreenerTabNameEnum } from '../../../models/screener/structure.model';
import { setFilterValue } from '../../../store/actions/screener/structure.action';
import { RootState } from '../../../store/reducers/root.reducer';
import CommonBlockComponent from '../components/common-block.component';
import GrowthBlockComponent from '../components/growth-block.component';

const IncomeStatementTab = () => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  const tabData: IScreenerTab | null = useSelector((state: RootState) =>
    state.screener.structure.screenerStructure
      ? state.screener.structure.screenerStructure.incomeStatement
      : null
  );

  const incomeStatementSetFilterValue = (
    block: ScreenerFilterCardType,
    filter: ScreenerFilterNameEnum,
    value: any
  ): void => {
    dispatch(
      setFilterValue(ScreenerTabNameEnum.INCOME_STATEMENT, block, filter, value)
    );
  };

  return (
    <>
      <ResponsiveRow height={52} mobileHeight={32}>
        <CommonBlockComponent
          tabName={ScreenerTabNameEnum.INCOME_STATEMENT}
          cardBlock={ScreenerIncomeStatementFilterCardEnum.PROFIT_MARGIN}
          data={tabData?.profitMargin}
          setFilterValueHandler={incomeStatementSetFilterValue}
          column
        />
        <CommonBlockComponent
          tabName={ScreenerTabNameEnum.INCOME_STATEMENT}
          cardBlock={ScreenerIncomeStatementFilterCardEnum.GROSS_MARGIN}
          data={tabData?.grossMargin}
          setFilterValueHandler={incomeStatementSetFilterValue}
          column
        />
        <CommonBlockComponent
          tabName={ScreenerTabNameEnum.INCOME_STATEMENT}
          cardBlock={ScreenerIncomeStatementFilterCardEnum.OP_INC}
          data={tabData?.opInc}
          setFilterValueHandler={incomeStatementSetFilterValue}
          column
        />
      </ResponsiveRow>
      <ResponsiveRow height={38} mobileHeight={36}>
        <GrowthBlockComponent
          tabName={ScreenerTabNameEnum.INCOME_STATEMENT}
          cardBlock={ScreenerIncomeStatementFilterCardEnum.REV_GROWTH}
          data={tabData?.revGrowth}
          setFilterValueHandler={incomeStatementSetFilterValue}
        />

        <GrowthBlockComponent
          tabName={ScreenerTabNameEnum.INCOME_STATEMENT}
          cardBlock={ScreenerIncomeStatementFilterCardEnum.PROF_GROWTH}
          data={tabData?.profGrowth}
          setFilterValueHandler={incomeStatementSetFilterValue}
        />
      </ResponsiveRow>
      {isPortraitMobile && <ScrollFillerComponent />}
    </>
  );
};

export default IncomeStatementTab;
