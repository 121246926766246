import SideFiltersComponent from '../../components/filters/aggregated-filters/side-filters.component';
import PageSkeletonComponent, {
  ITab,
} from '../../components/page-skeleton/page-skeleton.component';
import { RouteEnum } from '../../navigation/routes.constants';

import CompanyDividendsTab from './tabs/company-dividends.tab';
import CompanyStocksTab from './tabs/company-stocks.tab';

export enum StockAnalysisRouteEnum {
  COMPANY_STOCKS = 'company-stocks',
  COMPANY_DIVIDENDS = 'company-dividends',
}

export const STOCK_ANALYSIS_TAB: ITab[] = [
  {
    label: 'Company Stocks',
    route: StockAnalysisRouteEnum.COMPANY_STOCKS,
    component: CompanyStocksTab,
  },
  {
    label: 'Company Dividends',
    route: StockAnalysisRouteEnum.COMPANY_DIVIDENDS,
    component: CompanyDividendsTab,
  },
];

const StockAnalysisPage = () => {
  return (
    <PageSkeletonComponent
      pageRoute={RouteEnum.STOCK_ANALYSIS}
      tabs={STOCK_ANALYSIS_TAB}
      filtersComponent={SideFiltersComponent}
    />
  );
};

export default StockAnalysisPage;
