import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { PageIdEnum } from '../../../models/filters.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { RootState } from '../../../store/reducers/root.reducer';
import RoaDistributionComponent from '../components/roa-roic/roa-distribution.component';
import RoaComponent from '../components/roa-roic/roa.component';
import RoicDistributionComponent from '../components/roa-roic/roic-distribution.component';
import RoicComponent from '../components/roa-roic/roic.component';

const RoaRoicTab = () => {
  const dispatch = useDispatch();

  /* Charts Data */
  const roaDataUpdating = useSelector(
    (state: RootState) => state.visuals.managementEffectiveness.roaDataUpdating
  );
  const roaDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.managementEffectiveness.roaDistributionDataUpdating
  );
  const roicDataUpdating = useSelector(
    (state: RootState) => state.visuals.managementEffectiveness.roicDataUpdating
  );
  const roicDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.managementEffectiveness.roicDistributionDataUpdating
  );

  /* Change Report Page */

  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.ROA_ROIC));
  }, [dispatch]);

  return (
    <>
      {roaDataUpdating &&
        roaDistributionDataUpdating &&
        roicDataUpdating &&
        roicDistributionDataUpdating && <LoadingComponent transparent={true} />}
      <ResponsiveRow>
        <RoaComponent />
        <RoaDistributionComponent />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom paddingBottom={7}>
        <RoicComponent />
        <RoicDistributionComponent />
      </ResponsiveRow>
    </>
  );
};

export default RoaRoicTab;
