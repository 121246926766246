import { Palette, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  B_PAGES,
  TOOLTIP_FONT_SIZE,
} from '../../../constants/general.constants';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { RootState } from '../../../store/reducers/root.reducer';
import { maskBorderShadow, pxToRem } from '../../../ui/functions';
import FiltersCardComponent from '../filters-card.component';
import ConvertCurrencyFilter from './side-components/convert-currency-filter.component';
import DataFrequencyFilter from './side-components/data-frequency-filter.component';
import FiscalYearFilter from './side-components/fiscal-year-filter.component';
import PeerGroupFilter from './side-components/peer-group-filter.component';

export const useAggFiltersStyles = () => {
  const theme = useTheme();
  const palette = theme.palette;
  const isPortraitMobile = useMediaQueryPortraitMobile();

  return {
    tooltip: {
      fontSize: TOOLTIP_FONT_SIZE,
    },
    iconButtonRoot: {
      fontSize: '1rem',
      borderRadius: '50%',
      padding: '0.5rem',
      color: palette.text.primary,
      backgroundColor: palette.background.paper,
      '&:hover': {
        backgroundColor: `${palette.text.primary}B3`,
      },
    },
    /* Select */
    icon: {
      // color: colors.icons,
      transform: `scale(${isPortraitMobile ? 1 : 1})`,
    },
    typographyStyle: {
      textAlign: 'left' as 'left',
      marginTop: '1rem',
    },

    filterMargin: {
      marginBottom: '1.2rem',
    },
    divider: {
      backgroundColor: palette.divider,
    },
    listItemIconRoot: {
      // color: colors.icons,
      marginTop: '0.1rem',
      minWidth: pxToRem(40),
      '& svg': {
        fontSize: '1.8rem',
      },
    },
    fiscalYear: {
      padding: '0rem',
    },
  };
};

const getStyles = (palette: Palette) => ({
  divider: {
    marginTop: '1.5rem',
    marginBottom: '1.2rem',
    height: pxToRem(1),
  },

  maskBorderShadow: {
    ...maskBorderShadow(palette),
  },
  endAdornmentIconButton: {
    color: palette.text.primary,
    padding: '1rem !important',
    transform: 'scale(0.8)',
  },
  endAdornmentIconButtonTooltip: {
    transform: 'scale(0.8)',
  },

  /* Peers */
  peersContainer: {
    overflow: 'auto',
    borderRadius: '1rem',
    display: 'flex',
    flexDirection: 'column',
    padding: '0.5rem 0rem',
    minHeight: '10rem',
    height: '100%',
  },
});

const SideFiltersComponent = () => {
  const currentPage = useSelector(
    (state: RootState) => state.filters.currentPage
  );

  const [isBPage, setIsBPage] = useState<boolean>(false);
  useEffect(() => {
    const value = !!currentPage && B_PAGES.has(currentPage);
    setIsBPage(value);
  }, [currentPage]);

  const theme = useTheme();
  const styles = useAggFiltersStyles();
  const thisStyles = getStyles(theme.palette);

  return (
    <FiltersCardComponent>
      <ConvertCurrencyFilter thisStyles={thisStyles} />
      <DataFrequencyFilter styles={styles} isBPage={isBPage} />
      <PeerGroupFilter styles={styles} thisStyles={thisStyles} />
      <FiscalYearFilter styles={styles} isBPage={isBPage} />
    </FiltersCardComponent>
  );
};

export default SideFiltersComponent;
