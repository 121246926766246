import VisualRankChartWrapper from '../../../../components/charts/visual-rank-chart-wrapper.component';
import { COMPANY_STOCKS_TITLE_LABELS } from '../../utils/visuals.config';

const MarketCapRankComponent = () => {
  return (
    <VisualRankChartWrapper
      dataLabel="marketCapRank"
      pageLabel="stock"
      titleLabel={COMPANY_STOCKS_TITLE_LABELS.marketCapRank}
    />
  );
};

export default MarketCapRankComponent;
