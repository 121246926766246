import { Box, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { useMediaQueryPortraitMobile } from '../hooks/responsive-design.hook';
import { fetchCompanyData } from '../store/actions/companies.action';
import { setTickerId } from '../store/actions/filters.action';

type Props = {
  tickerId?: string;
  companyId?: string;
  small?: boolean;
};

const TickerCompanyIdHeaderComponent = ({
  tickerId,
  companyId,
  small,
}: Props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  const clickHandler = (event: any) => {
    if (!(event.ctrlKey || event.metaKey) && !!companyId) {
      event.stopPropagation();
      event.preventDefault();

      dispatch(setTickerId(companyId));
      dispatch(fetchCompanyData(companyId));
    }
  };

  return (
    <Typography
      variant={small ? 'h5' : isPortraitMobile ? 'h6' : 'h4'}
      sx={(theme) => ({
        color: theme.palette.text.primary,
        marginLeft: '1rem',
        '& span': {
          fontWeight: '700',
        },
      })}
    >
      <>
        <span
          style={{
            fontSize: isPortraitMobile ? '1.5rem' : undefined,
          }}
        >
          {tickerId}
        </span>
        {tickerId !== companyId && (
          <Box
            sx={{
              fontSize: small
                ? '1.2rem'
                : isPortraitMobile
                ? '1.5rem'
                : '1.8rem',
              marginLeft: small ? '0.4rem' : '0.7rem',
              fontWeight: 'normal !important',

              ...(isPortraitMobile
                ? { '& a': { textDecoration: 'underline !important' } }
                : {}),

              '&:hover a': {
                textDecoration: 'underline !important',
              },
            }}
            component="span"
            onClick={clickHandler}
          >
            {'('}
            <Link
              to={`${location.pathname}?tickerId=${companyId}`}
              style={{
                color: 'unset',
                textDecoration: 'none',
                // borderBottom: '0.02rem solid white',
                fontSize: small
                  ? '1.1rem'
                  : isPortraitMobile
                  ? '1.25rem'
                  : '1.45rem',
                marginLeft: small ? '0.1rem' : '0.1rem',
                marginRight: small ? '0.1rem' : '0.1rem',
              }}
            >
              {companyId}
            </Link>
            {')'}
          </Box>
        )}
      </>
    </Typography>
  );
};

export default TickerCompanyIdHeaderComponent;
