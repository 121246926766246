import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PageIdEnum } from '../../../models/filters.model';
import { ISecIndCty } from '../../../models/screener/structure.model';
import { GroupBySectorEnum } from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { RootState } from '../../../store/reducers/root.reducer';
import SectorAnalysisRowWrapper from '../components/sector-analysis-row-wrapper.component';
import { setSectorGroupBy } from '../utils/visuals.actions';

const CountryAnalysisTab = () => {
  const dispatch = useDispatch();

  const countryCountries = useSelector(
    (state: RootState) => state.visuals.sector.countryCountries
  );
  const hasCountries = (): boolean =>
    !!countryCountries?.filter((e: ISecIndCty) => e.selected)?.length;

  /* Change Report Page */
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.COUNTRY_ANALYSIS));
    dispatch(setSectorGroupBy(GroupBySectorEnum.COUNTRY_ID));
  }, [dispatch]);

  return (
    <>
      <SectorAnalysisRowWrapper
        position="top"
        pageLabel="Country"
        hasSelectedElements={hasCountries}
      />
      <SectorAnalysisRowWrapper
        position="bottom"
        pageLabel="Country"
        hasSelectedElements={hasCountries}
        noMarginBottom
        paddingBottom={5}
      />
    </>
  );
};

export default CountryAnalysisTab;
