import { FilterAlt, Warning } from '@mui/icons-material';
import { Fab } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  ISecIndCty,
  ISectorIndustry,
} from '../../models/screener/structure.model';
import { setOpenMobileFilterDialog } from '../../store/actions/structure.action';
import { RootState } from '../../store/reducers/root.reducer';

const useIsWarning = () => {
  // Path location
  const { pathname } = useLocation();
  const splitPathname = pathname?.split('/').filter((e) => !!e);
  const [page, section] =
    splitPathname?.length === 2 ? splitPathname : ['', ''];

  // Options
  const topOption = useSelector(
    (state: RootState) => state.visuals.sector.topOption
  );
  const bottomOption = useSelector(
    (state: RootState) => state.visuals.sector.topOption
  );

  const countryCountries = useSelector(
    (state: RootState) => state.visuals.sector.countryCountries
  );
  const industrySectors = useSelector(
    (state: RootState) => state.visuals.sector.industrySectors
  );
  const sectorSectors = useSelector(
    (state: RootState) => state.visuals.sector.sectorSectors
  );

  if (page !== 'sector-analysis') {
    return false;
  } else {
    if (!topOption && !bottomOption) {
      // Both not set
      return true;
    }

    // Check if there are no selected elements
    switch (section) {
      case 'country':
        return !countryCountries?.filter((e: ISecIndCty) => e.selected)?.length;
      case 'sector':
        return !sectorSectors?.filter((e: ISectorIndustry) => e.selected)
          ?.length;
      case 'industry':
        return !industrySectors?.filter(
          (e: ISectorIndustry) =>
            e.selected &&
            e.industries.some((industry: ISecIndCty) => industry.selected)
        )?.length;
    }
  }
};

const FiltersFabComponent = () => {
  const dispatch = useDispatch();

  const isWarning = useIsWarning();

  return (
    <Fab
      color={isWarning ? 'warning' : 'primary'}
      size="small"
      sx={{
        position: 'absolute',
        bottom: '1.5rem',
        right: '1.5rem',
      }}
      onClick={() => {
        dispatch(setOpenMobileFilterDialog(true));
      }}
    >
      {isWarning ? <Warning /> : <FilterAlt />}
    </Fab>
  );
};

export default FiltersFabComponent;
