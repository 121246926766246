import { Add, Remove } from '@mui/icons-material';
import {
  IconButton,
  Input,
  InputAdornment,
  Palette,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { isNullUndefined } from '@prometeus/common';
import React, { useRef } from 'react';
import { isNumeric } from '../../../constants/utils.constants';
import { pxToRem } from '../../../ui/functions';

const DEFAULT_EMPTY_VALUE = '-';

const getStyles = (palette: Palette, inputWidth?: string) => ({
  iconButton: {
    //  color: colors.icons,
    padding: '0.3rem !important',
  },
  mobileIconButton: { transform: 'scale(2.5)' },
  row: {
    marginBottom: '0.3rem',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  input: {
    width: inputWidth || '1.8rem',
    fontWeight: 'bolder',
    fontSize: '1.2rem',
    letterSpacing: '-0.08rem',
    color: palette.text.primary,
    '&::before': {
      borderBottomColor: palette.text.primary,
      borderBottomWidth: 0,
    },
    '&::after': {
      borderBottomWidth: pxToRem(2),
    },
    '&:hover': {
      '&::before': {
        borderBottom: `${pxToRem(2)} solid ${palette.primary.main} !important`,
      },
    },
    '& input': {
      textAlign: 'center',
      borderBottomWidth: pxToRem(1),
      borderColor: palette.text.primary,
      paddingTop: pxToRem(3),
      paddingBottom: pxToRem(3),
    },
  },
  smallInput: {
    fontSize: '1.05rem',
  },
  smallLabel: {
    fontSize: '0.9rem',
  },
  adornment: {
    '& > *': {
      color: palette.text.primary,
    },
  },
});

type Props = {
  boxLabel: string;
  step: number;
  min: number;
  max: number;
  value: string | null;
  mask: 'min' | 'max';
  setValue: (value: string) => void;
  onBlurChange: (value: string) => void;
  inputWidth?: string;
  isPercent?: boolean;
  rightEnd?: boolean;
  spaceBetween?: boolean;
  isSmall?: boolean;
};

const IncrementalComponent = (props: Props) => {
  const theme = useTheme();
  const styles = getStyles(theme.palette, props.inputWidth);

  const propsValue: string = isNullUndefined(props.value)
    ? DEFAULT_EMPTY_VALUE
    : (props.value as string);
  const maskedValue =
    props.mask === 'min' ? props.min - props.step : props.max + props.step;

  const [changedValue, setChangedValue] = React.useState<boolean>(false);

  const onChangeBoxHandler = (event: React.ChangeEvent<{ value: string }>) => {
    const newValue: string = event.target.value.replace(/[^0-9.-]*/g, '');
    props.setValue(newValue);
    if (!changedValue) {
      setChangedValue(true);
    }
  };

  const onBlurBoxHandler = (event: React.ChangeEvent<{ value: string }>) => {
    if (isNumeric(propsValue || '') && changedValue) {
      setChangedValue(false);
      if (+(propsValue as string) < props.min) {
        props.onBlurChange(`${props.min}`);
        props.setValue(`${props.min}`);
      } else if (+(propsValue as string) > props.max) {
        props.onBlurChange(`${props.max}`);
        props.setValue(`${props.max}`);
      } else {
        props.onBlurChange(propsValue as string);
      }
    }
  };

  /* Buttons */
  const inputRef = useRef();
  const onMouseLeaveHandler = () => {
    if (inputRef.current) {
      const ref = inputRef.current as any;
      ref.focus();
      ref.blur();
    }
  };

  const onClickLess = () => {
    const baseValue: number =
      propsValue === DEFAULT_EMPTY_VALUE ? maskedValue : +propsValue;
    let value = baseValue - props.step;
    if (value < props.min) {
      value = props.min;
    }
    props.setValue(`${value}`);
  };

  const onClickMore = () => {
    const baseValue: number =
      propsValue === DEFAULT_EMPTY_VALUE ? maskedValue : +propsValue;
    let value = baseValue + props.step;
    if (value > props.max) {
      value = props.max;
    }
    props.setValue(`${value}`);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="flex-start"
      sx={{
        ...styles.row,
        ...(!!props.rightEnd ? styles.flexEnd : {}),
        ...(!!props.spaceBetween ? styles.spaceBetween : {}),
      }}
    >
      {props.boxLabel && (
        <Typography variant="subtitle1" align="left">
          {props.boxLabel}
        </Typography>
      )}
      <Stack direction="row" alignItems="center">
        <IconButton
          sx={{
            ...styles.iconButton,
          }}
          size="small"
          onClick={onClickLess}
          onMouseLeave={onMouseLeaveHandler}
        >
          <Remove fontSize="small" />
        </IconButton>
        <Input
          sx={{
            ...styles.input,
            ...(!!props.isSmall ? styles.smallInput : {}),
          }}
          value={
            !isNullUndefined(propsValue)
              ? propsValue
              : !!props.min
              ? props.min
              : ''
          }
          margin="dense"
          onChange={onChangeBoxHandler}
          onBlur={onBlurBoxHandler}
          inputProps={{
            ref: inputRef,
            step: props.step,
            min: props.min,
            max: props.max,
          }}
          endAdornment={
            <>
              {props.isPercent && propsValue !== '-' && (
                <InputAdornment position="end" sx={styles.adornment}>
                  %
                </InputAdornment>
              )}
            </>
          }
        />
        <IconButton
          sx={{
            ...styles.iconButton,
          }}
          size="small"
          onClick={onClickMore}
          onMouseLeave={onMouseLeaveHandler}
        >
          <Add fontSize="small" />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default IncrementalComponent;
