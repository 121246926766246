import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { BAR_CHART_PLUGINS } from '../../../../constants/charts/options.constants';
import { CURRENT_QUICK_RATIO_CAPEX_TITLE_LABELS } from '../../utils/visuals.config';

const CurrentRatioDistributionComponent = () => {
  return (
    <VisualChartWrapper
      type="bar"
      dataLabel="currentRatioDistribution"
      pageLabel="financialStrength"
      titleLabel={
        CURRENT_QUICK_RATIO_CAPEX_TITLE_LABELS.currentRatioDistribution
      }
      xAxisType="category"
      chartOptions={BAR_CHART_PLUGINS}
      useDatasetLabels
    />
  );
};

export default CurrentRatioDistributionComponent;
