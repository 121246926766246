import { Stack } from '@mui/material';
import {
  IScreenerCheckboxList,
  IScreenerFilter,
  ScreenerFilterCardType,
  ScreenerFilterNameEnum,
  ScreenerStockFilterCardEnum,
} from '@prometeus/common';
import { useDispatch, useSelector } from 'react-redux';
import CardComponent, {
  resetScreenerCardBlockHandler,
} from '../../../../components/card.component';
import CheckboxListScreenerComponent from '../../../../components/filters/screener/checkbox-list.component';
import { ScreenerTabNameEnum } from '../../../../models/screener/structure.model';
import { setFilterValue } from '../../../../store/actions/screener/structure.action';
import { RootState } from '../../../../store/reducers/root.reducer';

const MarketCapFilterComponent = () => {
  const dispatch = useDispatch();

  const filterData: IScreenerFilter | null = useSelector((state: RootState) =>
    state.screener.structure.screenerStructure
      ? state.screener.structure.screenerStructure.stock?.marketCap
      : null
  );

  const stockSetFilterValue = (
    block: ScreenerFilterCardType,
    filter: ScreenerFilterNameEnum,
    value: any
  ): void => {
    dispatch(setFilterValue(ScreenerTabNameEnum.STOCK, block, filter, value));
  };

  return (
    <CardComponent
      containerHeight={true}
      tabName={ScreenerTabNameEnum.STOCK}
      cardBlock={ScreenerStockFilterCardEnum.MARKET_CAP}
      activeFilters={!!filterData?.active}
      titleLabel={filterData?.boxLabel || ''}
      isFilterCard
    >
      <Stack justifyContent="center" sx={{ height: '100%' }}>
        <CheckboxListScreenerComponent
          labels={
            (filterData?.components[0] as IScreenerCheckboxList).list || []
          }
          active={!!filterData?.active}
          checked={(filterData?.bins as boolean[]) || []}
          onClick={(value: boolean[]) => {
            if (value.some((e) => e)) {
              stockSetFilterValue(
                ScreenerStockFilterCardEnum.MARKET_CAP,
                ScreenerFilterNameEnum.BINS,
                value
              );
            } else {
              if (!!filterData?.active) {
                resetScreenerCardBlockHandler(
                  dispatch,
                  ScreenerTabNameEnum.STOCK,
                  ScreenerStockFilterCardEnum.MARKET_CAP
                );
              }
            }
          }}
        />
      </Stack>
    </CardComponent>
  );
};

export default MarketCapFilterComponent;
