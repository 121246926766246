import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { COMPANY_DIVIDENDS_TITLE_LABELS } from '../../utils/visuals.config';

const DividendTtmComponent = () => {
  return (
    <VisualChartWrapper
      dataLabel="dividendTTM"
      pageLabel="stock"
      titleLabel={COMPANY_DIVIDENDS_TITLE_LABELS.dividendTTM}
      type="line"
      useDollarsCurrency
      isTime
      useFiscalYear
      hideLegend
    />
  );
};

export default DividendTtmComponent;
