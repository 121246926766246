import VisualChartWrapper from '../../../../../components/charts/visual-chart-wrapper.component';
import { BAR_CHART_PLUGINS } from '../../../../../constants/charts/options.constants';
import { useMediaQueryPortraitMobile } from '../../../../../hooks/responsive-design.hook';
import { REVENUE_AND_PROFIT_TITLE_LABELS } from '../../../utils/visuals.config';

const AnnualRevenueGrowthRateComponent = () => {
  const isPortraitMobile = useMediaQueryPortraitMobile();

  return (
    <VisualChartWrapper
      dataLabel="annualRevenueGrowthRate"
      pageLabel="incomeStatement"
      titleLabel={REVENUE_AND_PROFIT_TITLE_LABELS.annualRevenueGrowthRate}
      type="bar"
      xAxisType="category"
      chartOptions={BAR_CHART_PLUGINS}
      useDollarsCurrency
      isBar
      useDatasetLabels
      smallChart={!isPortraitMobile}
    />
  );
};

export default AnnualRevenueGrowthRateComponent;
