import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { BAR_CHART_PLUGINS } from '../../../../constants/charts/options.constants';
import { ROA_ROIC_TITLE_LABELS } from '../../utils/visuals.config';

const RoicDistributionComponent = () => {
  return (
    <VisualChartWrapper
      type="bar"
      dataLabel="roicDistribution"
      pageLabel="managementEffectiveness"
      titleLabel={ROA_ROIC_TITLE_LABELS.roicDistribution}
      xAxisType="category"
      chartOptions={BAR_CHART_PLUGINS}
      useDatasetLabels
    />
  );
};

export default RoicDistributionComponent;
