import VisualChartWrapper from '../../../../../components/charts/visual-chart-wrapper.component';
import { BAR_CHART_PLUGINS } from '../../../../../constants/charts/options.constants';
import { OPERATING_EXPENSES_TITLE_LABELS } from '../../../utils/visuals.config';

const OperatingIncomeDistributionComponent = () => {
  return (
    <VisualChartWrapper
      dataLabel="operatingIncomeDistribution"
      pageLabel="incomeStatement"
      titleLabel={OPERATING_EXPENSES_TITLE_LABELS.operatingIncomeDistribution}
      type="bar"
      xAxisType="category"
      chartOptions={BAR_CHART_PLUGINS}
      useDollarsCurrency
      useDatasetLabels
    />
  );
};

export default OperatingIncomeDistributionComponent;
