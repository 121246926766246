import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { COMMON_COLORS } from '../../../../ui/colors';
import { NONCURRENT_ASSETS_LIABILITIES_TITLE_LABELS } from '../../utils/visuals.config';

const NonCurrentLiabilitiesComponent = () => {
  return (
    <VisualChartWrapper
      type="line"
      dataLabel="nonCurrentLiabilities"
      pageLabel="balanceSheet"
      titleLabel={
        NONCURRENT_ASSETS_LIABILITIES_TITLE_LABELS.nonCurrentLiabilities
      }
      useDollarsCurrency
      useFiscalYear
      isTime
      customLineColor={COMMON_COLORS.liabilityCharts}
      hideLegend
    />
  );
};

export default NonCurrentLiabilitiesComponent;
