import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { PageIdEnum } from '../../../models/filters.model';
import { ISectorIndustry } from '../../../models/screener/structure.model';
import { GroupBySectorEnum } from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { RootState } from '../../../store/reducers/root.reducer';
import SectorAnalysisRowWrapper from '../components/sector-analysis-row-wrapper.component';
import { setSectorGroupBy } from '../utils/visuals.actions';

const SectorAnalysisTab = () => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  const sectorSectors = useSelector(
    (state: RootState) => state.visuals.sector.sectorSectors
  );

  const hasSectors = (): boolean =>
    !!sectorSectors?.filter((e: ISectorIndustry) => e.selected)?.length;

  /* Change Report Page */
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.SECTOR_ANALYSIS));
    dispatch(setSectorGroupBy(GroupBySectorEnum.SECTOR));
  }, [dispatch]);

  return (
    <>
      <SectorAnalysisRowWrapper
        position="top"
        pageLabel="Sector"
        hasSelectedElements={hasSectors}
      />
      <SectorAnalysisRowWrapper
        position="bottom"
        pageLabel="Sector"
        hasSelectedElements={hasSectors}
        noMarginBottom
        paddingBottom={isPortraitMobile ? 5 : 3}
      />
    </>
  );
};

export default SectorAnalysisTab;
