import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import ScrollFillerComponent from '../../../components/scroll-filler.component';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { PageIdEnum } from '../../../models/filters.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { RootState } from '../../../store/reducers/root.reducer';
import CapexDistributionComponent from '../components/current-quick-ration-capex/capex-distribution.component';
import CapexComponent from '../components/current-quick-ration-capex/capex.component';
import CurrentRatioDistributionComponent from '../components/current-quick-ration-capex/current-ratio-distribution.component';
import CurrentRatioComponent from '../components/current-quick-ration-capex/current-ratio.component';
import QuickRatioDistributionComponent from '../components/current-quick-ration-capex/quick-ratio-distribution.component';
import QuickRatioComponent from '../components/current-quick-ration-capex/quick-ratio.component';

const CurrentQuickRatioCapexTab = () => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  /***************************************
   *              Chars Data             *
   ***************************************/
  const currentRatioDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.financialStrength.currentRatioDataUpdating
  );
  const currentRatioDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.financialStrength.currentRatioDistributionDataUpdating
  );
  const quickRatioDataUpdating = useSelector(
    (state: RootState) => state.visuals.financialStrength.quickRatioDataUpdating
  );
  const quickRatioDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.financialStrength.quickRatioDistributionDataUpdating
  );
  const capexDataUpdating = useSelector(
    (state: RootState) => state.visuals.financialStrength.capexDataUpdating
  );
  const capexDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.financialStrength.capexDistributionDataUpdating
  );

  /* Change Report Page */
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.CURRENT_QUICK_RATIO_CAPEX));
  }, [dispatch]);

  return (
    <>
      {currentRatioDataUpdating &&
        currentRatioDistributionDataUpdating &&
        quickRatioDataUpdating &&
        quickRatioDistributionDataUpdating &&
        capexDataUpdating &&
        capexDistributionDataUpdating && (
          <LoadingComponent transparent={true} />
        )}
      <ResponsiveRow>
        <CurrentRatioComponent />
        <CurrentRatioDistributionComponent />
      </ResponsiveRow>
      <ResponsiveRow>
        <QuickRatioComponent />
        <QuickRatioDistributionComponent />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom paddingBottom={7}>
        <CapexComponent />
        <CapexDistributionComponent />
      </ResponsiveRow>
      {!isPortraitMobile && <ScrollFillerComponent fill={4} />}
    </>
  );
};

export default CurrentQuickRatioCapexTab;
