import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  MAX_FISCAL_YEAR,
  MIN_FISCAL_YEAR,
} from '../../../../constants/general.constants';
import { useMediaQueryPortraitMobile } from '../../../../hooks/responsive-design.hook';
import { TimeframeEnum } from '../../../../models/filters.model';
import { setFiscalYear } from '../../../../store/actions/filters.action';
import { RootState } from '../../../../store/reducers/root.reducer';
import FilterTitleComponent from '../../filter-components/filter-title.component';
import BetweenSliderComponent, {
  BetweenValuesType,
} from '../../screener/between.component';

type Props = { styles: any; isBPage: boolean };

const FiscalYearFilter = ({ styles, isBPage }: Props) => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  const fiscalYear = useSelector(
    (state: RootState) => state.filters.fiscalYear
  );
  const activeQYFilter = useSelector(
    (state: RootState) => state.filters.activeQYFilter
  );
  const activeDWMQFilter = useSelector(
    (state: RootState) => state.filters.activeDWMQFilter
  );

  const [internalFiscalYear, setInternalFiscalYear] = useState<
    [number, number]
  >([0, 0]);

  useEffect(() => {
    setInternalFiscalYear(fiscalYear);
  }, [fiscalYear]);

  const onChangeSlideTWHandler = (event: any, newRange: any) => {
    if (Array.isArray(newRange)) {
      if (
        `${newRange[0]}`.length === 4 &&
        `${newRange[1]}`.length === 4 &&
        newRange[1] >= newRange[0]
      ) {
        setInternalFiscalYear(newRange as [number, number]);
        dispatch(setFiscalYear(newRange as [number, number]));
      }
    }
  };

  return (
    <Box sx={isPortraitMobile ? { marginBottom: '1.5rem !important' } : {}}>
      <FilterTitleComponent title={`Fiscal Year`} />
      <BetweenSliderComponent
        containerSx={styles.fiscalYear}
        typographyVariant="h6"
        min={MIN_FISCAL_YEAR}
        minValue={internalFiscalYear[0]}
        setMinValue={(value: number) => {
          onChangeSlideTWHandler(null, [value, internalFiscalYear[1]]);
        }}
        max={MAX_FISCAL_YEAR}
        maxValue={internalFiscalYear[1]}
        setMaxValue={(value: number) => {
          onChangeSlideTWHandler(null, [internalFiscalYear[0], value]);
        }}
        setValues={(values: BetweenValuesType) => {
          onChangeSlideTWHandler(null, values);
        }}
        disabled={
          (isBPage ? activeDWMQFilter : activeQYFilter) === TimeframeEnum.DAILY
        }
      />
    </Box>
  );
};

export default FiscalYearFilter;
