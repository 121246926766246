import { Stack } from '@mui/material';
import {
  IScreenerBetween,
  IScreenerFilter,
  IScreenerSelect,
  ScreenerFilterCardType,
  ScreenerFilterNameEnum,
  ScreenerStockFilterCardEnum,
} from '@prometeus/common';
import { useDispatch, useSelector } from 'react-redux';
import CardComponent from '../../../../components/card.component';
import BetweenSliderComponent, {
  BetweenValuesType,
} from '../../../../components/filters/screener/between.component';
import SelectItemComponent from '../../../../components/filters/screener/select-item.component';
import { useMediaQueryPortraitMobile } from '../../../../hooks/responsive-design.hook';
import { ScreenerTabNameEnum } from '../../../../models/screener/structure.model';
import { setFilterValue } from '../../../../store/actions/screener/structure.action';
import { RootState } from '../../../../store/reducers/root.reducer';
import { screenerStyles } from '../../utils/styles';
import {
  onBetweenValuesHandler,
  onMaxBetweenValueHandler,
  onMinBetweenValueHandler,
} from '../../utils/tab-change.handler';

const PriceEarningsRatioFilterComponent = () => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  const filterData: IScreenerFilter | null = useSelector((state: RootState) =>
    state.screener.structure.screenerStructure
      ? state.screener.structure.screenerStructure.stock?.priceEarningsRatio
      : null
  );

  const stockSetFilterValue = (
    block: ScreenerFilterCardType,
    filter: ScreenerFilterNameEnum,
    value: any
  ): void => {
    dispatch(setFilterValue(ScreenerTabNameEnum.STOCK, block, filter, value));
  };

  const selectItemHandler = (value: string) => {
    stockSetFilterValue(
      ScreenerStockFilterCardEnum.PRICE_EARNINGS,
      ScreenerFilterNameEnum.PEER_GROUP_OPERATOR,
      value
    );
  };

  const minHandler = (value: number) => {
    onMinBetweenValueHandler(
      stockSetFilterValue,
      ScreenerStockFilterCardEnum.PRICE_EARNINGS,
      value,
      true
    );
  };

  const maxHandler = (value: number) => {
    onMaxBetweenValueHandler(
      stockSetFilterValue,
      ScreenerStockFilterCardEnum.PRICE_EARNINGS,
      value,
      true
    );
  };

  const betweenHandler = (values: BetweenValuesType) => {
    onBetweenValuesHandler(
      stockSetFilterValue,
      ScreenerStockFilterCardEnum.PRICE_EARNINGS,
      values,
      true
    );
  };

  return (
    <CardComponent
      containerHeight={true}
      tabName={ScreenerTabNameEnum.STOCK}
      cardBlock={ScreenerStockFilterCardEnum.PRICE_EARNINGS}
      activeFilters={!!filterData?.active}
      titleLabel={filterData?.boxLabel || ''}
      isFilterCard
    >
      <Stack
        direction={isPortraitMobile ? 'column' : 'row'}
        sx={isPortraitMobile ? { padding: '1.5rem' } : { padding: '1rem' }}
      >
        <SelectItemComponent
          label={(filterData?.components[0] as IScreenerSelect).label || ''}
          value={filterData?.peerGroupOperator}
          list={(filterData?.components[0] as IScreenerSelect).list || []}
          onSelect={selectItemHandler}
          containerSx={screenerStyles.longSelectItemComponent}
        />
        <BetweenSliderComponent
          title={(filterData?.components[1] as IScreenerBetween).label}
          step={(filterData?.components[1] as IScreenerBetween).step}
          min={(filterData?.components[1] as IScreenerBetween).min || 0}
          minValue={filterData?.minYear as number}
          setMinValue={minHandler}
          max={(filterData?.components[1] as IScreenerBetween).max || 0}
          maxValue={filterData?.maxYear as number}
          setMaxValue={maxHandler}
          setValues={betweenHandler}
          containerSx={screenerStyles.paddingRight0}
        />
      </Stack>
    </CardComponent>
  );
};

export default PriceEarningsRatioFilterComponent;
