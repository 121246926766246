import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { COMMON_COLORS } from '../../../../ui/colors';
import { BS_OVERVIEW_TITLE_LABELS } from '../../utils/visuals.config';

const TotalAssetsComponent = () => {
  return (
    <VisualChartWrapper
      type="line"
      dataLabel="totalAssets"
      pageLabel="balanceSheet"
      titleLabel={BS_OVERVIEW_TITLE_LABELS.totalAssets}
      useDollarsCurrency
      useFiscalYear
      isTime
      customLineColor={COMMON_COLORS.assetCharts}
      hideLegend
    />
  );
};

export default TotalAssetsComponent;
