import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { PageIdEnum } from '../../../models/filters.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { RootState } from '../../../store/reducers/root.reducer';
import AverageOperationExpensesDistributionComponent from './components/operating-expenses/average-operation-expenses-distribution.component';
import OperatingIncomeDistributionComponent from './components/operating-expenses/operating-income-distribution.component';
import OperatingIncomeOnGrossProfitComponent from './components/operating-expenses/operating-income-on-gross-profit.component';
import OperationExpensesAmortDeprComponent from './components/operating-expenses/operation-expenses-amort-depr.component';
import OperationExpensesRDComponent from './components/operating-expenses/operation-expenses-rd.component';
import OperationExpensesSGAComponent from './components/operating-expenses/operation-expenses-sga.component';

const OperatingExpensesTab = () => {
  const dispatch = useDispatch();

  /* Charts Data */
  const operatingIncomeOnGrossProfitDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.operatingIncomeOnGrossProfitDataUpdating
  );
  const operatingIncomeDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.operatingIncomeDistributionDataUpdating
  );
  const averageOperationExpensesDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement
        .averageOperationExpensesDistributionDataUpdating
  );
  const operationExpensesRDDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.operationExpensesRDDataUpdating
  );
  const operationExpensesSGADataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.operationExpensesSGADataUpdating
  );
  const operationExpensesAmortDeprDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.operationExpensesAmortDeprDataUpdating
  );

  /* Change Report Page */
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.OPERATING_EXPENSES));
  }, [dispatch]);

  return (
    <>
      {operatingIncomeOnGrossProfitDataUpdating &&
        operatingIncomeDistributionDataUpdating &&
        averageOperationExpensesDistributionDataUpdating &&
        operationExpensesRDDataUpdating &&
        operationExpensesSGADataUpdating &&
        operationExpensesAmortDeprDataUpdating && (
          <LoadingComponent transparent={true} />
        )}
      <ResponsiveRow>
        <OperatingIncomeOnGrossProfitComponent />
        <OperatingIncomeDistributionComponent />
        <AverageOperationExpensesDistributionComponent />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom paddingBottom={8}>
        <OperationExpensesRDComponent />
        <OperationExpensesSGAComponent />
        <OperationExpensesAmortDeprComponent />
      </ResponsiveRow>
    </>
  );
};

export default OperatingExpensesTab;
