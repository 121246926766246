import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { DEBT_TO_EQUITY_DEBT_PAYBACK_TITLE_LABELS } from '../../utils/visuals.config';

const DebtPaybackComponent = () => {
  return (
    <VisualChartWrapper
      type="line"
      dataLabel="debtPayback"
      pageLabel="financialStrength"
      titleLabel={DEBT_TO_EQUITY_DEBT_PAYBACK_TITLE_LABELS.debtPayback}
      isTime
      useFiscalYear
    />
  );
};

export default DebtPaybackComponent;
