import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { ROA_ROIC_TITLE_LABELS } from '../../utils/visuals.config';

const RoicComponent = () => {
  return (
    <VisualChartWrapper
      type="line"
      dataLabel="roic"
      pageLabel="managementEffectiveness"
      titleLabel={ROA_ROIC_TITLE_LABELS.roic}
      isTime
      useFiscalYear
    />
  );
};

export default RoicComponent;
