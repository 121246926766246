import { AttachMoney } from '@mui/icons-material';
import { Stack, Switch } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setUseDollars } from '../../../../store/actions/filters.action';
import { RootState } from '../../../../store/reducers/root.reducer';
import FilterTitleComponent from '../../filter-components/filter-title.component';

type Props = {
  thisStyles: any;
};

const ConvertCurrencyFilter = ({ thisStyles }: Props) => {
  const dispatch = useDispatch();

  const useDollars = useSelector(
    (state: RootState) => state.filters.useDollars
  );

  const onClickDollarsHandler = () => {
    dispatch(setUseDollars(!useDollars));
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
      <FilterTitleComponent title="Convert to USD" />
      <Stack direction="row" alignItems="center">
        <AttachMoney sx={{ fontSize: '2rem', marginRight: '-0.5rem' }} />
        <Switch
          sx={{ ...thisStyles.usdSwitch }}
          checked={useDollars}
          onChange={onClickDollarsHandler}
          color="primary"
          name="convertUSD"
        />
      </Stack>
    </Stack>
  );
};

export default ConvertCurrencyFilter;
