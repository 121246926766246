import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { COMMON_COLORS } from '../../../../ui/colors';
import { EQUITY_TITLE_LABELS } from '../../utils/visuals.config';

const TreasuryStockComponent = () => {
  return (
    <VisualChartWrapper
      type="line"
      dataLabel="treasuryStock"
      pageLabel="balanceSheet"
      titleLabel={EQUITY_TITLE_LABELS.treasuryStock}
      useDollarsCurrency
      useFiscalYear
      isTime
      customLineColor={COMMON_COLORS.equityCharts}
      hideLegend
    />
  );
};

export default TreasuryStockComponent;
