import {
  Autocomplete,
  autocompleteClasses,
  Box,
  Palette,
  Paper,
  useTheme,
} from '@mui/material';
import { ICompanyMinimal, IS_FREE_LICENCE_TICKER } from '@prometeus/common';
import CSS from 'csstype';
import React, { MutableRefObject, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { debounce, throttle } from 'throttle-debounce';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { useFocus } from '../../../hooks/use-focus.hook';
import { ISecIndCty } from '../../../models/screener/structure.model';
import {
  AdminRouteEnum,
  RouteEnum,
} from '../../../navigation/routes.constants';
import {
  fetchCompanyData,
  searchCompanies,
  setCompaniesData,
} from '../../../store/actions/companies.action';
import { setTickerId } from '../../../store/actions/filters.action';
import { openActiveFreeTrialDialog } from '../../../store/actions/modals.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { getAutocompleteStyles } from '../../../styles/autocomplete.styles';
import { BLUR } from '../../../styles/common.styles';
import { useSelectStyles } from '../../../styles/select.styles';
import { COMMON_COLORS } from '../../../ui/colors';
import IconComponent from '../../icon.component';
import AutocompleteRenderInputComponent from './autocomplete-render-input.component';

const isBlurredCompany = (
  company: ICompanyMinimal,
  hasActiveLicence: boolean
) => !hasActiveLicence && !IS_FREE_LICENCE_TICKER(company?.tickerId || '');

type AutocompleteOptionProps = {
  option: ICompanyMinimal;
  renderProps: React.HTMLAttributes<HTMLLIElement>;
  hasActiveLicence?: boolean | string;
};

const AutocompleteOption = (props: AutocompleteOptionProps) => {
  const theme = useTheme();
  const autocompleteStyles = getAutocompleteStyles(theme);
  return (
    <li
      {...props.renderProps}
      style={{
        padding: 0,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          ...autocompleteStyles.option,
          padding: '0.8rem 2rem 0.8rem 1.5rem',
          '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: COMMON_COLORS.contrastPrimaryText,
          },
          ...(isBlurredCompany(props.option, !!props.hasActiveLicence)
            ? BLUR
            : {}),
        }}
      >
        <IconComponent
          src={props.option.companyId}
          rest={{
            style: {
              width: '2rem',
              height: '2rem',
              marginRight: '0.8rem',
            },
          }}
        />
        <div>{getOptionLabel(props.option)}</div>
      </Box>
    </li>
  );
};

type Props = {
  style?: CSS.Properties;
  textFieldStyle?: CSS.Properties;
  currentPath: string;
};

const getStyles = (palette: Palette) => ({
  listbox: {
    maxHeight: '14rem',
  },
  elementContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottomStyle: 'none',
    borderBottomWidth: 0,
    borderBottomColor: palette.primary.main,
    padding: '0.7rem',
  },
});

const getOptionLabel = (option: ICompanyMinimal): string =>
  `${option.tickerId} - ${option.companyName}`;

const AutocompleteTickerIdComponent = (props: Props) => {
  const dispatch = useDispatch();

  /* Search shortcut */
  const [autocompleteRef, setAutocompleteFocus] = useFocus();
  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.code === 'KeyF') {
        e.preventDefault();
        (setAutocompleteFocus as () => void)();
      }
    };

    window.addEventListener('keydown', handleKeydown);

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [setAutocompleteFocus]);

  const [searchInputValue, setSearchInputValue] = useState<string>('');
  const theme = useTheme();
  const companies = useSelector(
    (state: RootState) => state.companies.companies
  );
  const selectedCountries = useSelector(
    (state: RootState) => state.companies.selectedCountries
  );
  const selectedIndustries = useSelector(
    (state: RootState) => state.companies.selectedIndustries
  );
  const mostViewedCompanies = useSelector(
    (state: RootState) => state.companies.mostViewedCompanies
  );

  const autocompleteRequest = debounce(
    300,
    throttle(
      300,
      (nextValue: string, countries: ISecIndCty[], industries: string[]) => {
        dispatch(searchCompanies(nextValue, countries, industries));
      }
    )
  );

  const setSearchInputValueHandler = (value: string): void => {
    const input: string = value.toLowerCase().trim();

    if (input.length) {
      autocompleteRequest(input, selectedCountries, selectedIndustries);
    } else {
      dispatch(setCompaniesData(mostViewedCompanies));
    }

    setSearchInputValue(value);
  };

  const autocompleteStyles = getAutocompleteStyles(theme);
  const styles = getStyles(theme.palette);
  const selectStyles = useSelectStyles();

  const hasActiveLicence = useSelector(
    (state: RootState) => state.authentication.hasActiveLicence
  );

  const history = useHistory();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  const changeHandler = (
    event: React.ChangeEvent<{}>,
    value: ICompanyMinimal | null
  ) => {
    if (value) {
      if (isBlurredCompany(value, !!hasActiveLicence)) {
        dispatch(openActiveFreeTrialDialog());
      } else {
        dispatch(setTickerId(value.tickerId));
        dispatch(fetchCompanyData(value.tickerId));

        const currentPath = `/${props.currentPath}`;
        if (
          (
            [
              RouteEnum.USER,
              RouteEnum.SECTOR_ANALYSIS,
              RouteEnum.SCREENER,
              RouteEnum.MARKET_COVERAGE,
            ] as string[]
          ).includes(currentPath) ||
          currentPath.includes(AdminRouteEnum.HOME)
        ) {
          history.push(RouteEnum.COMPANY_SUMMARY);
        }
      }
    }
  };

  const inputChangeHandler = (
    event: React.ChangeEvent<{}>,
    value: string,
    reason: string
  ) => {
    event?.stopPropagation();
    if (reason === 'input') {
      setSearchInputValueHandler(value);
    }
  };

  return (
    <form
      noValidate
      onSubmit={(event) => {
        event?.preventDefault();
      }}
      autoComplete="off"
      style={{ width: '80%', ...props.style }}
    >
      <Autocomplete
        disableListWrap
        autoHighlight
        filterOptions={(options) => options} // Otherwise, it automatically filters options
        PaperComponent={(props) => (
          <Paper
            {...props}
            sx={{
              ...selectStyles.menuPaper,
              ...autocompleteStyles.paper,
              '& .MuiAutocomplete-option.Mui-focused': {
                backgroundColor: `${theme.palette.primary.main} !important`,
                color: COMMON_COLORS.contrastPrimaryText,
              },
            }}
          />
        )}
        ListboxProps={{
          style: {
            ...autocompleteStyles.listbox,
            ...styles.listbox,
          },
        }}
        // Styling
        sx={{
          width: '100%',
          '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
            padding: isPortraitMobile
              ? '0.5rem 1rem !important'
              : '1.2rem 1rem !important',
            height: isPortraitMobile ? '2rem' : 'auto',
          },
          [`& .${autocompleteClasses.noOptions}`]: autocompleteStyles.noOptions,
          '& .MuiOutlinedInput-root': {
            padding: '0rem 1rem !important',
          },
        }}
        // Options
        options={companies}
        getOptionLabel={getOptionLabel}
        renderOption={(props, option: ICompanyMinimal) => {
          const component = (
            <AutocompleteOption
              key={option.tickerId}
              renderProps={props}
              option={option}
              hasActiveLicence={hasActiveLicence}
            />
          );

          return component;
        }}
        // Input
        inputValue={searchInputValue}
        onChange={changeHandler}
        onInputChange={inputChangeHandler}
        renderInput={AutocompleteRenderInputComponent({
          label: '',
          placeholder: 'Search for a company',
          textFieldStyle: {
            fieldset: {
              borderRadius: '2rem',
            },
          },
          searchInputValue: searchInputValue,
          setSearchInputValue: setSearchInputValueHandler,
          notArrowAdornment: true,
          inputRef: autocompleteRef as MutableRefObject<any>,
          hasFilters: true,
          hasFiltersType: 'searchBar',
        })}
      />
    </form>
  );
};

export default AutocompleteTickerIdComponent;
