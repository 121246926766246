import { ISectorData } from '@prometeus/common';
import { Dispatch } from 'redux';
import { ISectorsPayload } from '../../../constants/companies.constants';
import {
  ISecIndCty,
  ISectorIndustry,
} from '../../../models/screener/structure.model';
import {
  CreateChartDatasetOptionsType,
  GroupBySectorEnum,
  IVisualPayload,
} from '../../../models/visuals.model';

export type PositionType = 'top' | 'bottom';
export type PageLabelType = 'Country' | 'Industry' | 'Sector';

export interface IAVisualChart {
  /* Labels */
  labels: string[]; // Main (full) label

  /* Data */
  dataArr: number[][];

  /* Utility data */
  checksum?: string;
  dateLabel?: boolean;

  /* Config data */
  datasetLabels: CreateChartDatasetOptionsType[];
  columns: string[];
  isPercentage?: boolean;

  /* Metadata */
  hasData: boolean;
  error?: string;
}

export interface IVisualsState {
  // Options
  topOption?: string;
  bottomOption?: string;

  // Filters
  groupBy: GroupBySectorEnum;
  timeWindow: [number, number];

  /* Country analysis */
  // Charts
  topCountryData?: IAVisualChart;
  topCountryDataUpdating?: boolean;
  topDistributionCountryData?: IAVisualChart;
  topDistributionCountryDataUpdating?: boolean;
  bottomCountryData?: IAVisualChart;
  bottomCountryDataUpdating?: boolean;
  bottomDistributionCountryData?: IAVisualChart;
  bottomDistributionCountryDataUpdating?: boolean;
  // Filters
  countryCountries: ISecIndCty[];
  countrySectors: ISectorIndustry[];
  countrySelectedIndustries: string[];
  countryIndustryCompaniesNumber?: number;

  /* Sector analysis */
  // Charts
  topSectorData?: IAVisualChart;
  topSectorDataUpdating?: boolean;
  topDistributionSectorData?: IAVisualChart;
  topDistributionSectorDataUpdating?: boolean;
  bottomSectorData?: IAVisualChart;
  bottomSectorDataUpdating?: boolean;
  bottomDistributionSectorData?: IAVisualChart;
  bottomDistributionSectorDataUpdating?: boolean;
  // Filters
  sectorData?: ISectorData[];
  sectorCountries: ISecIndCty[];
  sectorSectors: ISectorIndustry[];
  sectorSelectedIndustries: string[];
  sectorIndustryCompaniesNumber?: number;

  /* Industry analysis */
  // Charts
  topIndustryData?: IAVisualChart;
  topIndustryDataUpdating?: boolean;
  topDistributionIndustryData?: IAVisualChart;
  topDistributionIndustryDataUpdating?: boolean;
  bottomIndustryData?: IAVisualChart;
  bottomIndustryDataUpdating?: boolean;
  bottomDistributionIndustryData?: IAVisualChart;
  bottomDistributionIndustryDataUpdating?: boolean;
  // Filters
  industryData?: ISectorData[];
  industryCountries: ISecIndCty[];
  industrySectors: ISectorIndustry[];
  industrySelectedIndustries: string[];
  industryIndustryCompaniesNumber?: number;
}

export interface ILabelValue {
  label: string;
  value: string;
}

/***************************************
 *              Set Options            *
 ***************************************/
/* SET_TOP_CHART_OPTION */
export const SET_TOP_CHART_OPTION = 'SET_TOP_CHART_OPTION';
export interface ISetTopChartOption {
  type: typeof SET_TOP_CHART_OPTION;
  payload: string;
  asyncDispatch: Dispatch;
}

/* SET_BOTTOM_CHART_OPTION */
export const SET_BOTTOM_CHART_OPTION = 'SET_BOTTOM_CHART_OPTION';
export interface ISetBottomChartOption {
  type: typeof SET_BOTTOM_CHART_OPTION;
  payload: string;
  asyncDispatch: Dispatch;
}

/* SECTOR_ANALYSIS_UPDATE */
export const SECTOR_ANALYSIS_UPDATE = 'SECTOR_ANALYSIS_UPDATE';
export interface ISectorAnalysisUpdate {
  type: typeof SECTOR_ANALYSIS_UPDATE;
  payload: string[];
}

/***************************************
 *           Country Analysis          *
 ***************************************/ /* FETCH_CHART_DATA */
export const FETCH_CHART_DATA = 'FETCH_CHART_DATA';

export interface FetchChartDataI {
  type: typeof FETCH_CHART_DATA;
}

export const FETCH_CHART_DATA_SUCCESS = 'FETCH_CHART_DATA_SUCCESS';

export interface FetchChartDataSuccessI {
  type: typeof FETCH_CHART_DATA_SUCCESS;
}

export const FETCH_CHART_DATA_FAIL = 'FETCH_CHART_DATA_FAIL';

export interface FetchChartDataFailI {
  type: typeof FETCH_CHART_DATA_FAIL;
  payload: string; // error
}

export type FetchChartDataDispatchType =
  | FetchChartDataI
  | FetchChartDataSuccessI
  | FetchChartDataFailI;

/* TOP_COUNTRY_DATA */
export const TOP_COUNTRY_DATA = 'TOP_COUNTRY_DATA';
export interface ITopCountryData {
  type: typeof TOP_COUNTRY_DATA;
  payload: IVisualPayload;
}

/* TOP_DISTRIBUTION_COUNTRY_DATA */
export const TOP_DISTRIBUTION_COUNTRY_DATA = 'TOP_DISTRIBUTION_COUNTRY_DATA';
export interface ITopDistributionCountryData {
  type: typeof TOP_DISTRIBUTION_COUNTRY_DATA;
  payload: IVisualPayload;
}

/* BOTTOM_COUNTRY_DATA */
export const BOTTOM_COUNTRY_DATA = 'BOTTOM_COUNTRY_DATA';
export interface IBottomCountryData {
  type: typeof BOTTOM_COUNTRY_DATA;
  payload: IVisualPayload;
}

/* BOTTOM_DISTRIBUTION_COUNTRY_DATA */
export const BOTTOM_DISTRIBUTION_COUNTRY_DATA =
  'BOTTOM_DISTRIBUTION_COUNTRY_DATA';
export interface IBottomDistributionCountryData {
  type: typeof BOTTOM_DISTRIBUTION_COUNTRY_DATA;
  payload: IVisualPayload;
}

/***************************************
 *            Sector Analysis          *
 ***************************************/
/* TOP_SECTOR_DATA */
export const TOP_SECTOR_DATA = 'TOP_SECTOR_DATA';
export interface ITopSectorData {
  type: typeof TOP_SECTOR_DATA;
  payload: IVisualPayload;
}

/* TOP_DISTRIBUTION_SECTOR_DATA */
export const TOP_DISTRIBUTION_SECTOR_DATA = 'TOP_DISTRIBUTION_SECTOR_DATA';
export interface ITopDistributionSectorData {
  type: typeof TOP_DISTRIBUTION_SECTOR_DATA;
  payload: IVisualPayload;
}

/* BOTTOM_SECTOR_DATA */
export const BOTTOM_SECTOR_DATA = 'BOTTOM_SECTOR_DATA';
export interface IBottomSectorData {
  type: typeof BOTTOM_SECTOR_DATA;
  payload: IVisualPayload;
}

/* BOTTOM_DISTRIBUTION_SECTOR_DATA */
export const BOTTOM_DISTRIBUTION_SECTOR_DATA =
  'BOTTOM_DISTRIBUTION_SECTOR_DATA';
export interface IBottomDistributionSectorData {
  type: typeof BOTTOM_DISTRIBUTION_SECTOR_DATA;
  payload: IVisualPayload;
}

/* TOP_INDUSTRY_DATA */
export const TOP_INDUSTRY_DATA = 'TOP_INDUSTRY_DATA';
export interface ITopIndustryData {
  type: typeof TOP_INDUSTRY_DATA;
  payload: IVisualPayload;
}

/* TOP_DISTRIBUTION_INDUSTRY_DATA */
export const TOP_DISTRIBUTION_INDUSTRY_DATA = 'TOP_DISTRIBUTION_INDUSTRY_DATA';
export interface ITopDistributionIndustryData {
  type: typeof TOP_DISTRIBUTION_INDUSTRY_DATA;
  payload: IVisualPayload;
}

/* BOTTOM_INDUSTRY_DATA */
export const BOTTOM_INDUSTRY_DATA = 'BOTTOM_INDUSTRY_DATA';
export interface IBottomIndustryData {
  type: typeof BOTTOM_INDUSTRY_DATA;
  payload: IVisualPayload;
}

/* BOTTOM_DISTRIBUTION_INDUSTRY_DATA */
export const BOTTOM_DISTRIBUTION_INDUSTRY_DATA =
  'BOTTOM_DISTRIBUTION_INDUSTRY_DATA';
export interface IBottomDistributionIndustryData {
  type: typeof BOTTOM_DISTRIBUTION_INDUSTRY_DATA;
  payload: IVisualPayload;
}

export const ACTIONS_ARR = [
  TOP_COUNTRY_DATA,
  TOP_DISTRIBUTION_COUNTRY_DATA,
  BOTTOM_COUNTRY_DATA,
  BOTTOM_DISTRIBUTION_COUNTRY_DATA,
  TOP_SECTOR_DATA,
  TOP_DISTRIBUTION_SECTOR_DATA,
  BOTTOM_SECTOR_DATA,
  BOTTOM_DISTRIBUTION_SECTOR_DATA,
  TOP_INDUSTRY_DATA,
  TOP_DISTRIBUTION_INDUSTRY_DATA,
  BOTTOM_INDUSTRY_DATA,
  BOTTOM_DISTRIBUTION_INDUSTRY_DATA,
];

/* FETCH_SECTOR_ANALYSIS_SECTORS_DATA */
export const FETCH_SECTOR_ANALYSIS_SECTORS_DATA_SUCCESS =
  'FETCH_SECTOR_ANALYSIS_SECTORS_DATA_SUCCESS';
export const FETCH_SECTOR_ANALYSIS_SECTORS_DATA_FAIL =
  'FETCH_SECTOR_ANALYSIS_SECTORS_DATA_FAIL';

export interface FetchSectorAnalysisSectorsDataFailI {
  type: typeof FETCH_SECTOR_ANALYSIS_SECTORS_DATA_FAIL;
  payload: string;
}

export interface FetchSectorAnalysisSectorsDataSuccessI {
  type: typeof FETCH_SECTOR_ANALYSIS_SECTORS_DATA_SUCCESS;
  payload: ISectorsPayload;
}

export type FetchSectorAnalysisSectorsDataDispachTypes =
  | FetchSectorAnalysisSectorsDataFailI
  | FetchSectorAnalysisSectorsDataSuccessI;

/***************************************
 *          SELECT_COUNTRY_IDS         *
 ***************************************/
/* SELECT_COUNTRY_COUNTRY_IDS */
export const SELECT_COUNTRY_COUNTRY_IDS = 'SELECT_COUNTRY_COUNTRY_IDS';
export interface SelectCountryCountryIdsI {
  type: typeof SELECT_COUNTRY_COUNTRY_IDS;
  payload: number;
}

/* SELECT_SECTOR_COUNTRY_IDS */
export const SELECT_SECTOR_COUNTRY_IDS = 'SELECT_SECTOR_COUNTRY_IDS';
export interface SelectSectorCountryIdsI {
  type: typeof SELECT_SECTOR_COUNTRY_IDS;
  payload: number;
}

/* SELECT_INDUSTRY_COUNTRY_IDS */
export const SELECT_INDUSTRY_COUNTRY_IDS = 'SELECT_INDUSTRY_COUNTRY_IDS';
export interface SelectIndustryCountryIdsI {
  type: typeof SELECT_INDUSTRY_COUNTRY_IDS;
  payload: number;
}

/***************************************
 *            SELECT_SECTORS           *
 ***************************************/
/* SELECT_COUNTRY_SECTORS */
export const SELECT_COUNTRY_SECTORS = 'SELECT_COUNTRY_SECTORS';
export interface SelectCountrySectorsI {
  type: typeof SELECT_COUNTRY_SECTORS;
  payload: number;
}

/* SELECT_SECTOR_SECTORS */
export const SELECT_SECTOR_SECTORS = 'SELECT_SECTOR_SECTORS';
export interface SelectSectorSectorsI {
  type: typeof SELECT_SECTOR_SECTORS;
  payload: number;
}

/* SELECT_INDUSTRY_SECTORS */
export const SELECT_INDUSTRY_SECTORS = 'SELECT_INDUSTRY_SECTORS';
export interface SelectIndustrySectorsI {
  type: typeof SELECT_INDUSTRY_SECTORS;
  payload: number;
}

/***************************************
 *           SELECT_INDUSTRIES         *
 ***************************************/
/* SELECT_COUNTRY_INDUSTRIES */
export const SELECT_COUNTRY_INDUSTRIES = 'SELECT_COUNTRY_INDUSTRIES';
export interface SelectCountryIndustriesI {
  type: typeof SELECT_COUNTRY_INDUSTRIES;
  payload: {
    sector: number;
    industry: number;
  };
}

/* SELECT_SECTOR_INDUSTRIES */
export const SELECT_SECTOR_INDUSTRIES = 'SELECT_SECTOR_INDUSTRIES';
export interface SelectSectorIndustriesI {
  type: typeof SELECT_SECTOR_INDUSTRIES;
  payload: {
    sector: number;
    industry: number;
  };
}

/* SELECT_INDUSTRY_INDUSTRIES */
export const SELECT_INDUSTRY_INDUSTRIES = 'SELECT_INDUSTRY_INDUSTRIES';
export interface SelectIndustryIndustriesI {
  type: typeof SELECT_INDUSTRY_INDUSTRIES;
  payload: {
    sector: number;
    industry: number;
  };
}

/***************************************
 *       FETCH_INDUSTRY_COMPANIES      *
 ***************************************/
/* FETCH_COUNTRY_INDUSTRY_COMPANIES_SUCCESS */
export const FETCH_COUNTRY_INDUSTRY_COMPANIES_SUCCESS =
  'FETCH_COUNTRY_INDUSTRY_COMPANIES_SUCCESS';
export interface IFetchCountryIndustryCompaniesSuccess {
  type: typeof FETCH_COUNTRY_INDUSTRY_COMPANIES_SUCCESS;
  payload: number;
}

/* FETCH_COUNTRY_INDUSTRY_COMPANIES_FAIL */
export const FETCH_COUNTRY_INDUSTRY_COMPANIES_FAIL =
  'FETCH_COUNTRY_INDUSTRY_COMPANIES_FAIL';
export interface IFetchCountryIndustryCompaniesFail {
  type: typeof FETCH_COUNTRY_INDUSTRY_COMPANIES_FAIL;
  payload: string;
}

export type FetchCountryIndustryCompaniesDispachTypes =
  | IFetchCountryIndustryCompaniesFail
  | IFetchCountryIndustryCompaniesSuccess;

/* FETCH_SECTOR_INDUSTRY_COMPANIES_SUCCESS */
export const FETCH_SECTOR_INDUSTRY_COMPANIES_SUCCESS =
  'FETCH_SECTOR_INDUSTRY_COMPANIES_SUCCESS';
export interface IFetchSectorIndustryCompaniesSuccess {
  type: typeof FETCH_SECTOR_INDUSTRY_COMPANIES_SUCCESS;
  payload: number;
}

/* FETCH_SECTOR_INDUSTRY_COMPANIES_FAIL */
export const FETCH_SECTOR_INDUSTRY_COMPANIES_FAIL =
  'FETCH_SECTOR_INDUSTRY_COMPANIES_FAIL';
export interface IFetchSectorIndustryCompaniesFail {
  type: typeof FETCH_SECTOR_INDUSTRY_COMPANIES_FAIL;
  payload: string;
}

export type FetchSectorIndustryCompaniesDispachTypes =
  | IFetchSectorIndustryCompaniesFail
  | IFetchSectorIndustryCompaniesSuccess;

/* FETCH_INDUSTRY_INDUSTRY_COMPANIES_SUCCESS */
export const FETCH_INDUSTRY_INDUSTRY_COMPANIES_SUCCESS =
  'FETCH_INDUSTRY_INDUSTRY_COMPANIES_SUCCESS';
export interface IFetchIndustryIndustryCompaniesSuccess {
  type: typeof FETCH_INDUSTRY_INDUSTRY_COMPANIES_SUCCESS;
  payload: number;
}

/* FETCH_INDUSTRY_INDUSTRY_COMPANIES_FAIL */
export const FETCH_INDUSTRY_INDUSTRY_COMPANIES_FAIL =
  'FETCH_INDUSTRY_INDUSTRY_COMPANIES_FAIL';
export interface IFetchIndustryIndustryCompaniesFail {
  type: typeof FETCH_INDUSTRY_INDUSTRY_COMPANIES_FAIL;
  payload: string;
}

export type FetchIndustryIndustryCompaniesDispachTypes =
  | IFetchIndustryIndustryCompaniesFail
  | IFetchIndustryIndustryCompaniesSuccess;

/* SET_SECTOR_GROUP_BY */
export const SET_SECTOR_GROUP_BY = 'SET_SECTOR_GROUP_BY';
export interface ISetSectorGroupBy {
  type: typeof SET_SECTOR_GROUP_BY;
  payload: GroupBySectorEnum;
}

/* SET_TIME_WINDOW */
export const SET_TIME_WINDOW = 'SET_TIME_WINDOW';
export interface ISetTimeWindow {
  type: typeof SET_TIME_WINDOW;
  payload: [number, number];
  asyncDispatch: Dispatch;
}

/* APPLY_CTY_SEC_IND_FILTERS */
export const APPLY_CTY_SEC_IND_FILTERS = 'APPLY_CTY_SEC_IND_FILTERS';
export interface IApplyCtySecIndFilters {
  type: typeof APPLY_CTY_SEC_IND_FILTERS;
  payload: {
    country: ISecIndCty[];
    sector: ISectorIndustry[];
    industry: string[];
  };
  asyncDispatch: Dispatch;
}

/* FETCH_SECTOR_CHART_DATA */
export const FETCH_SECTOR_CHART_DATA = 'FETCH_SECTOR_CHART_DATA';
export interface FetchSectorChartDataI {
  type: typeof FETCH_SECTOR_CHART_DATA;
  payload: any;
}

export const FETCH_SECTOR_CHART_DATA_SUCCESS =
  'FETCH_SECTOR_CHART_DATA_SUCCESS';
export interface FetchSectorChartDataSuccessI {
  type: typeof FETCH_SECTOR_CHART_DATA_SUCCESS;
  payload: any;
}

export const FETCH_SECTOR_CHART_DATA_FAIL = 'FETCH_SECTOR_CHART_DATA_FAIL';
export interface FetchSectorChartDataFailI {
  type: typeof FETCH_SECTOR_CHART_DATA_FAIL;
  payload: string; // error
}

export type FetchSectorChartDataDispatchType =
  | FetchSectorChartDataI
  | FetchSectorChartDataSuccessI
  | FetchSectorChartDataFailI;

export type DispatchActionTypes =
  | ISetTimeWindow
  | IApplyCtySecIndFilters
  | ISetSectorGroupBy
  | FetchSectorChartDataDispatchType
  | ISectorAnalysisUpdate
  /* Country Analysis */
  | ITopCountryData
  | ITopDistributionCountryData
  | IBottomCountryData
  | IBottomDistributionCountryData
  /* Sector Analysis */
  | ITopSectorData
  | ITopDistributionSectorData
  | IBottomSectorData
  | IBottomDistributionSectorData
  /* Industry Analysis */
  | ITopIndustryData
  | ITopDistributionIndustryData
  | IBottomIndustryData
  | IBottomDistributionIndustryData
  /* Set Chart Option */
  | ISetTopChartOption
  | ISetBottomChartOption
  /* Fetch */
  | FetchSectorAnalysisSectorsDataDispachTypes
  | FetchCountryIndustryCompaniesDispachTypes
  | FetchSectorIndustryCompaniesDispachTypes
  | FetchIndustryIndustryCompaniesDispachTypes
  /* Select */
  | SelectCountryCountryIdsI
  | SelectSectorCountryIdsI
  | SelectIndustryCountryIdsI
  | SelectCountrySectorsI
  | SelectSectorSectorsI
  | SelectIndustrySectorsI
  | SelectCountryIndustriesI
  | SelectSectorIndustriesI
  | SelectIndustryIndustriesI;
