import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { BAR_CHART_PLUGINS } from '../../../../constants/charts/options.constants';
import { COMPANY_DIVIDENDS_TITLE_LABELS } from '../../utils/visuals.config';

const DividendYieldDistributionComponent = () => {
  return (
    <VisualChartWrapper
      dataLabel="dividendYieldDistribution"
      pageLabel="stock"
      titleLabel={COMPANY_DIVIDENDS_TITLE_LABELS.dividendYieldDistr}
      type="bar"
      xAxisType="category"
      chartOptions={BAR_CHART_PLUGINS}
      useDatasetLabels
    />
  );
};

export default DividendYieldDistributionComponent;
