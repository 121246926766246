import VisualChartWrapper from '../../../../../components/charts/visual-chart-wrapper.component';
import { PROFIT_MARGIN_AND_GROSS_MARGIN_TITLE_LABELS } from '../../../utils/visuals.config';

const ProfitMarginComponent = () => {
  return (
    <VisualChartWrapper
      dataLabel="profitMargin"
      pageLabel="incomeStatement"
      titleLabel={PROFIT_MARGIN_AND_GROSS_MARGIN_TITLE_LABELS.profitMargin}
      type="line"
      isTime
      useFiscalYear
    />
  );
};

export default ProfitMarginComponent;
