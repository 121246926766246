import { useTheme } from '@mui/material';
import { isFirefox, isIE } from 'react-device-detect';
import {
  BACKDROP_FILTER_GLASS,
  OPTION_PADDING_ITEM,
} from '../constants/general.constants';
import { COMMON_COLORS } from '../ui/colors';
import { formBorder, perc2HexOpacity, pxToRem } from '../ui/functions';

export const useSelectStyles = () => {
  const theme = useTheme();
  const palette = theme.palette;

  return {
    selectRoot: {
      paddingLeft: '0.5rem !important',
      '& .MuiSvgIcon-root': {
        right: '1.5rem',
      },

      '& fieldset': {
        border: 'none',
      },
    },
    outlined: {
      color: palette.text.primary,
    },
    select: {
      textAlign: 'left',
    },
    iconText: {
      color: palette.text.primary,
      // right: '1.5rem',
    },
    menuItemRoot: {
      color: palette.text.primary,
      // backgroundColor: palette.background.default,
      minHeight: `${pxToRem(36)} !important`,

      '&.Mui-selected': {
        backgroundColor: `${palette.primary.main} !important`,
        color: COMMON_COLORS.contrastPrimaryText,
      },

      '&:hover, &.Mui-focusVisible': {
        backgroundColor: `${palette.primary.main} !important`,
        color: COMMON_COLORS.contrastPrimaryText,

        '& .MuiCheckbox-root': {
          color: `${COMMON_COLORS.contrastPrimaryText} !important`,
        },

        '& .MuiListItemIcon-root': {
          color: `${COMMON_COLORS.contrastPrimaryText} !important`,
        },
      },
      padding: OPTION_PADDING_ITEM,
    },
    menuPaper: {
      '& .MuiList-padding': {
        paddingTop: pxToRem(8),
        paddingBottom: pxToRem(8),
      },
      '&:before': {
        filter: BACKDROP_FILTER_GLASS,
      },
      border: `${formBorder(palette)} !important`,
      borderRadius: '1rem !important',
      // 'backdrop-filter': BACKDROP_FILTER_GLASS,
      backdropFilter: BACKDROP_FILTER_GLASS,
      webkitBackdropFilter: BACKDROP_FILTER_GLASS,
      // '-webkit-backdrop-filter': BACKDROP_FILTER_GLASS,
      background: `${palette.background.default}${isIE || isFirefox ? '' : perc2HexOpacity(65)
        }`,
    },
    menuItemSelected: {
      backgroundColor: `${palette.primary.main} !important`,
      color: COMMON_COLORS.contrastPrimaryText,
    },
    listItemIcon: {
      color: palette.text.primary,
      minWidth: '2.5rem !important',
      paddingBottom: '0.2rem',
    },
    brandFlame: { marginLeft: '0.4rem', transform: 'scale(1.1)' },
    disabledMenuItem: {
      opacity: '1 !important',
      '& .MuiListItemIcon-root, .MuiListItemText-root, span.menu-item-label': {
        opacity: '0.38',
      },
      paddingRight: '0.7rem !important',
      '&:hover': {
        cursor: 'default !important',
        backgroundColor: 'transparent !important',
      },
    },
  };
};
