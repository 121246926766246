import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { PageIdEnum } from '../../../models/filters.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { RootState } from '../../../store/reducers/root.reducer';
import AnnualRevenueGrowthRateComponent from './components/revenue-and-profit/annual-revenue-growth-rate.component';
import ProfitabilityRankComponent from './components/revenue-and-profit/profitability-rank.component';
import RevenueGrowthYoyComponent from './components/revenue-and-profit/revenue-growth-yoy.component';
import RevenueRankComponent from './components/revenue-and-profit/revenue-rank.component';
import RevenueVsIncomeComponent from './components/revenue-and-profit/revenue-vs-income.component';

const RevenueAndProfitTab = () => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  /* Data updating */
  const revenueVsIncomeDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.revenueVsIncomeDataUpdating
  );
  const revenueGrowthYoyDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.revenueGrowthYoyDataUpdating
  );
  const revenueRankDataUpdating = useSelector(
    (state: RootState) => state.visuals.incomeStatement.revenueRankDataUpdating
  );
  const profitabilityRankDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.profitabilityRankDataUpdating
  );
  const annualRevenueGrowthRateDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.incomeStatement.annualRevenueGrowthRateDataUpdating
  );

  /* Change report page */
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.REVEUE_AND_PROFIT));
  }, [dispatch]);

  return (
    <>
      {revenueVsIncomeDataUpdating &&
        revenueGrowthYoyDataUpdating &&
        revenueRankDataUpdating &&
        profitabilityRankDataUpdating &&
        annualRevenueGrowthRateDataUpdating && (
          <LoadingComponent transparent={true} />
        )}
      {isPortraitMobile ? (
        <ResponsiveRow paddingBottom={8}>
          <RevenueVsIncomeComponent />
          <RevenueGrowthYoyComponent />
          <RevenueRankComponent />
          <ProfitabilityRankComponent />
          <AnnualRevenueGrowthRateComponent />
        </ResponsiveRow>
      ) : (
        <Stack direction="row" style={{ height: '100%' }}>
          <Stack
            direction="column"
            sx={{
              flex: '2 1 0',
            }}
          >
            <div
              style={{
                height: '52%',
                marginBottom: '3%',
              }}
            >
              <RevenueVsIncomeComponent />
            </div>
            <div
              style={{
                height: '38.3%',
              }}
            >
              <RevenueGrowthYoyComponent />
            </div>
          </Stack>
          <Stack
            direction="column"
            sx={{
              height: '93.3%',
              flex: '1 1 0',
            }}
          >
            <div
              style={{
                height: '31.5%',
                marginBottom: '5%',
              }}
            >
              <RevenueRankComponent />
            </div>
            <div
              style={{
                height: '31.5%',
                marginBottom: '5%',
              }}
            >
              <ProfitabilityRankComponent />
            </div>
            <div
              style={{
                height: '31.5%',
              }}
            >
              <AnnualRevenueGrowthRateComponent />
            </div>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default RevenueAndProfitTab;
