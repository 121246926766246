import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { PageIdEnum } from '../../../models/filters.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { RootState } from '../../../store/reducers/root.reducer';
import TotalAssetsComponent from '../components/overview/total-assets.component';
import TotalEquityComponent from '../components/overview/total-equity.component';
import TotalLiabilitiesComponent from '../components/overview/totali-liabilities.component';

const OverviewTab = () => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  /* Charts Data  Updating*/
  const totalAssetsDataUpdating = useSelector(
    (state: RootState) => state.visuals.balanceSheet.totalAssetsDataUpdating
  );
  const totalLiabilitiesDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.totalLiabilitiesDataUpdating
  );
  const totalEquityDataUpdating = useSelector(
    (state: RootState) => state.visuals.balanceSheet.totalEquityDataUpdating
  );

  /* Change Report Page */
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.BS_OVERVIEW));
  }, [dispatch]);

  return (
    <>
      {totalAssetsDataUpdating &&
        totalLiabilitiesDataUpdating &&
        totalEquityDataUpdating && <LoadingComponent transparent={true} />}

      <ResponsiveRow>
        <TotalAssetsComponent />
        <TotalLiabilitiesComponent />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom={!isPortraitMobile} paddingBottom={8}>
        {!isPortraitMobile && <div style={{ margin: '0 1rem' }}></div>}
        <TotalEquityComponent />
      </ResponsiveRow>
    </>
  );
};

export default OverviewTab;
