import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { ROS_ROE_TITLE_LABELS } from '../../utils/visuals.config';

const RosComponent = () => {
  return (
    <VisualChartWrapper
      type="line"
      dataLabel="ros"
      pageLabel="managementEffectiveness"
      titleLabel={ROS_ROE_TITLE_LABELS.ros}
      isTime
      useFiscalYear
    />
  );
};

export default RosComponent;
