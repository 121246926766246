import { SxProps, Typography, TypographyVariant } from '@mui/material';
import WaveSkeleton from '../../wave-skeleton.component';

type Props = {
  title: string;
  variant?: TypographyVariant;
  sx?: SxProps;
  skeleton?: boolean;
};

const FilterTitleComponent = (props: Props) => {
  return (
    <Typography
      gutterBottom
      variant={props.variant || 'h6'}
      sx={{
        fontSize: '1.4rem',
        textAlign: 'left' as 'left',
        // marginTop: '1rem',
        color: 'text.primary',
        ...props.sx,
      }}
    >
      {!!props.skeleton ? <WaveSkeleton /> : props.title}
    </Typography>
  );
};

export default FilterTitleComponent;
