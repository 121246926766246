import { ChartDataset } from 'chart.js';
import moment from 'moment';
import {
  CreateChartDatasetOptionsType,
  IVisualChart
} from '../../models/visuals.model';
import { COMMON_COLORS } from '../../ui/colors';
import { isInRange } from '../utils.constants';
import { getSizeRem } from './utils.constants';

export const COMPANY_LABEL_PLACEHOLDER = '[Company]';

export const createChartDatasetConfig = (
  screenWidth: number,
  labels: string[],
  data: number[],
  color: string,
  otherOptions?: CreateChartDatasetOptionsType,
  useDollars: boolean = false,
  currencyRate?: number[],
  isTime: boolean = true,
  isRadar: boolean = false,
  isBar: boolean = false,
  highlightNegatives?: boolean,
  isPortraitMobile?: boolean,
): ChartDataset => ({
  /* From Args */
  data: isTime
    ? data?.map((e: number, i: number): any => ({
      x: +labels[i],
      y:
        useDollars && !!currencyRate && currencyRate?.length > 0
          ? e / (currencyRate[i] || 1)
          : e,
    }))
    : useDollars && !!currencyRate && currencyRate?.length > 0
      ? data?.map((e: number, i: number) => e / (currencyRate[i] || 1))
      : data,

  /* Colors */
  backgroundColor: highlightNegatives
    ? data?.map((e) => (e < 0 ? `${COMMON_COLORS.error}66` : `${color}66`))
    : `${color}66`,
  borderColor: highlightNegatives
    ? data?.map((e) => (e < 0 ? COMMON_COLORS.error : color))
    : color,
  pointBorderColor: color,
  pointBackgroundColor: color,
  pointHoverBackgroundColor: color,
  pointHoverBorderColor: `${color}44`,

  /* Default */
  fill: false,
  tension: isRadar ? 0 : 0.1,

  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  borderWidth: isBar ? 0 : getSizeRem(3, screenWidth) + (isPortraitMobile ? 1 : 0),
  pointBorderWidth: getSizeRem(1, screenWidth),
  pointHoverRadius: getSizeRem(7, screenWidth) + (isPortraitMobile ? 2 : 0),
  pointHoverBorderWidth: getSizeRem(10, screenWidth),
  pointRadius: isRadar ? getSizeRem(5, screenWidth) : 0, // Increase performance
  pointHitRadius: getSizeRem(10, screenWidth),

  spanGaps: true /* Fill the empty data in lines */,
  /* Uncommon settings */
  ...otherOptions, // Includes label
});

export const buildDistrDatasetOptions = (
  datasetOption: CreateChartDatasetOptionsType,
  i: number,
  screenWidth: number
): CreateChartDatasetOptionsType =>
  !i // If first element, it's going to be `stepped`
    ? {
      ...datasetOption,
      type: 'line',
      fill: true,
      stepped: 'middle',
      borderWidth: getSizeRem(0.5, screenWidth),
      pointHoverRadius: getSizeRem(5, screenWidth),
      pointHoverBorderWidth: getSizeRem(8, screenWidth),
    }
    : {
      ...datasetOption,
      backgroundColor:
        COMMON_COLORS[`chartColor${i}` as keyof typeof COMMON_COLORS],
      barPercentage: getSizeRem(1, screenWidth),
      barThickness: getSizeRem(4, screenWidth),
    };

export interface IChartDatasetConfigParams {
  useDollars?: boolean;
  isTime?: boolean;
  isRadar?: boolean;
  isPie?: boolean;
  isBar?: boolean;
  customLineColor?: string;
  isDistr?: boolean;
  isXPercentage?: boolean;
  highlightNegatives?: boolean;
  fiscalYear?: [number, number];
  isPortraitMobile?: boolean;
}

export const createChartDatasetsConfig = <T extends IVisualChart>(
  data: T | undefined,
  screenWidth: number,
  options?: IChartDatasetConfigParams,
  currentCompanyTickerId: string = 'Company'
): ChartDataset[] => {
  /* Params */
  const useDollars = !!options?.useDollars;
  const isTime = !!options?.isTime;
  const isRadar = !!options?.isRadar;
  const isPie = !!options?.isPie;
  const isBar = !!options?.isBar;
  const customLineColor = options?.customLineColor;
  const isDistr = !!options?.isDistr;
  const highlightNegatives = !!options?.highlightNegatives;
  const fiscalYear = options?.fiscalYear;
  const isPortraitMobile = options?.isPortraitMobile;

  /* Consider Fiscal Year */
  let fiscalKeepIndexes: Set<number> = new Set([]);
  if (!!data?.labels) {
    fiscalKeepIndexes = new Set([...data.labels.keys()]);
    if (!!fiscalYear) {
      fiscalKeepIndexes = new Set(
        [...data.labels.keys()]?.filter((index: number) =>
          isInRange(
            moment(+data.labels[index]).year(),
            fiscalYear[1],
            fiscalYear[0]
          )
        )
      );
    }
  }

  if (isPie) {
    fiscalKeepIndexes = new Set([Math.min(...fiscalKeepIndexes)]);
  }

  /* Compute dataset */
  const chartDataset: ChartDataset[] = !!data?.datasetLabels
    ? data.datasetLabels?.map(
      (_datasetOption: CreateChartDatasetOptionsType, i: number) => {
        const datasetOption = {
          ..._datasetOption,
          label: _datasetOption.label?.replace(
            COMPANY_LABEL_PLACEHOLDER,
            currentCompanyTickerId
          ),
        };

        return createChartDatasetConfig(
          screenWidth,
          (data?.labels || [])?.filter((_, index: number) =>
            fiscalKeepIndexes.has(index)
          ),
          (!!data
            ? (data[`data${i + 1}` as keyof IVisualChart] as number[])
            : []
          )?.filter((_, index: number) => fiscalKeepIndexes.has(index)),
          customLineColor ||
          COMMON_COLORS[
          `chartColor${isDistr ? (!i ? data.datasetLabels.length : i) : i + 1
          }` as keyof typeof COMMON_COLORS
          ],
          isDistr
            ? buildDistrDatasetOptions(datasetOption, i, screenWidth)
            : datasetOption,
          useDollars,
          data?.currencyRate?.filter((_, index: number) =>
            fiscalKeepIndexes.has(index)
          ),
          isTime,
          isRadar,
          isBar,
          highlightNegatives,
          isPortraitMobile
        );
      }
    )
    : [];

  if (isDistr && chartDataset.length) {
    chartDataset.push(chartDataset.shift() as ChartDataset);
  }

  return isRadar ? [chartDataset[0]] : chartDataset;
};
