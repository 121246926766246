import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { PageIdEnum } from '../../../models/filters.model';
import {
  ISecIndCty,
  ISectorIndustry,
} from '../../../models/screener/structure.model';
import { GroupBySectorEnum } from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { RootState } from '../../../store/reducers/root.reducer';
import SectorAnalysisRowWrapper from '../components/sector-analysis-row-wrapper.component';
import { setSectorGroupBy } from '../utils/visuals.actions';

const IndustryAnalysisTab = () => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  const industrySectors = useSelector(
    (state: RootState) => state.visuals.sector.industrySectors
  );

  const hasSecInds = (): boolean =>
    !!industrySectors?.filter(
      (e: ISectorIndustry) =>
        e.selected &&
        e.industries.some((industry: ISecIndCty) => industry.selected)
    )?.length;

  /* Change Report Page */
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.INDUSTRY_ANALYSIS));
    dispatch(setSectorGroupBy(GroupBySectorEnum.INDUSTRY));
  }, [dispatch]);

  return (
    <>
      <SectorAnalysisRowWrapper
        position="top"
        pageLabel="Industry"
        hasSelectedElements={hasSecInds}
      />
      <SectorAnalysisRowWrapper
        position="bottom"
        pageLabel="Industry"
        hasSelectedElements={hasSecInds}
        noMarginBottom
        paddingBottom={isPortraitMobile ? 5 : 3}
      />
    </>
  );
};

export default IndustryAnalysisTab;
