import { Box, Stack, Theme } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { TimeScale } from 'chart.js';
import 'chartjs-adapter-moment';
import { StrictMode, useEffect, useState } from 'react';
import { conditionalRegistration } from 'react-chartjs3-wrapper';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, useHistory, useLocation } from 'react-router-dom';
import './App.scss';
import AppbarComponent from './components/appbar.component';
import ModalsComponent from './components/modals/modals.component';
import SplashScreenComponent from './components/splash-screen.component';
import { MINUTES_TO_REFRESH } from './constants/general.constants';
import { useMediaQueryPortraitMobile } from './hooks/responsive-design.hook';
import { useGlobalStyles } from './hooks/use-global-styles.hook';
import { useWindowSize } from './hooks/use-window-size.hook';
import AdminNavigationMenuComponent from './navigation/admin-navigation-menu.component';
import MobileMenuComponent from './navigation/mobile-menu.component';
import NavigationMenuComponent from './navigation/navigation-menu.component';
import NavigationSwitchComponent from './navigation/navigation-switch.component';
import { RouteEnum } from './navigation/routes.constants';
import Providers from './providers';
import {
  getDummyToken,
  getLoggedUserData,
  renewToken,
} from './store/actions/authentication.action';
import { getMostViewedCompanies } from './store/actions/companies.action';
import { setGlobalError } from './store/actions/error-handling.action';
import { fetchSectorsData } from './store/actions/sector-data.action';
import { setScreenSize } from './store/actions/structure.action';
import { RootState } from './store/reducers/root.reducer';
import AxiosInterceptor from './utils/axios-interceptor';
import { signOutBundler } from './utils/common';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const InnerApp = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  /***************************************
   *            Authentication           *
   ***************************************/
  const accessToken = useSelector(
    (state: RootState) => state.authentication.accessToken
  );
  const isAdmin = useSelector(
    (state: RootState) => state.authentication.isAdmin
  );

  /***************************************
   *            Error Handling           *
   ***************************************/
  /* Global Error Handler */
  useEffect(() => {
    const errorHandler = (event: ErrorEvent) => {
      // Avoid displaying an error that shouldn't occur
      // if (
      //   event?.filename !==
      //   'https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js'
      // ) {
      //   dispatch(setGlobalError({ message: event?.message }));
      // }
      dispatch(setGlobalError({ message: event?.message }));
    };
    window.addEventListener('error', errorHandler);

    const unhandledRejectionHandler = (event: PromiseRejectionEvent) => {
      dispatch(setGlobalError({ message: event?.reason?.message }));
    };
    window.addEventListener('unhandledrejection', unhandledRejectionHandler);

    return () => {
      window.removeEventListener('error', errorHandler);
      window.removeEventListener(
        'unhandledrejection',
        unhandledRejectionHandler
      );
    };
  }, [dispatch]);

  /***************************************
   *            Token Handling           *
   ***************************************/
  // Check on startup
  useEffect(() => {
    if (!!accessToken) {
      // Get current time
      const currentTime = Date.now();
      const expiration = new Date(accessToken.expirationDate).getTime();
      const safetyInterval = MINUTES_TO_REFRESH * 60 * 1000;

      // Time until token refresh in milliseconds
      const timeout = expiration - currentTime - safetyInterval;

      // If token already expired, generate new token and set the access token
      if (timeout <= 0) {
        if (expiration <= currentTime) {
          signOutBundler(dispatch, history);
        } else {
          console.debug('Updating Access Token');
          dispatch(renewToken());
        }
      }
      // Set timeout so MINUTES_TO_REFRESH minutes before token expires, token will be updated
      else {
        console.debug(
          'Access Token will be updated in ' + timeout + ' milliseconds.'
        );
        const timer = setTimeout(() => {
          dispatch(renewToken());
        }, timeout);

        return () => {
          clearTimeout(timer);
        };
      }
    }
  }, [dispatch, accessToken, history]);

  // Fetch default company data on load (and login)
  const [fetchedInitData, setFetchedInitData] = useState<boolean>(false); // used to avoid multiple calls to this init fetching
  useEffect(() => {
    if (!fetchedInitData && !!accessToken?.token) {
      // Use a timeout to avoid localStorage slowness issue
      const timer = setTimeout(() => {
        dispatch(fetchSectorsData());
        dispatch(getMostViewedCompanies());
        dispatch(getLoggedUserData());

        setFetchedInitData(true);
      }, 500);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [dispatch, fetchedInitData, accessToken]);

  /* Routing */
  const { pathname } = useLocation();
  const currentPath = pathname.split('/')[1];

  /* Loadings */
  const loadingSectorsData = useSelector(
    (state: RootState) => state.companies.loadingSectorsData
  );
  const loadingMostViewedCompanies = useSelector(
    (state: RootState) => state.companies.loading
  );
  const loadingAuthentication = useSelector(
    (state: RootState) => state.authentication.loading
  );

  const showSplashScreen =
    loadingSectorsData || loadingMostViewedCompanies || loadingAuthentication;

  /* Admin Menu */
  const adminMenu = isAdmin && currentPath.includes('admin');

  /* Payment route */
  const isPaymentRoute = currentPath === RouteEnum.PAYMENT.slice(1);

  /* Portrait Mobile */
  const isPortraitMobile = useMediaQueryPortraitMobile();

  return (
    <>
      <ModalsComponent />
      {showSplashScreen && <SplashScreenComponent />}
      <Stack>
        {!!accessToken?.token && (
          <>
            {!showSplashScreen && (
              <AppbarComponent
                currentPath={currentPath}
                isPaymentRoute={isPaymentRoute}
              />
            )}
            {!isPaymentRoute && (
              <>
                {isPortraitMobile ? (
                  <MobileMenuComponent />
                ) : adminMenu ? (
                  <AdminNavigationMenuComponent
                    currentPath={pathname.split('/').slice(1).join('/')}
                  />
                ) : (
                  <>
                    {!showSplashScreen && (
                      <NavigationMenuComponent currentPath={currentPath} />
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </Stack>
      <NavigationSwitchComponent
        adminMenu={adminMenu}
        showSplashScreen={!!showSplashScreen}
      />
    </>
  );
};

const OuterApp = () => {
  const dispatch = useDispatch();

  // Get dummy token
  useEffect(() => {
    dispatch(getDummyToken());
  }, [dispatch]);

  /* ChartJS */
  useEffect(() => {
    /* Register only necessary elements */
    conditionalRegistration({
      line: true,
      bar: true,
      pie: true,
      radar: true,
      title: true,
      legend: true,
      tooltip: true,
      filler: true,
      others: [TimeScale /* ChartDataLabels */],
      choropleth: true,
    });
  }, []);

  const styles = useGlobalStyles();

  /* Screen Width */
  const screenSize = useWindowSize();

  useEffect(() => {
    dispatch(setScreenSize(screenSize));
  }, [dispatch, screenSize]);

  const accessToken = useSelector(
    (state: RootState) => state.authentication.accessToken
  );

  return (
    <BrowserRouter>
      <AxiosInterceptor>
        <CssBaseline />
        <Box sx={styles.body}>
          {accessToken ? <InnerApp /> : <SplashScreenComponent />}
        </Box>
      </AxiosInterceptor>
    </BrowserRouter>
  );
};

const App = () => {
  return (
    // @ts-ignore
    <StrictMode>
      <Providers>
        <OuterApp />
      </Providers>
    </StrictMode>
  );
};

export default App;
