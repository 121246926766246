import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StatementsTableComponent from '../../../components/charts/statements-table.component';
import { COMMON_STYLES } from '../../../constants/general.constants';
import { PageIdEnum } from '../../../models/filters.model';
import { IStatementsData } from '../../../models/visuals.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { RootState } from '../../../store/reducers/root.reducer';
import { CF_STATEMENTS_CONFIG } from '../utils/visuals.config';

const CFStatementsTab = () => {
  const dispatch = useDispatch();

  const statementsData = useSelector(
    (state: RootState) => state.visuals.cashflow.statementsData
  );
  const statementsDataUpdating = useSelector(
    (state: RootState) => state.visuals.cashflow.statementsDataUpdating
  );

  /* Change Report Page */
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.CF_STATEMENT));
  }, [dispatch]);

  return (
    <div
      style={{
        ...COMMON_STYLES.flexDisplay,
        height: '97%',
      }}
    >
      <StatementsTableComponent
        statements={(statementsData as IStatementsData)?.map}
        statementsConfig={CF_STATEMENTS_CONFIG}
        titleLabel="Cash Flow Statement"
        hasData={!!(statementsData as IStatementsData)?.map?.size}
        updating={statementsDataUpdating}
        containerHeight={true}
        containerWidth={true}
      />
    </div>
  );
};

export default CFStatementsTab;
