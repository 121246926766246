import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { COMMON_COLORS } from '../../../../ui/colors';
import { NONCURRENT_ASSETS_LIABILITIES_TITLE_LABELS } from '../../utils/visuals.config';

const NonCurrentAssetsComponent = () => {
  return (
    <VisualChartWrapper
      type="line"
      dataLabel="nonCurrentAssets"
      pageLabel="balanceSheet"
      titleLabel={NONCURRENT_ASSETS_LIABILITIES_TITLE_LABELS.nonCurrentAssets}
      useDollarsCurrency
      useFiscalYear
      isTime
      customLineColor={COMMON_COLORS.assetCharts}
      hideLegend
    />
  );
};

export default NonCurrentAssetsComponent;
