import { Grow, Tabs, tabsClasses, useTheme } from '@mui/material';
import { useMediaQueryPortraitMobile } from '../../hooks/responsive-design.hook';
import { AdminRouteEnum, RouteEnum } from '../../navigation/routes.constants';
import { getTabStyles } from '../../styles/tab.styles';
import PageSkeletonTab from './page-skeleton-tab.component';
import { ITab } from './page-skeleton.component';

type GenericT = { [field: string]: any };
type Props<T = GenericT> = {
  tabValue: number;
  setTabValue: (tab: number) => void;
  tabs: ITab<T>[];
  pageRoute: RouteEnum | AdminRouteEnum;
  isDisabled?:
    | ((tab: ITab<T>, index: number) => boolean)
    | ((tab: ITab<T>) => boolean | undefined);
  url: string;
};

function PageSkeletonTabs<T = GenericT>({
  tabValue,
  setTabValue,
  pageRoute,
  tabs,
  isDisabled,
  url,
}: Props<T>) {
  const isPortraitMobile = useMediaQueryPortraitMobile();
  const theme = useTheme();
  const styles = getTabStyles(theme.palette, isPortraitMobile);

  return (
    <Grow in={true}>
      {/* Used to avoid scrollTop error */}
      <Tabs
        action={(ref) => ref?.updateIndicator()}
        sx={{
          ...styles.tabsRoot,
          [`& .${tabsClasses.indicator}`]: styles.indicator,
          '& .MuiTabs-scroller': {
            display: 'flex',
            overflowX: 'auto !important', // Enable horizontal scroll
            scrollbarWidth: 'none !important', // Hide scrollbar for Firefox
            '&::-webkit-scrollbar': {
              display: 'none !important', // Hide scrollbar for Chrome, Safari, and Edge
            },
            width: '100%', // Ensure the container stretches the full width
            maxWidth: '100vw', // Ensure it doesn’t go beyond the viewport width
          },

          '& .MuiTab-root': {
            flexShrink: '0 !important',
          },
        }}
        value={tabValue}
        indicatorColor="primary"
        textColor="secondary"
        {...(isPortraitMobile
          ? {
              variant: 'scrollable',
              scrollButtons: false,
              allowScrollButtonsMobile: true,
            }
          : {})}
        aria-label={`${pageRoute} tabs`}
      >
        {tabs.map((tab: ITab<T>, i: number) => (
          <PageSkeletonTab
            key={tab.label}
            tabValue={tabValue}
            tab={tab}
            index={i}
            isDisabled={isDisabled}
            pageRoute={pageRoute}
            setTabValue={setTabValue}
            url={url}
          />
        ))}
      </Tabs>
    </Grow>
  );
}

export default PageSkeletonTabs;
