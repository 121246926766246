import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { COMMON_STYLES } from '../../../constants/general.constants';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { PageIdEnum } from '../../../models/filters.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { RootState } from '../../../store/reducers/root.reducer';
import EquityTotalEquityComponent from '../components/equity/equity-total-equity.component';
import RetainedEarningsComponent from '../components/equity/retained-earnings.component';
import ShareCapitalAdditionalPaidInComponent from '../components/equity/share-capital-additional-paid-in.component';
import TreasuryStockComponent from '../components/equity/treasury-stock.component';

const EquityTab = () => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  /* Charts Data updating */
  const equityTotalEquityDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.equityTotalEquityDataUpdating
  );
  const shareCapitalAdditionalPaidInDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.shareCapitalAdditionalPaidInDataUpdating
  );
  const retainedEarningsDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.retainedEarningsDataUpdating
  );
  const treasuryStockDataUpdating = useSelector(
    (state: RootState) => state.visuals.balanceSheet.treasuryStockDataUpdating
  );

  /* Change Report Page */
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.EQUITY));
  }, [dispatch]);

  return (
    <>
      {equityTotalEquityDataUpdating &&
        shareCapitalAdditionalPaidInDataUpdating &&
        retainedEarningsDataUpdating &&
        treasuryStockDataUpdating && <LoadingComponent transparent={true} />}
      {isPortraitMobile ? (
        <ResponsiveRow paddingBottom={8}>
          <EquityTotalEquityComponent />
          <ShareCapitalAdditionalPaidInComponent />
          <RetainedEarningsComponent />
          <TreasuryStockComponent />
        </ResponsiveRow>
      ) : (
        <Stack direction="row" style={{ height: '93.3%' }}>
          <div style={{ flex: '1 1 0' }}></div>
          <Stack
            direction="column"
            justifyContent="space-between"
            sx={{
              flex: '3.5 1 0',
            }}
          >
            <div style={{ height: '32%' }}>
              <EquityTotalEquityComponent />
            </div>
            <Stack
              direction="row"
              style={{
                height: '32%',
              }}
            >
              <div style={COMMON_STYLES.flexOneOneZero}>
                <ShareCapitalAdditionalPaidInComponent />
              </div>
              <div style={COMMON_STYLES.flexOneOneZero}>
                <RetainedEarningsComponent />
              </div>
            </Stack>
            <div style={{ height: '32%' }}>
              <TreasuryStockComponent />
            </div>
          </Stack>
          <div style={{ flex: '1 1 0' }}></div>
        </Stack>
      )}
    </>
  );
};

export default EquityTab;
