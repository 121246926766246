import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { BAR_CHART_PLUGINS } from '../../../../constants/charts/options.constants';
import { DEBT_TO_EQUITY_DEBT_PAYBACK_TITLE_LABELS } from '../../utils/visuals.config';

const DebtToEquityDistributionComponent = () => {
  return (
    <VisualChartWrapper
      type="bar"
      dataLabel="deDistribution"
      pageLabel="financialStrength"
      titleLabel={
        DEBT_TO_EQUITY_DEBT_PAYBACK_TITLE_LABELS.debtToEquityDistribution
      }
      xAxisType="category"
      chartOptions={BAR_CHART_PLUGINS}
      useDatasetLabels
    />
  );
};

export default DebtToEquityDistributionComponent;
