import { OpenInNew } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import {
  ICompanyMinimal,
  isNullUndefined,
  toTitleCase,
} from '@prometeus/common';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Virtuoso } from 'react-virtuoso';
import { PeerGroupEnum } from '../../../../models/peer-group.model';
import {
  setPeerGroup,
  setPeers,
} from '../../../../store/actions/filters.action';
import { getIcons } from '../../../../store/actions/icons.action';
import { openTableDialog } from '../../../../store/actions/modals.action';
import {
  getCompaniesInternational,
  getCompaniesRegional,
} from '../../../../store/actions/peer-group.action';
import { RootState } from '../../../../store/reducers/root.reducer';
import CustomSelectComponent from '../../../select.component';
import TooltipIconButton from '../../../tooltip-icon-button.component';
import FilterTitleComponent from '../../filter-components/filter-title.component';
import PeerMenuItemComponent from '../../filter-components/peer-menu-item.component';

const PEER_GROUP_OPTIONS: string[] = [
  toTitleCase(PeerGroupEnum.REGIONAL),
  toTitleCase(PeerGroupEnum.INTERNATIONAL),
  toTitleCase(PeerGroupEnum.SUGGESTED),
  toTitleCase(PeerGroupEnum.CUSTOM),
];

type Props = {
  styles: any;
  thisStyles: any;
};

const PeerGroupFilter = ({ styles, thisStyles }: Props) => {
  const dispatch = useDispatch();

  // State
  const hasActiveLicence = useSelector(
    (state: RootState) => state.authentication.hasActiveLicence
  );
  const currentCompany = useSelector(
    (state: RootState) => state.companies.currentCompany
  );
  const peerGroup = useSelector((state: RootState) => state.filters.peerGroup);
  const peersLoading = useSelector(
    (state: RootState) => state.peerGroup.loading
  );
  const peers = useSelector((state: RootState) => state.peerGroup.peers);

  // Fetch icons
  useEffect(() => {
    if (!!peers?.size) {
      dispatch(
        getIcons([...peers.values()].map((e: ICompanyMinimal) => e.tickerId))
      );
    }
  }, [dispatch, peers]);

  // Get peers
  useEffect(() => {
    if (currentCompany) {
      switch (peerGroup) {
        case PeerGroupEnum.REGIONAL:
          dispatch(
            getCompaniesRegional(
              currentCompany.countryId,
              currentCompany.industry
            )
          );
          break;
        case PeerGroupEnum.INTERNATIONAL:
          dispatch(getCompaniesInternational(currentCompany.industry));
          break;
      }
    }
  }, [dispatch, peerGroup, currentCompany]);

  // Filter out the same company from the peers
  const filterPeers = (peer: ICompanyMinimal) =>
    ![currentCompany?.tickerId, currentCompany?.companyId].includes(
      peer.tickerId
    );

  // Open Peers dialog
  const onClickSetDialogOpenHandler = () => {
    dispatch(openTableDialog('peers'));
  };

  // Get number of peers
  const getPeersValue = () => {
    const peersValue = [...peers.values()].filter(filterPeers).length;
    return isNullUndefined(peersValue) ? '-' : peersValue < 0 ? 0 : peersValue;
  };

  // Select peer
  const onClickPeerGroupMenuItemHandler = (_value: string) => {
    const value = _value.toLowerCase() as PeerGroupEnum;
    dispatch(setPeerGroup(value));
    if (value === PeerGroupEnum.CUSTOM) {
      dispatch(setPeers(Array.from(peers?.keys())));
    }
  };

  return (
    <Stack direction="column" spacing={1.5} sx={{ height: '100%  ' }}>
      <Box>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ marginBottom: '0.5rem' }}
        >
          <FilterTitleComponent title={`Peer Group`} />
          <Stack direction="row" alignItems="center">
            <Typography variant="subtitle1" sx={{ fontSize: '1.2rem' }}>
              Group peers: {getPeersValue()}
            </Typography>
            {!peersLoading && peerGroup === PeerGroupEnum.CUSTOM && (
              <TooltipIconButton
                icon={<OpenInNew />}
                tooltip="Manage Peers"
                onClick={onClickSetDialogOpenHandler}
                iconButtonStyle={thisStyles.endAdornmentIconButton}
                IconButtonProps={{
                  component: 'span',
                }}
                tooltipStyle={thisStyles.endAdornmentIconButtonTooltip}
              />
            )}
          </Stack>
        </Stack>

        <CustomSelectComponent
          boxLabel="Group"
          option={toTitleCase(peerGroup)}
          options={PEER_GROUP_OPTIONS}
          setOption={onClickPeerGroupMenuItemHandler}
          boxSx={{
            flex: '1.8 1 0',
            // marginBottom: '-1.2rem',
          }}
          isDisabled={(option: string) =>
            hasActiveLicence
              ? false
              : [
                  toTitleCase(PeerGroupEnum.SUGGESTED),
                  toTitleCase(PeerGroupEnum.CUSTOM),
                ].includes(option)
          }
        />
      </Box>
      <Box
        sx={{
          flex: '1 1 0',
          ...styles.filterMargin,
        }}
      >
        <Box
          sx={{
            ...thisStyles.peersContainer,
            ...thisStyles.maskBorderShadow,
          }}
        >
          {/* @ts-ignore */}
          <Virtuoso
            data={[...peers.values()].filter(filterPeers).slice(0, 10)}
            itemContent={(index, peer: ICompanyMinimal) => (
              <PeerMenuItemComponent key={peer.tickerId} peer={peer} />
            )}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default PeerGroupFilter;
