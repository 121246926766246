import { Box } from '@mui/material';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { GenericT } from '../../constants/types';
import { ITab } from './page-skeleton.component';

type Props<T = GenericT> = {
  RouteComponent: React.FunctionComponent<any>;
  url: string;
  path: string;
  tabs: ITab<T>[];
  isDisabled?: (tab: ITab<T>, index: number) => boolean;
};

function PageSkeletonSwitch<T = GenericT>({
  RouteComponent,
  url,
  path,
  tabs,
  isDisabled,
}: Props<T>) {
  return (
    <Box
      sx={{
        flex: '3 1 0',
        minWidth: 0,
        // '-ms-overflow-style': 'none', // IE, Edge
        msOverflowStyle: 'none',
        scrollbarWidth: 'none', // Firefox

        '&::-webkit-scrollbar': {
          // Chrome, Safari, Opera
          display: 'none',
        },
      }}
    >
      <Switch>
        {tabs
          .filter((e: ITab<T>, i: number) =>
            !!isDisabled ? !isDisabled(e, i) : true
          )
          .map((e: ITab<T>) => {
            const TabComponent = e.component;
            return (
              <RouteComponent
                path={`${path}/${e.route}`}
                key={`${path}/${e.route}`}
              >
                <TabComponent {...(e?.extra as any)} />
              </RouteComponent>
            );
          })}
        <RouteComponent path={path}>
          <Redirect
            to={{
              pathname: `${url}/${tabs[0].route}`,
            }}
          />
        </RouteComponent>
        <Route path="*">
          <Redirect
            to={{
              pathname: `${path}`,
            }}
          />
        </Route>
      </Switch>
    </Box>
  );
}

export default PageSkeletonSwitch;
