import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { BAR_CHART_PLUGINS } from '../../../../constants/charts/options.constants';
import { CASH_FLOW_TITLE_LABELS } from '../../utils/visuals.config';

const CashFromInvestingActivitiesComponent = () => {
  return (
    <VisualChartWrapper
      type="bar"
      dataLabel="cashFromInvestingActivities"
      pageLabel="cashflow"
      titleLabel={CASH_FLOW_TITLE_LABELS.cashFromInvestingActivities}
      chartOptions={BAR_CHART_PLUGINS}
      useDollarsCurrency
      useFiscalYear
      isTime
      isBar
    />
  );
};

export default CashFromInvestingActivitiesComponent;
