import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { COMPANY_STOCKS_TITLE_LABELS } from '../../utils/visuals.config';

const MarketCapComponent = () => {
  return (
    <VisualChartWrapper
      type="line"
      dataLabel="marketCap"
      pageLabel="stock"
      titleLabel={COMPANY_STOCKS_TITLE_LABELS.marketCap}
      useDollarsCurrency
      isTime
      useFiscalYear
      hideLegend
    />
  );
};

export default MarketCapComponent;
