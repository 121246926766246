import { Typography } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { PageIdEnum } from '../../../models/filters.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { RootState } from '../../../store/reducers/root.reducer';
import AccountsReceivableComponent from '../components/current-asset-and-liabilities/accounts-receivable.component';
import CashEqShortTermInvestments from '../components/current-asset-and-liabilities/cash-eq-short-term-investments.component';
import CurrentAssetsComponent from '../components/current-asset-and-liabilities/current-assets.component';
import CurrentLiabilitiesComponent from '../components/current-asset-and-liabilities/current-liabilities.component';
import InventoriesComponent from '../components/current-asset-and-liabilities/inventories.component';
import OtherShortTermAssetsComponent from '../components/current-asset-and-liabilities/other-short-term-assets.component';
import OtherShortTermLiabilitiesComponent from '../components/current-asset-and-liabilities/other-short-term-liabilities.component';
import PayablesAccrualsComponent from '../components/current-asset-and-liabilities/payables-accruals.component';
import ShortTermDebtComponent from '../components/current-asset-and-liabilities/short-term-debt.component';

const CurrentAssetAndLiabilitiesTab = () => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  /* Chart data updating */
  const currentAssetsDataUpdating = useSelector(
    (state: RootState) => state.visuals.balanceSheet.currentAssetsDataUpdating
  );
  const cashEqShortTermInvestmentsDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.cashEqShortTermInvestmentsDataUpdating
  );
  const accountsReceivableDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.accountsReceivableDataUpdating
  );
  const inventoriesDataUpdating = useSelector(
    (state: RootState) => state.visuals.balanceSheet.inventoriesDataUpdating
  );
  const otherShortTermAssetsDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.otherShortTermAssetsDataUpdating
  );
  const currentLiabilitiesDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.currentLiabilitiesDataUpdating
  );
  const payablesAccrualsDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.payablesAccrualsDataUpdating
  );
  const shortTermDebtDataUpdating = useSelector(
    (state: RootState) => state.visuals.balanceSheet.shortTermDebtDataUpdating
  );
  const otherShortTermLiabilitiesDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.balanceSheet.otherShortTermLiabilitiesDataUpdating
  );

  /* Change Report Page */
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.CURRENT_ASSETS_AND_LIABILITIES));
  }, [dispatch]);

  return (
    <>
      {currentAssetsDataUpdating &&
        currentLiabilitiesDataUpdating &&
        cashEqShortTermInvestmentsDataUpdating &&
        accountsReceivableDataUpdating &&
        payablesAccrualsDataUpdating &&
        shortTermDebtDataUpdating &&
        inventoriesDataUpdating &&
        otherShortTermAssetsDataUpdating &&
        otherShortTermLiabilitiesDataUpdating && (
          <LoadingComponent transparent={true} />
        )}

      {isPortraitMobile ? (
        <ResponsiveRow paddingBottom={8}>
          <Typography variant="h5" sx={{ marginLeft: '1rem' }}>
            Assets
          </Typography>
          <CurrentAssetsComponent />
          <CashEqShortTermInvestments />
          <AccountsReceivableComponent />
          <InventoriesComponent />
          <OtherShortTermAssetsComponent />

          <Typography
            variant="h5"
            sx={{ marginLeft: '1rem', marginTop: '1rem' }}
          >
            Liabilities
          </Typography>
          <CurrentLiabilitiesComponent />
          <PayablesAccrualsComponent />
          <ShortTermDebtComponent />
          <OtherShortTermLiabilitiesComponent />
        </ResponsiveRow>
      ) : (
        <>
          <ResponsiveRow sx={{ height: '40%' }}>
            <CurrentAssetsComponent />
            <CurrentLiabilitiesComponent />
          </ResponsiveRow>
          <div style={{ height: '52%' }}>
            <ResponsiveRow>
              <CashEqShortTermInvestments />
              <AccountsReceivableComponent />
              <PayablesAccrualsComponent />
              <ShortTermDebtComponent />
            </ResponsiveRow>
            <ResponsiveRow noMarginBottom>
              <InventoriesComponent />
              <OtherShortTermAssetsComponent />
              <OtherShortTermLiabilitiesComponent />
              <div style={{ margin: '0 1rem' }}></div>
            </ResponsiveRow>
          </div>
        </>
      )}
    </>
  );
};

export default CurrentAssetAndLiabilitiesTab;
