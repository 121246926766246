import VisualChartWrapper from '../../../../../components/charts/visual-chart-wrapper.component';
import { REVENUE_AND_PROFIT_TITLE_LABELS } from '../../../utils/visuals.config';

const RevenueGrowthYoyComponent = () => {
  return (
    <VisualChartWrapper
      dataLabel="revenueGrowthYoy"
      pageLabel="incomeStatement"
      titleLabel={REVENUE_AND_PROFIT_TITLE_LABELS.revenueGrowthYoy}
      type="line"
      useDollarsCurrency
      isTime
      useFiscalYear
    />
  );
};

export default RevenueGrowthYoyComponent;
