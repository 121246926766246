import { EMPTY_DATASET_LABELS } from '../../../constants/charts/utils.constants';
import {
  ChartTypeEnum,
  IStatementsConfig,
  IVisualAggregatedConfig,
  IVisualConfig,
} from '../../../models/visuals.model';
import {
  ACCOUNTS_RECEIVABLE_QUARTERLY_DATA,
  ACCOUNTS_RECEIVABLE_YEARLY_DATA,
  BS_STATEMENTS_QUARTERLY_DATA,
  BS_STATEMENTS_YEARLY_DATA,
  CASH_EQ_SHORT_TERM_INVESTMENTS_QUARTERLY_DATA,
  CASH_EQ_SHORT_TERM_INVESTMENTS_YEARLY_DATA,
  CURRENT_ASSETS_QUARTERLY_DATA,
  CURRENT_ASSETS_YEARLY_DATA,
  CURRENT_LIABILITIES_QUARTERLY_DATA,
  CURRENT_LIABILITIES_YEARLY_DATA,
  EQUITY_TOTAL_EQUITY_QUARTERLY_DATA,
  EQUITY_TOTAL_EQUITY_YEARLY_DATA,
  INVENTORIES_QUARTERLY_DATA,
  INVENTORIES_YEARLY_DATA,
  LONG_TERM_DEBT_QUARTERLY_DATA,
  LONG_TERM_DEBT_YEARLY_DATA,
  LONG_TERM_INVESTMENTS_RECEIVABLE_QUARTERLY_DATA,
  LONG_TERM_INVESTMENTS_RECEIVABLE_YEARLY_DATA,
  NONCURRENT_ASSETS_QUARTERLY_DATA,
  NONCURRENT_ASSETS_YEARLY_DATA,
  NONCURRENT_LIABILITIES_QUARTERLY_DATA,
  NONCURRENT_LIABILITIES_YEARLY_DATA,
  OTHER_LONG_TERM_ASSETS_QUARTERLY_DATA,
  OTHER_LONG_TERM_ASSETS_YEARLY_DATA,
  OTHER_LONG_TERM_LIABILITIES_QUARTERLY_DATA,
  OTHER_LONG_TERM_LIABILITIES_YEARLY_DATA,
  OTHER_SHORT_TERM_ASSETS_QUARTERLY_DATA,
  OTHER_SHORT_TERM_ASSETS_YEARLY_DATA,
  OTHER_SHORT_TERM_LIABILITIES_QUARTERLY_DATA,
  OTHER_SHORT_TERM_LIABILITIES_YEARLY_DATA,
  PAYABLES_ACCRUALS_QUARTERLY_DATA,
  PAYABLES_ACCRUALS_YEARLY_DATA,
  PROPERTY_PLANT_EQUIPMENT_QUARTERLY_DATA,
  PROPERTY_PLANT_EQUIPMENT_YEARLY_DATA,
  RETAINED_EARNINGS_QUARTERLY_DATA,
  RETAINED_EARNINGS_YEARLY_DATA,
  SHARE_CAPITAL_ADDITIONAL_PAID_IN_QUARTERLY_DATA,
  SHARE_CAPITAL_ADDITIONAL_PAID_IN_YEARLY_DATA,
  SHORT_TERM_DEBT_QUARTERLY_DATA,
  SHORT_TERM_DEBT_YEARLY_DATA,
  TOTAL_ASSETS_QUARTERLY_DATA,
  TOTAL_ASSETS_YEARLY_DATA,
  TOTAL_EQUITY_QUARTERLY_DATA,
  TOTAL_EQUITY_YEARLY_DATA,
  TOTAL_LIABILITIES_QUARTERLY_DATA,
  TOTAL_LIABILITIES_YEARLY_DATA,
  TREASURY_STOCK_QUARTERLY_DATA,
  TREASURY_STOCK_YEARLY_DATA,
} from './visuals.model';

/***************************************
 *               Overview              *
 ***************************************/
const OVERVIEW_YEARLY_CONFIG: IVisualConfig[] = [
  {
    id: 'totalAssets',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Total Assets',
    dataAction: TOTAL_ASSETS_YEARLY_DATA,
    stateLabel: 'totalAssetsData',
    isCurrency: true,
    columns: ['Fiscal Year', 'Total Assets', 'Currency Rate', 'Report Date'],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'totalLiabilities',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Total Liabilities',
    dataAction: TOTAL_LIABILITIES_YEARLY_DATA,
    stateLabel: 'totalLiabilitiesData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Total Liabilities',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'totalEquity',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Total Equity',
    dataAction: TOTAL_EQUITY_YEARLY_DATA,
    stateLabel: 'totalEquityData',
    isCurrency: true,
    columns: ['Fiscal Year', 'Total Equity', 'Currency Rate', 'Report Date'],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
];

const OVERVIEW_QUARTERLY_CONFIG: IVisualConfig[] = [
  {
    id: 'totalmapAssetsQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Total Assets',
    dataAction: TOTAL_ASSETS_QUARTERLY_DATA,
    stateLabel: 'totalAssetsData',
    isCurrency: true,
    columns: [
      'Date',
      'Total Assets',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'totalLiabilitiesQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Total Liabilities',
    dataAction: TOTAL_LIABILITIES_QUARTERLY_DATA,
    stateLabel: 'totalLiabilitiesData',
    isCurrency: true,
    columns: [
      'Date',
      'Total Liabilities',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'totalEquityQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Total Equity',
    dataAction: TOTAL_EQUITY_QUARTERLY_DATA,
    stateLabel: 'totalEquityData',
    isCurrency: true,
    columns: [
      'Date',
      'Total Equity',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
];

export const OVERVIEW_CONFIG_AGGREGATED: IVisualAggregatedConfig = {
  yearly: OVERVIEW_YEARLY_CONFIG,
  quarterly: OVERVIEW_QUARTERLY_CONFIG,
};

/***************************************
 *      Current Assets Liabilities     *
 ***************************************/
const CURRENT_ASSETS_LIABILITIES_YEARLY_CONFIG: IVisualConfig[] = [
  {
    id: 'currentAssetsYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Total Current Assets',
    dataAction: CURRENT_ASSETS_YEARLY_DATA,
    stateLabel: 'currentAssetsData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Total Current Assets',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'cashEqShortTermInvestmentsYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Cash, Cash Equivalents & Short Term Investments',
    dataAction: CASH_EQ_SHORT_TERM_INVESTMENTS_YEARLY_DATA,
    stateLabel: 'cashEqShortTermInvestmentsData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Cash, Cash Equivalents & Short Term Investments',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'accountsReceivableYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Accounts & Notes Receivable',
    dataAction: ACCOUNTS_RECEIVABLE_YEARLY_DATA,
    stateLabel: 'accountsReceivableData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Accounts & Notes Receivable',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'inventoriesYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Inventories',
    dataAction: INVENTORIES_YEARLY_DATA,
    stateLabel: 'inventoriesData',
    isCurrency: true,
    columns: ['Fiscal Year', 'Inventories', 'Currency Rate', 'Report Date'],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'otherShortTermAssetsYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Other Current Assets',
    dataAction: OTHER_SHORT_TERM_ASSETS_YEARLY_DATA,
    stateLabel: 'otherShortTermAssetsData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Other Current Assets',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'currentLiabilitiesYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Total Current Liabilities',
    dataAction: CURRENT_LIABILITIES_YEARLY_DATA,
    stateLabel: 'currentLiabilitiesData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Total Current Liabilities',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'payablesAccrualsYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Payables & Accruals',
    dataAction: PAYABLES_ACCRUALS_YEARLY_DATA,
    stateLabel: 'payablesAccrualsData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Payables & Accruals',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'shortTermDebtYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Short Term Debt',
    dataAction: SHORT_TERM_DEBT_YEARLY_DATA,
    stateLabel: 'shortTermDebtData',
    isCurrency: true,
    columns: ['Fiscal Year', 'Short Term Debt', 'Currency Rate', 'Report Date'],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'otherShortTermLiabilitiesYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Other Current Liabilities',
    dataAction: OTHER_SHORT_TERM_LIABILITIES_YEARLY_DATA,
    stateLabel: 'otherShortTermLiabilitiesData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Other Current Liabilities',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
];

const CURRENT_ASSETS_LIABILITIES_QUARTERLY_CONFIG: IVisualConfig[] = [
  {
    id: 'currentAssetsQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Total Current Assets',
    dataAction: CURRENT_ASSETS_QUARTERLY_DATA,
    stateLabel: 'currentAssetsData',
    isCurrency: true,
    columns: [
      'Date',
      'Total Current Assets',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'cashEqShortTermInvestmentsQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Cash, Cash Equivalents & Short Term Investments',
    dataAction: CASH_EQ_SHORT_TERM_INVESTMENTS_QUARTERLY_DATA,
    stateLabel: 'cashEqShortTermInvestmentsData',
    isCurrency: true,
    columns: [
      'Date',
      'Cash, Cash Equivalents & Short Term Investments',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'accountsReceivableQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Accounts & Notes Receivable',
    dataAction: ACCOUNTS_RECEIVABLE_QUARTERLY_DATA,
    stateLabel: 'accountsReceivableData',
    isCurrency: true,
    columns: [
      'Date',
      'Accounts & Notes Receivable',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'inventoriesQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Inventories',
    dataAction: INVENTORIES_QUARTERLY_DATA,
    stateLabel: 'inventoriesData',
    isCurrency: true,
    columns: [
      'Date',
      'Inventories',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'otherShortTermAssetsQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Inventories',
    dataAction: OTHER_SHORT_TERM_ASSETS_QUARTERLY_DATA,
    stateLabel: 'otherShortTermAssetsData',
    isCurrency: true,
    columns: [
      'Date',
      'Inventories',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'currentLiabilitiesQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Total Current Liabilities',
    dataAction: CURRENT_LIABILITIES_QUARTERLY_DATA,
    stateLabel: 'currentLiabilitiesData',
    isCurrency: true,
    columns: [
      'Date',
      'Total Current Liabilities',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'payablesAccrualsQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Payables & Accruals',
    dataAction: PAYABLES_ACCRUALS_QUARTERLY_DATA,
    stateLabel: 'payablesAccrualsData',
    isCurrency: true,
    columns: [
      'Date',
      'Payables & Accruals',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'shortTermDebtQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Short Term Debt',
    dataAction: SHORT_TERM_DEBT_QUARTERLY_DATA,
    stateLabel: 'shortTermDebtData',
    isCurrency: true,
    columns: [
      'Date',
      'Short Term Debt',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'otherShortTermLiabilitiesQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Other Current Liabilities',
    dataAction: OTHER_SHORT_TERM_LIABILITIES_QUARTERLY_DATA,
    stateLabel: 'otherShortTermLiabilitiesData',
    isCurrency: true,
    columns: [
      'Date',
      'Other Current Liabilities',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
];

export const CURRENT_ASSETS_LIABILITIES_CONFIG_AGGREGATED: IVisualAggregatedConfig =
{
  yearly: CURRENT_ASSETS_LIABILITIES_YEARLY_CONFIG,
  quarterly: CURRENT_ASSETS_LIABILITIES_QUARTERLY_CONFIG,
};

/***************************************
 *   Noncurrent Assets and Liabilities *
 ***************************************/
const NONCURRENT_ASSETS_LIABILITIES_YEARLY_CONFIG: IVisualConfig[] = [
  {
    id: 'nonCurrentAssetsYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Total Non-Current Assets',
    dataAction: NONCURRENT_ASSETS_YEARLY_DATA,
    stateLabel: 'nonCurrentAssetsData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Total Non-Current Assets',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'propertyPlantEquipmentYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Net Property, Plant & Equipment',
    dataAction: PROPERTY_PLANT_EQUIPMENT_YEARLY_DATA,
    stateLabel: 'propertyPlantEquipmentData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Net Property, Plant & Equipment',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'longTermInvestmentsReceivablesYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Long Term Investments',
    dataAction: LONG_TERM_INVESTMENTS_RECEIVABLE_YEARLY_DATA,
    stateLabel: 'longTermInvestmentsReceivablesData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Long Term Investments',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'otherLongTermAssetsYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Other Long Term Assets',
    dataAction: OTHER_LONG_TERM_ASSETS_YEARLY_DATA,
    stateLabel: 'otherLongTermAssetsData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Other Long Term Assets',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'nonCurrentLiabilitiesYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Total Non-Current Liabilities',
    dataAction: NONCURRENT_LIABILITIES_YEARLY_DATA,
    stateLabel: 'nonCurrentLiabilitiesData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Total Non-Current Liabilities',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'longTermDebtYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Long Term Debt',
    dataAction: LONG_TERM_DEBT_YEARLY_DATA,
    stateLabel: 'longTermDebtData',
    isCurrency: true,
    columns: ['Fiscal Year', 'Long Term Debt', 'Currency Rate', 'Report Date'],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'otherLongTermLiabilitiesYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Other Long Term Liabilities',
    dataAction: OTHER_LONG_TERM_LIABILITIES_YEARLY_DATA,
    stateLabel: 'otherLongTermLiabilitiesData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Other Long Term Liabilities',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
];

const NONCURRENT_ASSETS_LIABILITIES_QUARTERLY_CONFIG: IVisualConfig[] = [
  {
    id: 'nonCurrentAssetsQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Total Non-Current Assets',
    dataAction: NONCURRENT_ASSETS_QUARTERLY_DATA,
    stateLabel: 'nonCurrentAssetsData',
    isCurrency: true,
    columns: [
      'Date',
      'Total Non-Current Assets',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'propertyPlantEquipmentQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Net Property, Plant & Equipment',
    dataAction: PROPERTY_PLANT_EQUIPMENT_QUARTERLY_DATA,
    stateLabel: 'propertyPlantEquipmentData',
    isCurrency: true,
    columns: [
      'Date',
      'Net Property, Plant & Equipment',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'longTermInvestmentsReceivablesQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Long Term Investments',
    dataAction: LONG_TERM_INVESTMENTS_RECEIVABLE_QUARTERLY_DATA,
    stateLabel: 'longTermInvestmentsReceivablesData',
    isCurrency: true,
    columns: [
      'Date',
      'Long Term Investments',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'otherLongTermAssetsQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Other Long Term Assets',
    dataAction: OTHER_LONG_TERM_ASSETS_QUARTERLY_DATA,
    stateLabel: 'otherLongTermAssetsData',
    isCurrency: true,
    columns: [
      'Date',
      'Other Long Term Assets',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'nonCurrentLiabilitiesQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Total Non-Current Liabilities',
    dataAction: NONCURRENT_LIABILITIES_QUARTERLY_DATA,
    stateLabel: 'nonCurrentLiabilitiesData',
    isCurrency: true,
    columns: [
      'Date',
      'Total Non-Current Liabilities',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'longTermDebtQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Long Term Debt',
    dataAction: LONG_TERM_DEBT_QUARTERLY_DATA,
    stateLabel: 'longTermDebtData',
    isCurrency: true,
    columns: [
      'Date',
      'Long Term Debt',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'otherLongTermLiabilitiesQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Other Long Term Liabilities',
    dataAction: OTHER_LONG_TERM_LIABILITIES_QUARTERLY_DATA,
    stateLabel: 'otherLongTermLiabilitiesData',
    isCurrency: true,
    columns: [
      'Date',
      'Other Long Term Liabilities',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
];

export const NONCURRENT_ASSETS_LIABILITIES_CONFIG_AGGREGATED: IVisualAggregatedConfig =
{
  yearly: NONCURRENT_ASSETS_LIABILITIES_YEARLY_CONFIG,
  quarterly: NONCURRENT_ASSETS_LIABILITIES_QUARTERLY_CONFIG,
};

/***************************************
 *                Equity               *
 ***************************************/
const EQUITY_YEARLY_CONFIG: IVisualConfig[] = [
  {
    id: 'totalEquityYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Total Equity',
    dataAction: EQUITY_TOTAL_EQUITY_YEARLY_DATA,
    stateLabel: 'totalEquityData',
    isCurrency: true,
    columns: ['Fiscal Year', 'Total Equity', 'Currency Rate', 'Report Date'],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'shareCapitalAdditionalPaidInYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Share Capital & Additional Paid-In Capital',
    dataAction: SHARE_CAPITAL_ADDITIONAL_PAID_IN_YEARLY_DATA,
    stateLabel: 'shareCapitalAdditionalPaidInData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Share Capital & Additional Paid-In Capital',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'retainedEarningsYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Retained Earnings',
    dataAction: RETAINED_EARNINGS_YEARLY_DATA,
    stateLabel: 'retainedEarningsData',
    isCurrency: true,
    columns: [
      'Fiscal Year',
      'Retained Earnings',
      'Currency Rate',
      'Report Date',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'treasuryStockYearly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Treasury Stock',
    dataAction: TREASURY_STOCK_YEARLY_DATA,
    stateLabel: 'treasuryStockData',
    isCurrency: true,
    columns: ['Fiscal Year', 'Treasury Stock', 'Currency Rate', 'Report Date'],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
];

const EQUITY_QUARTERLY_CONFIG: IVisualConfig[] = [
  {
    id: 'totalEquityQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Total Equity',
    dataAction: EQUITY_TOTAL_EQUITY_QUARTERLY_DATA,
    stateLabel: 'totalEquityData',
    isCurrency: true,
    columns: [
      'Date',
      'Total Equity',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'shareCapitalAdditionalPaidInQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Share Capital & Additional Paid-In Capital',
    dataAction: SHARE_CAPITAL_ADDITIONAL_PAID_IN_QUARTERLY_DATA,
    stateLabel: 'shareCapitalAdditionalPaidInData',
    isCurrency: true,
    columns: [
      'Date',
      'Share Capital & Additional Paid-In Capital',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'retainedEarningsQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Retained Earnings',
    dataAction: RETAINED_EARNINGS_QUARTERLY_DATA,
    stateLabel: 'retainedEarningsData',
    isCurrency: true,
    columns: [
      'Date',
      'Retained Earnings',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
  {
    id: 'treasuryStockQuarterly',
    type: ChartTypeEnum.BASIC_CHART,
    item: 'Treasury Stock',
    dataAction: TREASURY_STOCK_QUARTERLY_DATA,
    stateLabel: 'treasuryStockData',
    isCurrency: true,
    columns: [
      'Date',
      'Treasury Stock',
      'Currency Rate',
      'Report Date',
      'Fiscal Year',
      'Fiscal Period',
    ],
    datasetLabels: EMPTY_DATASET_LABELS,
  },
];

export const EQUITY_CONFIG_AGGREGATED: IVisualAggregatedConfig = {
  yearly: EQUITY_YEARLY_CONFIG,
  quarterly: EQUITY_QUARTERLY_CONFIG,
};

export const BS_STATEMENTS_CONFIG: IStatementsConfig[] = [
  // SECTION: ASSETS
  {
    label: 'Cash, Cash Equivalents & Short Term Investments',
    column: 'Cash, Cash Equivalents & Short Term Investments',
    hierarchy: 2,
  },
  {
    label: 'Cash & Equivalents',
    column: 'Cash & Equivalents',
    hierarchy: 3,
  },
  {
    label: 'Short Term Investments',
    column: 'Short Term Investments',
    hierarchy: 3,
  },
  {
    label: 'Accounts & Notes Receivable',
    column: 'Accounts & Notes Receivable',
    hierarchy: 2,
  },
  {
    label: 'Inventories',
    column: 'Inventories',
    hierarchy: 2,
  },
  {
    label: 'Other Current Assets',
    column: 'Other Current Assets',
    hierarchy: 2,
  },
  {
    label: 'Total Current Assets',
    column: 'Total Current Assets',
    hierarchy: 1,
  },
  {
    label: 'Net Property, Plant & Equipment',
    column: 'Net Property, Plant & Equipment',
    hierarchy: 2,
  },
  {
    label: 'Gross Property, Plant & Equipment',
    column: 'Gross Property, Plant & Equipment',
    hierarchy: 3,
  },
  {
    label: 'Accumulated Depreciation',
    column: 'Accumulated Depreciation',
    hierarchy: 3,
  },
  {
    label: 'Long Term Investments',
    column: 'Long Term Investments',
    hierarchy: 2,
  },
  {
    label: 'Goodwill',
    column: 'Goodwill',
    hierarchy: 2,
  },
  {
    label: 'Negative Goodwill',
    column: 'Negative Goodwill',
    hierarchy: 2,
  },
  {
    label: 'Intangible Assets',
    column: 'Intangible Assets',
    hierarchy: 2,
  },
  {
    label: 'Other Long Term Assets',
    column: 'Other Long Term Assets',
    hierarchy: 2,
  },
  {
    label: 'Total Non-Current Assets',
    column: 'Total Non-Current Assets',
    hierarchy: 1,
  },
  {
    label: 'Total Assets',
    column: 'Total Assets',
    hierarchy: 1,
  },

  // SECTION: LIABILITIES & EQUITY
  {
    label: 'Payables & Accruals',
    column: 'Payables & Accruals',
    hierarchy: 2,
  },
  {
    label: 'Short Term Debt',
    column: 'Short Term Debt',
    hierarchy: 2,
  },
  {
    label: 'Other Current Liabilities',
    column: 'Other Current Liabilities',
    hierarchy: 2,
  },
  {
    label: 'Total Current Liabilities',
    column: 'Total Current Liabilities',
    hierarchy: 1,
  },
  {
    label: 'Long Term Debt',
    column: 'Long Term Debt',
    hierarchy: 2,
  },
  {
    label: 'Deferred Long Term Liabilities',
    column: 'Deferred Long Term Liabilities',
    hierarchy: 2,
  },
  {
    label: 'Other Long Term Liabilities',
    column: 'Other Long Term Liabilities',
    hierarchy: 2,
  },
  {
    label: 'Total Non-Current Liabilities',
    column: 'Total Non-Current Liabilities',
    hierarchy: 1,
  },
  {
    label: 'Total Liabilities',
    column: 'Total Liabilities',
    hierarchy: 1,
  },
  {
    label: 'Share Capital & Additional Paid-In Capital',
    column: 'Share Capital & Additional Paid-In Capital',
    hierarchy: 2,
  },
  {
    label: 'Retained Earnings',
    column: 'Retained Earnings',
    hierarchy: 2,
  },
  {
    label: 'Treasury Stock',
    column: 'Treasury Stock',
    hierarchy: 2,
  },
  {
    label: 'Other Stockholder Equity',
    column: 'Other Stockholder Equity',
    hierarchy: 2,
  },
  {
    label: 'Preferred Equity',
    column: 'Preferred Equity',
    hierarchy: 2,
  },
  {
    label: 'Total Equity',
    column: 'Total Equity',
    hierarchy: 1,
  },
  {
    label: 'Total Liabilities & Equity',
    column: 'Total Liabilities & Equity',
    hierarchy: 1,
  },
  {
    label: 'Net Working Capital',
    column: 'Net Working Capital',
    hierarchy: 5,
  },
  {
    label: 'Tangible Assets',
    column: 'Tangible Assets',
    hierarchy: 5,
  },
];

const bs_statements_items = BS_STATEMENTS_CONFIG.map(
  (e: IStatementsConfig) => e.column
);
export const BS_STATEMENTS_CONFIG_AGGREGATED: IVisualAggregatedConfig = {
  yearly: [
    {
      id: 'bsStatements',
      type: ChartTypeEnum.BASIC_CHART,
      item: bs_statements_items,
      dataAction: BS_STATEMENTS_YEARLY_DATA,
      stateLabel: 'statementsData',
      isCurrency: true,
      columns: [],
      datasetLabels: EMPTY_DATASET_LABELS,
    },
  ],
  quarterly: [
    {
      id: 'bsStatementsQuarterly',
      type: ChartTypeEnum.BASIC_CHART,
      item: bs_statements_items,
      dataAction: BS_STATEMENTS_QUARTERLY_DATA,
      stateLabel: 'statementsData',
      isCurrency: true,
      columns: [],
      datasetLabels: EMPTY_DATASET_LABELS,
    },
  ],
};

/***************************************
 *             Title Labels            *
 ***************************************/
export const BS_OVERVIEW_TITLE_LABELS = {
  totalAssets: 'Total Assets',
  totalLiabilities: 'Total Liabilities',
  totalEquity: 'Total Equity',
};

export const CURRENT_ASSETS_LIABILITIES_TITLE_LABELS = {
  currentAssets: 'Current Assets',
  cashEqShortTermInvestments: 'Cash Eq Short Term Investments',
  accountsReceivable: 'Accounts Receivable',
  inventories: 'Inventories',
  otherShortTermAssets: 'Other Short Term Assets',
  currentLiabilities: 'Current Liabilities',
  payablesAccruals: 'Payables Accruals',
  shortTermDebt: 'Short Term Debt',
  otherShortTermLiabilities: 'Other Short Term Liabilities',
};

export const NONCURRENT_ASSETS_LIABILITIES_TITLE_LABELS = {
  nonCurrentAssets: 'Non Currrent Assets',
  propertyPlantEquipment: 'Property Plant Equipment',
  longTermInvestmentsReceivables: 'Long Term Investments',
  otherLongTermAssets: 'Other Long Term Assets',
  nonCurrentLiabilities: 'Non Current Liabilities',
  longTermDebt: 'Long Term Debt',
  otherLongTermLiabilities: 'Other Long Term Liabilities',
};

export const EQUITY_TITLE_LABELS = {
  totalEquity: 'Total Equity',
  shareCapitalAdditionalPaidIn: 'Share Capital Additional Paid In',
  retainedEarnings: 'Retained Earnings',
  treasuryStock: 'Treasury Stock',
};
