import {
  alpha,
  Button,
  ButtonGroup,
  Palette,
  Stack,
  SxProps,
  useTheme,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { PREMIUM_FEATURE_TOOLTIP } from '../../../constants/general.constants';
import { IKeyValue } from '../../../models/other.model';
import { openActiveFreeTrialDialog } from '../../../store/actions/modals.action';
import { COMMON_COLORS } from '../../../ui/colors';
import { maskBorderShadow } from '../../../ui/functions';
import BrandFlame from '../../brand-flame.component';
import EnhancedTooltip from '../../enhanced-tooltip.component';

type Props = {
  data: IKeyValue<string>[];
  onClick: (value: string) => void;
  isButtonSelected: (value: string) => boolean;
  groupSx?: SxProps;
  isDisabled?: (key: string) => boolean;
};

const getStyles = (palette: Palette) => ({
  buttonGroupRoot: {
    display: 'flex',
    marginTop: '0.5rem',
    borderRadius: '2rem !important',
    padding: '0.3rem',
    ...maskBorderShadow(palette),
  },
  buttonRootSelected: {
    borderColor: palette.background.default,
    color: `${COMMON_COLORS.contrastPrimaryText} !important`,
    backgroundColor: palette.primary.main,
    fontSize: '0.9rem !important',
    '&:hover': {
      backgroundColor: alpha(palette.primary.main, 0.69),
    },
  },
  buttonRoot: {
    color: palette.text.primary,
    borderRadius: '2rem !important',
    fontSize: '0.8rem',
    border: 'none !important',
    '&:hover': {
      color:
        palette.mode === 'dark' ? COMMON_COLORS.contrastPrimaryText : 'black',
      backgroundColor: 'none',
    },
    width: '50% !important',
  },
});

const ButtonGroupComponent = (props: Props) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const styles = getStyles(theme.palette);

  return (
    <ButtonGroup
      size={'large'}
      sx={{
        ...styles.buttonGroupRoot,
        ...props.groupSx,
      }}
    >
      {props.data.map((e: IKeyValue<string>) => {
        const isDisabled = props.isDisabled ? props.isDisabled(e.key) : false;
        return (
          <Button
            key={e.value}
            sx={{
              ...styles.buttonRoot,
              ...(props.isButtonSelected(e.value)
                ? styles.buttonRootSelected
                : {}),
              ...(isDisabled
                ? {
                    opacity: '1 !important',
                    cursor: 'default !important',
                    '&:hover': {
                      color: `${theme.palette.text.primary} !important`,
                      backgroundColor: 'transparent !important',
                    },
                    '& span.button-group-label': {
                      opacity: '0.38 !important',
                    },
                  }
                : {}),
            }}
            onClick={(event) => {
              if (isDisabled) {
                event.stopPropagation();
                event.preventDefault();
                dispatch(openActiveFreeTrialDialog());
              } else {
                props.onClick(e.value);
              }
            }}
          >
            {isDisabled ? (
              <EnhancedTooltip title={PREMIUM_FEATURE_TOOLTIP}>
                <Stack direction="row" alignItems="center">
                  <span className="button-group-label">{e.key}</span>
                  <BrandFlame
                    sx={{
                      marginLeft: '0.4rem',
                      transform: 'scale(1)',
                    }}
                  />
                </Stack>
              </EnhancedTooltip>
            ) : (
              e.key
            )}
          </Button>
        );
      })}
    </ButtonGroup>
  );
};

export default ButtonGroupComponent;
