import { Stack } from '@mui/material';
import {
  IScreenerBetween,
  IScreenerFilter,
  ScreenerFilterCardType,
  ScreenerFilterNameEnum,
  ScreenerStockFilterCardEnum,
} from '@prometeus/common';
import { useDispatch, useSelector } from 'react-redux';
import CardComponent from '../../../../components/card.component';
import BetweenSliderComponent, {
  BetweenValuesType,
} from '../../../../components/filters/screener/between.component';
import { ScreenerTabNameEnum } from '../../../../models/screener/structure.model';
import { setFilterValue } from '../../../../store/actions/screener/structure.action';
import { RootState } from '../../../../store/reducers/root.reducer';
import {
  onBetweenValuesHandler,
  onMaxBetweenValueHandler,
  onMinBetweenValueHandler,
} from '../../utils/tab-change.handler';

const PriceFilterComponent = () => {
  const dispatch = useDispatch();

  const filterData: IScreenerFilter | null = useSelector((state: RootState) =>
    state.screener.structure.screenerStructure
      ? state.screener.structure.screenerStructure.stock?.price
      : null
  );

  const stockSetFilterValue = (
    block: ScreenerFilterCardType,
    filter: ScreenerFilterNameEnum,
    value: any
  ): void => {
    dispatch(setFilterValue(ScreenerTabNameEnum.STOCK, block, filter, value));
  };

  const minHandler = (value: number) => {
    onMinBetweenValueHandler(
      stockSetFilterValue,
      ScreenerStockFilterCardEnum.PRICE,
      value
    );
  };

  const maxHandler = (value: number) => {
    onMaxBetweenValueHandler(
      stockSetFilterValue,
      ScreenerStockFilterCardEnum.PRICE,
      value
    );
  };

  const betweenHandler = (values: BetweenValuesType) => {
    onBetweenValuesHandler(
      stockSetFilterValue,
      ScreenerStockFilterCardEnum.PRICE,
      values
    );
  };

  return (
    <CardComponent
      containerHeight={true}
      titleLabel={filterData?.boxLabel || ''}
      tabName={ScreenerTabNameEnum.STOCK}
      cardBlock={ScreenerStockFilterCardEnum.PRICE}
      activeFilters={!!filterData?.active}
      isFilterCard
    >
      <Stack sx={{ padding: '1rem' }} justifyContent="center">
        <BetweenSliderComponent
          title={(filterData?.components[0] as IScreenerBetween).label}
          step={(filterData?.components[0] as IScreenerBetween).step}
          min={(filterData?.components[0] as IScreenerBetween).min || 0}
          minValue={filterData?.thresholdGreater as number}
          setMinValue={minHandler}
          max={(filterData?.components[0] as IScreenerBetween).max || 0}
          maxValue={filterData?.thresholdLess as number}
          setMaxValue={maxHandler}
          setValues={betweenHandler}
          largeInputWidth={true}
          containerSx={{ padding: '0' }}
        />
      </Stack>
    </CardComponent>
  );
};

export default PriceFilterComponent;
