import { Stack, SxProps } from '@mui/material';
import { ResponsiveStyleValue } from '@mui/system';
import React from 'react';
import { COMMON_STYLES } from '../constants/general.constants';
import { useMediaQueryPortraitMobile } from '../hooks/responsive-design.hook';

const DEFAULT_HEIGHT = 45;
const DEFAULT_MOBILE_HEIGHT = 20;

type Props = {
  children: React.ReactNode;
  sx?: SxProps;
  direction?: ResponsiveStyleValue<
    'row' | 'row-reverse' | 'column' | 'column-reverse'
  >;
  noMarginBottom?: boolean;
  paddingBottom?: number;
  height?: number;
  mobileHeight?: number;
};

const ResponsiveRow = ({
  paddingBottom,
  direction,
  children,
  sx,
  noMarginBottom,
  height,
  mobileHeight,
}: Props) => {
  const isPortraitMobile = useMediaQueryPortraitMobile();
  return (
    <Stack
      direction={(isPortraitMobile ? 'column' : 'row') || direction}
      spacing={2}
      sx={{
        ...(isPortraitMobile
          ? {
              '& > div:not(.MuiBox-root)': {
                height: `${
                  mobileHeight ? mobileHeight : DEFAULT_MOBILE_HEIGHT
                }rem !important`,
              },
              paddingBottom: paddingBottom ? `${paddingBottom}rem` : '0',
            }
          : {
              '& > div': {
                flex: '1 1 0',
              },
              height: `${height ? height : DEFAULT_HEIGHT}%`,
            }),

        ...(!!noMarginBottom
          ? {}
          : isPortraitMobile
          ? { marginBottom: 0 }
          : COMMON_STYLES.cardRowBottomMargin),

        ...sx,
      }}
    >
      {children}
    </Stack>
  );
};

export default ResponsiveRow;
