import {
  IScreenerTab,
  ScreenerFilterCardType,
  ScreenerFilterNameEnum,
  ScreenerFinancialStrengthFilterCardEnum,
} from '@prometeus/common';
import { useDispatch, useSelector } from 'react-redux';
import ResponsiveRow from '../../../components/responsive-row.component';
import ScrollFillerComponent from '../../../components/scroll-filler.component';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { ScreenerTabNameEnum } from '../../../models/screener/structure.model';
import { setFilterValue } from '../../../store/actions/screener/structure.action';
import { RootState } from '../../../store/reducers/root.reducer';
import CommonBlockComponent from '../components/common-block.component';

const FinancialStrengthTab = () => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  const tabData: IScreenerTab | null = useSelector((state: RootState) =>
    state.screener.structure.screenerStructure
      ? state.screener.structure.screenerStructure.financialStrength
      : null
  );

  const financialStrengthSetFilterValue = (
    block: ScreenerFilterCardType,
    filter: ScreenerFilterNameEnum,
    value: any
  ): void => {
    dispatch(
      setFilterValue(
        ScreenerTabNameEnum.FINANCIAL_STRENGTH,
        block,
        filter,
        value
      )
    );
  };

  return (
    <>
      <ResponsiveRow height={40} mobileHeight={34}>
        <CommonBlockComponent
          tabName={ScreenerTabNameEnum.FINANCIAL_STRENGTH}
          cardBlock={ScreenerFinancialStrengthFilterCardEnum.CURRENT_RATIO}
          data={tabData?.currentRatio}
          setFilterValueHandler={financialStrengthSetFilterValue}
          isLarge={true}
        />
        <CommonBlockComponent
          tabName={ScreenerTabNameEnum.FINANCIAL_STRENGTH}
          cardBlock={ScreenerFinancialStrengthFilterCardEnum.DEBT_TO_EQUITY}
          data={tabData?.debtToEquity}
          setFilterValueHandler={financialStrengthSetFilterValue}
          isLarge={true}
        />
      </ResponsiveRow>
      <ResponsiveRow height={40} mobileHeight={34}>
        <CommonBlockComponent
          tabName={ScreenerTabNameEnum.FINANCIAL_STRENGTH}
          cardBlock={ScreenerFinancialStrengthFilterCardEnum.QUICK_RATIO}
          data={tabData?.quickRatio}
          setFilterValueHandler={financialStrengthSetFilterValue}
          isLarge={true}
        />
        <CommonBlockComponent
          tabName={ScreenerTabNameEnum.FINANCIAL_STRENGTH}
          cardBlock={ScreenerFinancialStrengthFilterCardEnum.DEBT_PAYBACK}
          data={tabData?.debtPayback}
          setFilterValueHandler={financialStrengthSetFilterValue}
          isLarge={true}
        />
      </ResponsiveRow>
      {isPortraitMobile && <ScrollFillerComponent />}
    </>
  );
};

export default FinancialStrengthTab;
