import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import { PageIdEnum } from '../../../models/filters.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { RootState } from '../../../store/reducers/root.reducer';
import RoeDistributionComponent from '../components/ros-roe/roe-distribution.component';
import RoeComponent from '../components/ros-roe/roe.component';
import RosDistributionComponent from '../components/ros-roe/ros-distribution.component';
import RosComponent from '../components/ros-roe/ros.component';

const RosRoeTab = () => {
  const dispatch = useDispatch();

  /* Charts Data */
  const rosDataUpdating = useSelector(
    (state: RootState) => state.visuals.managementEffectiveness.rosDataUpdating
  );
  const rosDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.managementEffectiveness.rosDistributionDataUpdating
  );
  const roeDataUpdating = useSelector(
    (state: RootState) => state.visuals.managementEffectiveness.roeDataUpdating
  );
  const roeDistributionDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.managementEffectiveness.roeDistributionDataUpdating
  );

  /* Change Report Page */

  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.ROS_ROE));
  }, [dispatch]);

  return (
    <>
      {rosDataUpdating &&
        rosDistributionDataUpdating &&
        roeDataUpdating &&
        roeDistributionDataUpdating && <LoadingComponent transparent={true} />}
      <ResponsiveRow>
        <RosComponent />
        <RosDistributionComponent />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom paddingBottom={7}>
        <RoeComponent />
        <RoeDistributionComponent />
      </ResponsiveRow>
    </>
  );
};

export default RosRoeTab;
