import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { CURRENT_QUICK_RATIO_CAPEX_TITLE_LABELS } from '../../utils/visuals.config';

const CurrentRatioComponent = () => {
  return (
    <VisualChartWrapper
      type="line"
      dataLabel="currentRatio"
      pageLabel="financialStrength"
      titleLabel={CURRENT_QUICK_RATIO_CAPEX_TITLE_LABELS.currentRatio}
      isTime
      useFiscalYear
    />
  );
};

export default CurrentRatioComponent;
