import { Box, Stack, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  RouteComponentProps,
  BrowserRouter as Router,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { GenericT } from '../../constants/types';
import { useMediaQueryPortraitMobile } from '../../hooks/responsive-design.hook';
import { useHandleQueryParams } from '../../hooks/use-handle-query-params.hook';
import { AuthenticatedRoute } from '../../navigation/route-wrappers';
import {
  AdminRouteEnum,
  PREMIUM_ROUTES,
  RouteEnum,
} from '../../navigation/routes.constants';
import { RootState } from '../../store/reducers/root.reducer';
import { getTabStyles } from '../../styles/tab.styles';
import CompanyHeaderInfoComponent from '../company-header-info.component';
import PageSkeletonFilters from './page-skeleton-filters.component';
import PageSkeletonSwitch from './page-skeleton-switch.component';
import PageSkeletonTabs from './page-skeleton-tabs.component';

export interface ITab<T = GenericT> {
  label: string;
  route: string;
  component: React.FunctionComponent<T>;
  extra?: T;
}

type Props<T = GenericT> = {
  pageRoute: RouteEnum | AdminRouteEnum;
  tabs: ITab<T>[];
  filtersComponent?: React.FunctionComponent<any>;
  routeComponent?: React.FunctionComponent<any>;
  hideCompanyHeader?: boolean;
  history?: RouteComponentProps['history'];
  isDisabled?: (tab: ITab<T>, index: number) => boolean;
};

function PageSkeletonComponent<T = GenericT>(props: Props<T>) {
  const isPortraitMobile = useMediaQueryPortraitMobile();
  const theme = useTheme();
  const styles = getTabStyles(theme.palette, isPortraitMobile);
  const { path, url } = useRouteMatch();

  const {
    tabs,
    pageRoute,
    filtersComponent: FiltersComponent,
    routeComponent,
  } = props;
  const RouteComponent = routeComponent || AuthenticatedRoute;

  const [tabValue, setTabValue] = useState<number>(0);

  const hasActiveLicence = useSelector(
    (state: RootState) => state.authentication.hasActiveLicence
  );

  const defaultIsDisabled = (tab: ITab<T>) =>
    !!hasActiveLicence || !pageRoute
      ? false
      : PREMIUM_ROUTES.get(pageRoute as RouteEnum)?.includes(tab.route);
  const isDisabled = props.isDisabled || defaultIsDisabled;

  const location = useLocation();
  useEffect(() => {
    const lastPath = location.pathname.split('/')[2];
    const tabIndex = tabs.findIndex((tab: ITab<T>) => tab.route === lastPath);
    setTabValue(tabIndex <= 0 ? 0 : tabIndex);
  }, [location, tabs]);

  /* Loadings */
  const loadingSectorsData = useSelector(
    (state: RootState) => state.companies.loadingSectorsData
  );
  const loadingMostViewedCompanies = useSelector(
    (state: RootState) => state.companies.loading
  );
  const loadingAuthentication = useSelector(
    (state: RootState) => state.authentication.loading
  );

  const showSplashScreen =
    loadingSectorsData || loadingMostViewedCompanies || loadingAuthentication;

  /*  */
  useHandleQueryParams();

  /* FiltersComponent */
  const filtersComponent = !!FiltersComponent ? (
    <FiltersComponent tab={tabValue} parentHistory={props.history} />
  ) : (
    <></>
  );

  return (
    <Router>
      <Box
        sx={{
          ...styles.root,
          overflow: isPortraitMobile ? 'hidden !important' : 'auto',
        }}
      >
        <Box
          sx={{
            ...styles.skeletonContainer,
            ...(!props.hideCompanyHeader ? styles.containerCompanyHeader : {}),
          }}
        >
          <Box
            sx={{
              flex: '3 1 0',
            }}
          >
            {!props.hideCompanyHeader && (
              <CompanyHeaderInfoComponent small={!isPortraitMobile} />
            )}

            <PageSkeletonTabs
              pageRoute={pageRoute}
              setTabValue={setTabValue}
              tabValue={tabValue}
              tabs={tabs}
              isDisabled={isDisabled}
              url={url}
            />
          </Box>
        </Box>
        <Stack
          direction="row"
          sx={{
            ...styles.tabView,
            ...styles.overflowY,
            ...(!props.hideCompanyHeader ? styles.tabViewCompanyHeader : {}),
            ...(!!props.hideCompanyHeader ? styles.tabViewNoCompanyHeader : {}),
            ...(isPortraitMobile ? { marginTop: '0.8rem' } : {}),
          }}
        >
          <PageSkeletonSwitch
            RouteComponent={RouteComponent}
            url={url}
            path={path}
            tabs={tabs}
          />
          {/* Filters column */}
          {!!FiltersComponent && (
            <PageSkeletonFilters
              filtersComponent={filtersComponent}
              showSplashScreen={!!showSplashScreen}
              path={path}
            />
          )}
        </Stack>
      </Box>
    </Router>
  );
}

export default PageSkeletonComponent;
