import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { BAR_CHART_PLUGINS } from '../../../../constants/charts/options.constants';
import { ROS_ROE_TITLE_LABELS } from '../../utils/visuals.config';

const RosDistributionComponent = () => {
  return (
    <VisualChartWrapper
      type="bar"
      dataLabel="rosDistribution"
      pageLabel="managementEffectiveness"
      titleLabel={ROS_ROE_TITLE_LABELS.rosDistribution}
      xAxisType="category"
      chartOptions={BAR_CHART_PLUGINS}
      useDatasetLabels
    />
  );
};

export default RosDistributionComponent;
