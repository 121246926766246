import { Stack } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useMediaQueryPortraitMobile } from '../../hooks/responsive-design.hook';
import { setOpenMobileFilterDialog } from '../../store/actions/structure.action';
import OutlinedButton from '../outlined-button.component';

const CloseFiltersDialogComponent = () => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  if (isPortraitMobile) {
    return (
      <Stack
        direction="row"
        justifyContent="center"
        sx={{ marginTop: 'auto !important' }}
      >
        <OutlinedButton
          label="Close"
          onClick={() => {
            dispatch(setOpenMobileFilterDialog(false));
          }}
          sx={{
            minWidth: '10rem !important',
            width: '10rem !important',
            flex: 0,
          }}
        />
      </Stack>
    );
  } else {
    return <></>;
  }
};

export default CloseFiltersDialogComponent;
