import { Stack, Tab, useTheme } from '@mui/material';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { PREMIUM_FEATURE_TOOLTIP } from '../../constants/general.constants';
import { GenericT } from '../../constants/types';
import { AdminRouteEnum, RouteEnum } from '../../navigation/routes.constants';
import { openActiveFreeTrialDialog } from '../../store/actions/modals.action';
import { a11yTabProps } from '../../styles/tab.styles';
import { COMMON_COLORS } from '../../ui/colors';
import BrandFlame from '../brand-flame.component';
import EnhancedTooltip from '../enhanced-tooltip.component';
import { ITab } from './page-skeleton.component';

type Props<T = GenericT> = {
  tab: ITab<T>;
  tabValue: number;
  index: number;
  setTabValue: (tab: number) => void;
  pageRoute: RouteEnum | AdminRouteEnum;
  isDisabled?:
    | ((tab: ITab<T>, index: number) => boolean)
    | ((tab: ITab<T>) => boolean | undefined);
  url: string;
};

function PageSkeletonTab<T = GenericT>({
  tab,
  tabValue,
  index,
  isDisabled,
  pageRoute,
  url,
  setTabValue,
}: Props<T>) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const disabled = !!isDisabled ? isDisabled(tab, index) : false;

  const innerTabComponent = (
    <Tab
      label={
        disabled ? (
          <Stack direction="row" alignItems="center" sx={{ minWidth: '100%' }}>
            <span className="tab-label">{tab.label}</span>
            <BrandFlame
              sx={{
                marginLeft: '0.4rem',
                transform: 'scale(1.2)',
              }}
            />
          </Stack>
        ) : (
          <span className="tab-label">{tab.label}</span>
        )
      }
      {...a11yTabProps(pageRoute, index)}
      component={Link as any}
      to={`${url}/${tab.route}`}
      tabIndex={index}
      value={index}
      onClick={() => {
        setTabValue(index);
      }}
      disabled={disabled}
      sx={{
        opacity: '1 !important',
        width: 'max-content !important',
        ...(disabled
          ? {
              // opacity: '1 !important',
              '& span.tab-label': {
                opacity: '0.38 !important',
              },
              '&:hover': {
                color: `${theme.palette.text.secondary} !important`,
              },
            }
          : {
              color:
                tabValue === index
                  ? `${COMMON_COLORS.lightBlue} !important`
                  : 'white',
            }),
      }}
    />
  );

  return disabled ? (
    <EnhancedTooltip
      title={PREMIUM_FEATURE_TOOLTIP}
      onInternalClick={() => {
        dispatch(openActiveFreeTrialDialog());
      }}
    >
      {innerTabComponent}
    </EnhancedTooltip>
  ) : (
    innerTabComponent
  );
}

export default PageSkeletonTab;
