import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import ScrollFillerComponent from '../../../components/scroll-filler.component';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { PageIdEnum } from '../../../models/filters.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { RootState } from '../../../store/reducers/root.reducer';
import CashFromFinancingActivitiesComponent from '../components/cashflow/cash-from-financing-activities.component';
import CashFromInvestingActivitiesComponent from '../components/cashflow/cash-from-investing-activities.component';
import CashFromOperatingActivities from '../components/cashflow/cash-from-operating-activities.component';
import CashSourcesComponent from '../components/cashflow/cash-sources.component';
import CashComponent from '../components/cashflow/cash.component';
import NetChangeInCashComponent from '../components/cashflow/net-change-in-cash.component';

const CashflowTab = () => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  /* Chart data updating */
  const cashDataUpdating = useSelector(
    (state: RootState) => state.visuals.cashflow.cashDataUpdating
  );
  const cashFromOperatingActivitiesDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.cashflow.cashFromOperatingActivitiesDataUpdating
  );
  const netChangeInCashDataUpdating = useSelector(
    (state: RootState) => state.visuals.cashflow.netChangeInCashDataUpdating
  );
  const cashFromInvestingActivitiesDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.cashflow.cashFromInvestingActivitiesDataUpdating
  );
  const cashSourcesDataUpdating = useSelector(
    (state: RootState) => state.visuals.cashflow.cashSourcesDataUpdating
  );
  const cashFromFinancingActivitiesDataUpdating = useSelector(
    (state: RootState) =>
      state.visuals.cashflow.cashFromFinancingActivitiesDataUpdating
  );

  /* Change Report Page */
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.CASH_FLOW));
  }, [dispatch]);

  return (
    <>
      {cashDataUpdating &&
        cashFromOperatingActivitiesDataUpdating &&
        netChangeInCashDataUpdating &&
        cashFromInvestingActivitiesDataUpdating &&
        cashSourcesDataUpdating &&
        cashFromFinancingActivitiesDataUpdating && (
          <LoadingComponent transparent={true} />
        )}
      <ResponsiveRow>
        <CashComponent />
        {isPortraitMobile ? (
          <NetChangeInCashComponent />
        ) : (
          <CashFromOperatingActivities />
        )}
      </ResponsiveRow>
      <ResponsiveRow>
        {isPortraitMobile ? (
          <CashFromOperatingActivities />
        ) : (
          <NetChangeInCashComponent />
        )}
        <CashFromInvestingActivitiesComponent />
      </ResponsiveRow>
      <ResponsiveRow paddingBottom={isPortraitMobile ? 7 : 0}>
        <CashSourcesComponent />
        <CashFromFinancingActivitiesComponent />
      </ResponsiveRow>
      {!isPortraitMobile && <ScrollFillerComponent />}
    </>
  );
};

export default CashflowTab;
