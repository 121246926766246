import { Backdrop, Palette, useTheme } from '@mui/material';
import ScaleLoader from 'react-spinners/ScaleLoader';
import { SCALE_LOADER_PROPS } from '../constants/loading';
import LinearProgressComponent from './linear-progress.component';

const useStyles = (palette: Palette) => ({
  backdropRoot: {
    zIndex: 1,
  },
  backdropBackground: {
    backgroundColor: `${palette.background.paper} !important`,
  },
});

type Props = {
  transparent?: boolean;
  linearProgress?: boolean;
  progress?: number;
};

const LoadingComponent = (props: Props) => {
  const theme = useTheme();
  const styles = useStyles(theme.palette);

  return (
    <Backdrop
      open={true}
      sx={{
        ...styles.backdropRoot,
        zIndex: 3,
        ...(!props.transparent ? styles.backdropBackground : {}),
      }}
    >
      {!!props.linearProgress ? (
        <LinearProgressComponent value={props.progress || 0} />
      ) : (
        // @ts-ignore
        <ScaleLoader {...SCALE_LOADER_PROPS} />
      )}
    </Backdrop>
  );
};

export default LoadingComponent;
