import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getRankChartOptions,
  tuneOptions,
} from '../../constants/charts/options.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
  getSizeRem,
  MAX_TICK_ROTATION,
  MIN_TICK_ROTATION,
  RANK_AMOUNT_THRESHOLD,
} from '../../constants/charts/utils.constants';
import { extractChartMetadata } from '../../constants/charts/visuals.constants';
import { DOLLAR_ST } from '../../constants/general.constants';
import { IOpenLargeChart, IVisualChartRank } from '../../models/visuals.model';
import { openLargeChartDialog } from '../../store/actions/modals.action';
import { RootState } from '../../store/reducers/root.reducer';
import { COMMON_COLORS } from '../../ui/colors';
import VisualChartComponent from './visual-chart.component';

const createRankingChartDatasetConfig = (
  data: IVisualChartRank | undefined,
  screenWidth: number,
  datasetOptions?: ChartOptions
): ChartDataset[] => [
  {
    label: '',
    data: data?.data1 || [],
    backgroundColor:
      data?.selectedTicker && data?.selectedTicker?.length > 0
        ? data?.selectedTicker?.map((e: number) =>
            e > 0
              ? `${COMMON_COLORS.chartColor1}66`
              : `${COMMON_COLORS.chartColor2}66`
          )
        : data?.data1?.map((_) => `${COMMON_COLORS.chartColor2}66`),
    borderColor:
      data?.selectedTicker && data?.selectedTicker?.length > 0
        ? data?.selectedTicker?.map((e: number) =>
            e > 0
              ? `${COMMON_COLORS.chartColor1}66`
              : `${COMMON_COLORS.chartColor2}66`
          )
        : data?.data1?.map((_) => `${COMMON_COLORS.chartColor2}66`),
    borderWidth: getSizeRem(1, screenWidth),
    minBarLength: getSizeRem(1, screenWidth), // min height in px
    fill: false,

    ...datasetOptions,
  },
];

type Props = {
  titleLabel: string;
  pageLabel: string;
  dataLabel: string;
  smallChart?: boolean;
};

const VisualRankChartWrapper = ({
  pageLabel,
  dataLabel,
  titleLabel,
  smallChart,
}: Props) => {
  const dispatch = useDispatch();

  const [metricRank, setMetricRank] = useState<ChartData>();
  const [metricRankFull, setMetricRankFull] = useState<ChartData>();

  // State
  const metricRankData = useSelector(
    (state: RootState) => (state.visuals as any)[pageLabel][`${dataLabel}Data`]
  );
  const metricRankDataUpdating = useSelector(
    (state: RootState) =>
      (state.visuals as any)[pageLabel][`${dataLabel}DataUpdating`]
  );
  const metricRankFullData = useSelector(
    (state: RootState) =>
      (state.visuals as any)[pageLabel][`${dataLabel}FullData`]
  );

  /* Common state */
  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );
  const currencySt =
    useSelector(
      (state: RootState) => state.companies.currentCompany?.currencySt
    ) || '';

  // Chart
  const [metricRankHasData, metricRankHasLabels, metricRankError] =
    extractChartMetadata(metricRankData);
  useEffect(() => {
    const labels = metricRankData?.labels;
    const ranks = metricRankData?.rank;

    if (labels && ranks) {
      setMetricRank({
        labels: labels.map((label: string, index: number): string[] => [
          label,
          `${ranks[index]}º`,
        ]),
        datasets: createRankingChartDatasetConfig(metricRankData, screenWidth),
      });
    }
  }, [metricRankData, screenWidth]);

  // Market Cap Rank Full
  useEffect(() => {
    let data: IVisualChartRank | undefined = metricRankFullData;

    // Build selected ticker if not present
    if (
      (!data?.selectedTicker || data?.selectedTicker?.length === 0) &&
      data?.labels &&
      data.rank
    ) {
      const ticker: string =
        metricRankData?.labels.find(
          (e: string, i: number) => metricRankData?.selectedTicker[i] > 0
        ) || '';
      const selectedTicker: number[] =
        data?.labels?.map((e: string) => (e === ticker ? 1 : 0)) || [];
      data = !!data
        ? {
            ...data,
            selectedTicker: selectedTicker,
          }
        : undefined;
      setMetricRankFull({
        labels: data?.labels.map(
          (label: string, index: number, arr: string[]): string[] => [
            label,
            arr.length < RANK_AMOUNT_THRESHOLD ? `${data?.rank[index]}º` : '',
          ]
        ),
        datasets: createRankingChartDatasetConfig(data, screenWidth),
      });
    } else {
      const labels = metricRankFullData?.labels;
      const ranks = metricRankFullData?.rank;

      setMetricRankFull({
        labels: labels?.map(
          (label: string, index: number, arr: string[]): string[] => [
            label,
            ranks && arr.length < RANK_AMOUNT_THRESHOLD
              ? `${ranks[index]}º`
              : '',
          ]
        ),
        datasets: createRankingChartDatasetConfig(
          metricRankFullData,
          screenWidth
        ),
      });
    }
  }, [metricRankFullData, metricRankData, screenWidth]);

  // Large chart
  const onOpenLargeClickMetricRankHandler = (data: IOpenLargeChart) => {
    dispatch(
      openLargeChartDialog({
        ...data,
        data: metricRankFull as ChartData,
        options: tuneOptions({
          screenWidth,
          options: getRankChartOptions(
            metricRankFullData &&
              metricRankFullData?.data1?.length > RANK_AMOUNT_THRESHOLD
              ? MAX_TICK_ROTATION
              : MIN_TICK_ROTATION,
            screenWidth
          ),
          showLegend: false,
          xType: 'category',
          tooltipSymbol:
            !!metricRankFullData?.isCurrency ||
            !!metricRankFullData?.isPercentage
              ? {
                  left: !!metricRankFullData?.isCurrency,
                  symbol: !!currencySt ? DOLLAR_ST : '%',
                }
              : undefined,
        }),
      })
    );
  };

  return (
    <VisualChartComponent
      type="bar"
      titleLabel={titleLabel}
      data={metricRank}
      error={
        !!metricRankError
          ? GENERAL_ERROR_MESSAGE
          : !metricRankHasLabels
          ? GENERAL_NO_DATA_MESSAGE
          : undefined
      }
      hasData={metricRankHasData}
      exportData={metricRankFullData}
      xAxisType="category"
      options={getRankChartOptions(0, screenWidth)}
      currencySymbol={!!metricRankData?.isCurrency ? DOLLAR_ST : undefined}
      isPercentage={!!metricRankData?.isPercentage}
      updating={!!metricRankDataUpdating}
      onOpenChartDialogClick={onOpenLargeClickMetricRankHandler}
      smallChart={smallChart}
    />
  );
};

export default VisualRankChartWrapper;
