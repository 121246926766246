import { Box } from '@mui/material';

type Props = {
  fill?: number;
};

const ScrollFillerComponent = ({ fill }: Props) => {
  return (
    <Box
      sx={{
        height: fill ? `${fill}rem` : `3rem`,
      }}
    ></Box>
  );
};

export default ScrollFillerComponent;
