import VisualRankChartWrapper from '../../../../../components/charts/visual-rank-chart-wrapper.component';
import { useMediaQueryPortraitMobile } from '../../../../../hooks/responsive-design.hook';
import { REVENUE_AND_PROFIT_TITLE_LABELS } from '../../../utils/visuals.config';

const ProfitabilityRankComponent = () => {
  const isPortraitMobile = useMediaQueryPortraitMobile();

  return (
    <VisualRankChartWrapper
      dataLabel="profitabilityRank"
      pageLabel="incomeStatement"
      titleLabel={REVENUE_AND_PROFIT_TITLE_LABELS.profitabilityRank}
      smallChart={!isPortraitMobile}
    />
  );
};

export default ProfitabilityRankComponent;
