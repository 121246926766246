import { Box } from '@mui/material';
import {
  ChartData,
  ChartDataset,
  ChartOptions,
  ChartTypeRegistry,
  Plugin,
  TimeUnit,
} from 'chart.js';
import { useCallback, useMemo, useState } from 'react';
import { ChartJsComponent } from 'react-chartjs3-wrapper';
import { useDispatch, useSelector } from 'react-redux';
import {
  CROSSHAIR_OPTIONS,
  tuneOptions,
} from '../../constants/charts/options.constants';
import { useMediaQueryPortraitMobile } from '../../hooks/responsive-design.hook';
import {
  ExportableDataType,
  IOpenLargeChart,
} from '../../models/visuals.model';
import { openLargeChartDialog } from '../../store/actions/modals.action';
import { RootState } from '../../store/reducers/root.reducer';
import CardLoadingComponent from '../card-loading.component';
import CardNoDataComponent from '../card-no-data.component';
import CardComponent from '../card.component';

interface Props {
  titleLabel?: string;
  hiddenTitleLabel?: string;
  hasData: boolean;
  exportData?: ExportableDataType;
  updating?: boolean;
  error?: string;
  warningIcon?: boolean;
  noDataMessageColor?: string;
  /* ChartJs */
  type: keyof ChartTypeRegistry;
  data?: ChartData;
  options?: ChartOptions;
  showLegend?: boolean;
  plugins?: Plugin[];
  /* Symbols */
  currencySymbol?: string;
  isPercentage?: boolean;
  /*  */
  isDistr?: boolean;
  /* X axis */
  xAxisType?: any;
  timeUnit?: TimeUnit;
  timeTooltipFormat?: string;
  /* Card */
  smallChart?: boolean;
  /* Icons */
  noInfoIcon?: boolean;
  onOpenChartDialogClick?: (data: IOpenLargeChart) => void;
  onOpenInfoClick?: (data: IOpenLargeChart) => void;
  /* Style */
  noCardStyle?: boolean;
}

const VisualChartComponent = (props: Props) => {
  const {
    titleLabel,
    hiddenTitleLabel,
    hasData,
    exportData,
    updating,
    error,
    warningIcon,
    noDataMessageColor,
    /* Chart Config */
    type,
    data,
    options,
    showLegend,
    plugins,
    /* Symbols */
    currencySymbol,
    isPercentage,
    /*  */
    isDistr,
    /* X axis */
    xAxisType,
    timeUnit,
    timeTooltipFormat,
    /* Card */
    smallChart,
    /* Card Icons */
    noInfoIcon,
    onOpenChartDialogClick,
    /* Style */
    noCardStyle,
  } = props;

  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );

  const tuneOptionsWrapped =
    type === 'radar'
      ? options
      : tuneOptions({
          screenWidth,
          options,
          showLegend,
          xType: xAxisType || 'time',
          timeUnit,
          tooltipFormat: timeTooltipFormat || 'DD MMM YYYY',
          tooltipSymbol:
            !!currencySymbol || isPercentage
              ? {
                  left: !!currencySymbol,
                  symbol: currencySymbol || '%',
                }
              : undefined,
          isPortraitMobile,
          isDistr,
        });

  const [chartImage, setChartImage] = useState<string>();
  const transferChartImage = useCallback((chartImageData: string) => {
    setChartImage(chartImageData);
  }, []);

  const ChildChartComponent = useMemo(() => {
    return (
      <>
        {hasData ? (
          <Box
            sx={{
              position: 'relative',
              width: '99%',
              height: '99%',
            }}
          >
            <>
              {/* Display error message if an error occured or "No Data Available" if datasets' data are empty*/}
              {error ||
              !!data?.datasets?.every(
                (e: ChartDataset) =>
                  !e.data?.filter((d: any) =>
                    !!Array.isArray(d) ? !!d?.length : !!d
                  ).length
              ) ? (
                <CardNoDataComponent
                  message={error}
                  warningIcon={warningIcon}
                  textColor={noDataMessageColor}
                />
              ) : (
                <ChartJsComponent
                  type={type}
                  data={data}
                  options={{ ...tuneOptionsWrapped, animation: false }}
                  enableVerticalCrosshair={type === 'line'}
                  enableHorizontalCrosshair={type === 'line'}
                  crosshairHorizontalOptions={CROSSHAIR_OPTIONS}
                  crosshairVerticalOptions={CROSSHAIR_OPTIONS}
                  transferChartImage={transferChartImage}
                  plugins={plugins}
                />
              )}
            </>
          </Box>
        ) : (
          <CardLoadingComponent />
        )}
      </>
    );
  }, [
    hasData,
    error,
    data,
    warningIcon,
    noDataMessageColor,
    type,
    tuneOptionsWrapped,
    plugins,
    transferChartImage,
  ]);

  const openChartDialogHandler = (data: IOpenLargeChart) => {
    if (!!onOpenChartDialogClick) {
      onOpenChartDialogClick(data);
    } else {
      dispatch(openLargeChartDialog(data));
    }
  };

  const OPEN_CHART_DIALOG_CONDITION =
    !!openChartDialogHandler &&
    !!data &&
    !!tuneOptionsWrapped &&
    !!type &&
    (!!titleLabel || !!hiddenTitleLabel);

  return (
    <CardComponent
      titleLabel={titleLabel}
      hasMoreInfo={!noInfoIcon}
      showLoading={hasData && updating}
      chartImage={chartImage}
      exportData={exportData}
      onOpenLargeClick={() => {
        if (OPEN_CHART_DIALOG_CONDITION) {
          openChartDialogHandler({
            data,
            options: tuneOptionsWrapped,
            type,
            titleLabel: (titleLabel || hiddenTitleLabel) as string,
          });
        }
      }}
      onInfoClickHandler={() => {
        if (OPEN_CHART_DIALOG_CONDITION) {
          openChartDialogHandler({
            data,
            options: tuneOptionsWrapped,
            type,
            titleLabel: (titleLabel || hiddenTitleLabel) as string,
            info: true,
          });
        }
      }}
      containerHeight={true}
      smallCard={smallChart}
      error={error}
      noCardStyle={noCardStyle}
    >
      {ChildChartComponent}
    </CardComponent>
  );
};

export default VisualChartComponent;
