import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { useMediaQueryPortraitMobile } from '../../../../hooks/responsive-design.hook';
import { COMMON_COLORS } from '../../../../ui/colors';
import { NONCURRENT_ASSETS_LIABILITIES_TITLE_LABELS } from '../../utils/visuals.config';

const OtherLongTermLiabilitiesComponent = () => {
  const isPortraitMobile = useMediaQueryPortraitMobile();

  return (
    <VisualChartWrapper
      type="line"
      dataLabel="otherLongTermLiabilities"
      pageLabel="balanceSheet"
      titleLabel={
        NONCURRENT_ASSETS_LIABILITIES_TITLE_LABELS.otherLongTermLiabilities
      }
      useDollarsCurrency
      useFiscalYear
      isTime
      customLineColor={COMMON_COLORS.liabilityCharts}
      hideLegend
      smallChart={!isPortraitMobile}
      mobileHeight={12}
    />
  );
};

export default OtherLongTermLiabilitiesComponent;
