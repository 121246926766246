import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
  DWMQFilter,
  QYFilter,
  TimeframeEnum,
} from '../../../../models/filters.model';
import { IKeyValue } from '../../../../models/other.model';
import {
  setDWMQFilter,
  setQYFilter,
} from '../../../../store/actions/filters.action';
import { RootState } from '../../../../store/reducers/root.reducer';
import ButtonGroupComponent from '../../filter-components/button-group.component';
import FilterTitleComponent from '../../filter-components/filter-title.component';

const DWMQY: IKeyValue<string>[] = [
  { key: 'Day', value: TimeframeEnum.DAILY },
  { key: 'Week', value: TimeframeEnum.WEEKLY },
  { key: 'Month', value: TimeframeEnum.MONTHLY },
  { key: 'Quarter', value: TimeframeEnum.QUARTERLY },
  { key: 'Year', value: TimeframeEnum.YEARLY },
];

type Props = {
  styles: any;
  isBPage: boolean;
};

const DataFrequencyFilter = ({ styles, isBPage }: Props) => {
  const dispatch = useDispatch();

  const hasActiveLicence = useSelector(
    (state: RootState) => state.authentication.hasActiveLicence
  );
  const activeQYFilter = useSelector(
    (state: RootState) => state.filters.activeQYFilter
  );
  const activeDWMQFilter = useSelector(
    (state: RootState) => state.filters.activeDWMQFilter
  );

  const isCurrentTimeframeFilter = (value: string): boolean =>
    (isBPage ? activeDWMQFilter : activeQYFilter) === value;

  const onClickSetDWMQYHandler = (filter: string) => {
    if (!isCurrentTimeframeFilter(filter)) {
      dispatch(
        isBPage
          ? setDWMQFilter(filter as DWMQFilter)
          : setQYFilter(filter as QYFilter)
      );
    }
  };

  return (
    <Box sx={{ marginTop: '2rem !important' }}>
      <FilterTitleComponent
        title="Data Frequency"
        sx={{ marginBottom: '1rem' }}
      />
      <ButtonGroupComponent
        data={DWMQY.filter((_, index: number) =>
          isBPage ? index < 4 : index > 2
        )}
        onClick={onClickSetDWMQYHandler}
        isButtonSelected={isCurrentTimeframeFilter}
        isDisabled={(key: string) =>
          hasActiveLicence || (isBPage && key === 'Month') || key === 'Year'
            ? false
            : true
        }
      />
    </Box>
  );
};

export default DataFrequencyFilter;
