import { Box } from '@mui/material';
import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { BAR_CHART_PLUGINS } from '../../../../constants/charts/options.constants';
import { useMediaQueryPortraitMobile } from '../../../../hooks/responsive-design.hook';
import { CASH_FLOW_TITLE_LABELS } from '../../utils/visuals.config';

const CashFromFinancingActivitiesComponent = () => {
  const isPortraitMobile = useMediaQueryPortraitMobile();

  const chart = (
    <VisualChartWrapper
      type="bar"
      dataLabel="cashFromFinancingActivities"
      pageLabel="cashflow"
      titleLabel={CASH_FLOW_TITLE_LABELS.cashFromFinancingActivities}
      chartOptions={BAR_CHART_PLUGINS}
      useDollarsCurrency
      useFiscalYear
      isTime
      isBar
    />
  );

  if (isPortraitMobile) {
    return (
      <Box
        sx={{
          height: '25rem !important',
          marginBottom: '2rem',
        }}
      >
        {chart}
      </Box>
    );
  } else {
    return chart;
  }
};

export default CashFromFinancingActivitiesComponent;
