import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { COMPANY_STOCKS_TITLE_LABELS } from '../../utils/visuals.config';

const SharesComponent = () => {
  return (
    <VisualChartWrapper
      type="line"
      dataLabel="shares"
      pageLabel="stock"
      titleLabel={COMPANY_STOCKS_TITLE_LABELS.shares}
      useDollarsCurrency
      isTime
      useFiscalYear
      hideLegend
    />
  );
};

export default SharesComponent;
