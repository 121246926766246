import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { useMediaQueryPortraitMobile } from '../../../../hooks/responsive-design.hook';
import { COMMON_COLORS } from '../../../../ui/colors';
import { EQUITY_TITLE_LABELS } from '../../utils/visuals.config';

const RetainedEarningsComponent = () => {
  const isPortraitMobile = useMediaQueryPortraitMobile();

  return (
    <VisualChartWrapper
      type="line"
      dataLabel="retainedEarnings"
      pageLabel="balanceSheet"
      titleLabel={EQUITY_TITLE_LABELS.retainedEarnings}
      useDollarsCurrency
      useFiscalYear
      isTime
      customLineColor={COMMON_COLORS.equityCharts}
      hideLegend
      smallChart={!isPortraitMobile}
      mobileHeight={12}
    />
  );
};

export default RetainedEarningsComponent;
