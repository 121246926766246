import { Drawer, DrawerProps, Stack, SxProps } from '@mui/material';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MOBILE_APP_BAR_HEIGHT_REM } from '../../constants/general.constants';
import { setOpenMobileMenu } from '../../store/actions/structure.action';
import { RootState } from '../../store/reducers/root.reducer';

type Props = {
  children: React.ReactNode;
  drawerProps?: DrawerProps;
  sx?: SxProps;
};

const MobileDrawer = ({ children, drawerProps, sx }: Props) => {
  const dispatch = useDispatch();
  const openMobileMenu = useSelector(
    (state: RootState) => state.structure.openMobileMenu
  );

  return (
    <Drawer
      variant="temporary"
      open={openMobileMenu}
      onClose={() => {
        dispatch(setOpenMobileMenu(false));
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          width: '100%',
          marginTop: `${MOBILE_APP_BAR_HEIGHT_REM}rem !important`,
          backgroundColor: (theme) => theme.palette.background.default,
          backgroundImage: 'none',
          overflowX: 'hidden !important',
          paddingBottom: '4rem',
          ...(sx as any),
        },
      }}
      {...drawerProps}
    >
      {children}
      <Stack
        sx={{
          fontSize: '0.75rem',
          margin: '0.5rem',
          marginTop: 'auto',
          marginRight: '1rem',
          minHeight: '4rem',
        }}
        direction="row"
        justifyContent="flex-end"
        alignItems="end"
      >{`v${process.env.REACT_APP_PROMETEUS_VERSION}`}</Stack>
    </Drawer>
  );
};

export default MobileDrawer;
