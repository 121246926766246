import { Close } from '@mui/icons-material';
import FilterList from '@mui/icons-material/FilterList';
import { Palette, useTheme } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import {
  companiesSearchSelectCountries,
  companiesSearchSelectIndustries,
  companiesSearchSelectSectors,
} from '../../../store/actions/companies.action';
import {
  peerGroupSelectCountries,
  peerGroupSelectIndustries,
  peerGroupSelectSectors,
} from '../../../store/actions/peer-group.action';
import { RootState } from '../../../store/reducers/root.reducer';
import ClickAwayComponent from '../../click-away.component';
import SelectIconComponent from '../../select-icon.component';
import TooltipIconButton from '../../tooltip-icon-button.component';
import CountrySelectorComponent from './country-selector.component';
import FilterTitleComponent from './filter-title.component';
import SectorIndustrySelectorComponent from './sector-industry-selector.component';

type Props = {
  open: boolean;
  setOpen: (value: boolean) => void;
  hasFiltersType?: 'searchBar' | 'peerGroup' | 'modal';
};

const getStyles = (palette: Palette) => ({
  container: {
    padding: '1rem',
    paddingTop: '0rem',
    backgroundColor: `${palette.background.paper} !important`,
  },
  icon: {
    marginRight: '-0.8rem',
  },
  closeIcon: {
    //  color: colors.icons,
    transform: 'scale(1)',
  },
});

const SearchCntSecIndFilterComponent = (props: Props) => {
  const theme = useTheme();
  const isPortraitMobile = useMediaQueryPortraitMobile();
  const styles = getStyles(theme.palette);

  const { hasFiltersType } = props;
  const sectors = useSelector(
    (state: RootState) =>
      (hasFiltersType === 'searchBar' ? state.companies : state.peerGroup)
        .sectors
  );
  const selectedIndustries = useSelector(
    (state: RootState) =>
      (hasFiltersType === 'searchBar' ? state.companies : state.peerGroup)
        .selectedIndustries
  );
  const selectedCountries = useSelector(
    (state: RootState) =>
      (hasFiltersType === 'searchBar' ? state.companies : state.peerGroup)
        .selectedCountries
  );

  const dispatch = useDispatch();
  const countryIdsAction = useCallback(
    (optionIndex: number) => {
      dispatch(
        (hasFiltersType === 'searchBar'
          ? companiesSearchSelectCountries
          : peerGroupSelectCountries)(optionIndex)
      );
    },
    [dispatch, hasFiltersType]
  );
  return (
    <SelectIconComponent
      icon={<FilterList />}
      tooltip="Filter Companies"
      open={props.open}
      setOpen={props.setOpen}
      sx={styles.container}
      iconStyles={{
        ...styles.icon,
        ...(isPortraitMobile
          ? {
              '& .MuiIconButton-root': {
                padding: '0.3rem',
                marginRight: '0.2rem',
                '& svg': {
                  fontSize: '1.2rem !important',
                },
              },
            }
          : {}),
      }}
    >
      <ClickAwayComponent setOpen={props.setOpen}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <FilterTitleComponent title="Filters" />
          <TooltipIconButton
            icon={<Close />}
            tooltip="Close"
            onClick={() => {
              props.setOpen(false);
            }}
            iconButtonStyle={styles.closeIcon}
          />
        </div>
        <CountrySelectorComponent
          label="Countries"
          placeholder="Select countries"
          dataList={selectedCountries}
          onClickCheckHandler={countryIdsAction}
          onCloseHandler={() => {}}
        />
        <SectorIndustrySelectorComponent
          label="Sectors & Industries"
          placeholder="Select sectors & industries"
          dataList={sectors}
          sectorAction={
            hasFiltersType === 'searchBar'
              ? companiesSearchSelectSectors
              : peerGroupSelectSectors
          }
          industryAction={
            hasFiltersType === 'searchBar'
              ? companiesSearchSelectIndustries
              : peerGroupSelectIndustries
          }
          selectedIndustries={selectedIndustries?.length || 0}
          onCloseHandler={() => {}}
        />
      </ClickAwayComponent>
    </SelectIconComponent>
  );
};

export default SearchCntSecIndFilterComponent;
