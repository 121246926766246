import {
  Book,
  EmojiEmotions,
  Euro,
  Home,
  ManageAccounts,
  PeopleAlt,
  Storage,
} from '@mui/icons-material';
import Drawer, { drawerClasses } from '@mui/material/Drawer';
import List from '@mui/material/List';
import { Theme } from '@mui/material/styles';
import React from 'react';
import {
  ADMIN_DRAWER_WIDTH_REM,
  APP_BAR_HEIGHT_REM,
  MOBILE_APP_BAR_HEIGHT_REM,
} from '../constants/general.constants';
import { useMediaQueryPortraitMobile } from '../hooks/responsive-design.hook';
import AdminNavigationMenuItemComponent from './admin-navigation-menu-item.component';
import { AdminRouteEnum } from './routes.constants';

const PAGES: { displayName: string; path: string; icon: React.ReactElement }[] =
  [
    {
      displayName: 'Home',
      path: AdminRouteEnum.HOME,
      icon: <Home />,
    },
    {
      displayName: 'Users',
      path: AdminRouteEnum.USERS,
      icon: <PeopleAlt />,
    },
    {
      displayName: 'Icons',
      path: AdminRouteEnum.ICONS,
      icon: <EmojiEmotions />,
    },
    {
      displayName: 'Managers',
      path: AdminRouteEnum.MANAGERS,
      icon: <ManageAccounts />,
    },
    {
      displayName: 'Company',
      path: AdminRouteEnum.COMPANY,
      icon: <Storage />,
    },
    {
      displayName: 'Logs',
      path: AdminRouteEnum.LOGS,
      icon: <Book />,
    },
    {
      displayName: 'Plans',
      path: AdminRouteEnum.PLANS,
      icon: <Euro />,
    },
  ];

const styles = {
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: (theme: Theme) => theme.zIndex.drawer + 1,
  },
  drawer: {
    width: `${ADMIN_DRAWER_WIDTH_REM}rem  `,
    flexShrink: 0,
  },
  drawerPaper: {
    width: `${ADMIN_DRAWER_WIDTH_REM}rem  `,
    backgroundColor: 'background.default',
    color: 'text.primary',
  },
  listItem: {
    fontSize: '1.2rem',
    '&:hover': {
      backgroundColor: 'background.paper',
      color: 'primary.main',
    },
    '&:hover svg': {
      color: 'primary.main',
    },
  },
  listItemColor: {
    color: 'text.primary',
  },
  selectedListItemColor: {
    color: 'primary.main',
  },
};

type Props = {
  currentPath: string;
};

const AdminNavigationMenuComponent = (props: Props) => {
  const isPortraitMobile = useMediaQueryPortraitMobile();

  return (
    <div
      style={{
        ...styles.root,
      }}
    >
      <Drawer
        sx={{
          ...styles.drawerPaper,
          [`& .${drawerClasses['paper']}`]: {
            ...styles.drawerPaper,
          },
        }}
        variant="permanent"
      >
        {/* <Toolbar /> */}
        <div
          style={{
            overflow: 'auto',
            marginTop: `${
              isPortraitMobile ? MOBILE_APP_BAR_HEIGHT_REM : APP_BAR_HEIGHT_REM
            }rem`,
          }}
        >
          <List>
            {PAGES.map((e) => {
              const strCurrentPath = `/${props.currentPath}`;
              const current =
                props.currentPath.split('/').length > 2 &&
                e.path.split('/').length > 2
                  ? strCurrentPath.startsWith(e.path)
                  : strCurrentPath === e.path;
              return (
                <AdminNavigationMenuItemComponent
                  displayName={e.displayName}
                  key={e.displayName}
                  path={e.path}
                  current={current}
                  icon={e.icon}
                />
              );
            })}
          </List>
        </div>
      </Drawer>
    </div>
  );
};

export default AdminNavigationMenuComponent;
