import { ArrowRight } from '@mui/icons-material';
import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  SvgIcon,
} from '@mui/material';
import { IStructureMacrosection, IStructureSection } from '@prometeus/common';
import { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import MobileDrawer from '../components/basic-blocks/mobile-drawer.component';
import BrandFlame from '../components/brand-flame.component';
import EnhancedTooltip from '../components/enhanced-tooltip.component';
import HeaderLogoComponent from '../components/header-logo.component';
import {
  LogInButtonComponent,
  SignOutButtonComponent,
  SignUpButtonComponent,
} from '../components/sign-in-dialog.component';
import TooltipIconButton from '../components/tooltip-icon-button.component';
import { PREMIUM_FEATURE_TOOLTIP } from '../constants/general.constants';
import { UserRouteEnum } from '../pages/user/user.page';
import { openActiveFreeTrialDialog } from '../store/actions/modals.action';
import { setOpenMobileMenu } from '../store/actions/structure.action';
import { RootState } from '../store/reducers/root.reducer';
import { useSelectStyles } from '../styles/select.styles';
import { COMMON_COLORS } from '../ui/colors';
import { PREMIUM_ROUTES, RouteEnum } from './routes.constants';

const SETTINGS_MENU_ELEMENT: IStructureMacrosection = {
  id: 'Ss',
  displayName: 'Settings',
  icon: 'M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z',
  path: RouteEnum.USER,
  topDivider: true,
  sections: [
    {
      id: '1',
      displayName: 'User Information',
      path: UserRouteEnum.USER_INFORMATION,
      filters: [],
    },
    {
      id: '2',
      displayName: 'Licences',
      path: UserRouteEnum.LICENCES,
      filters: [],
    },
    {
      id: '3',
      displayName: 'Preferences',
      path: UserRouteEnum.PREFERENCES,
      filters: [],
    },
  ],
};

const MobileMenuComponent = () => {
  const dispatch = useDispatch();
  const selectStyles = useSelectStyles();

  const hasActiveLicence = useSelector(
    (state: RootState) => state.authentication.hasActiveLicence
  );

  const toggleExpandedNavElement = (index: number) => {
    setExpandedNavElements((elements: boolean[]) =>
      elements.map((e: boolean, i: number) => (index === i ? !e : e))
    );
  };

  /* Sign In block */
  const isAdmin = useSelector(
    (state: RootState) => state.authentication.isAdmin
  );
  const userInformation = useSelector(
    (state: RootState) => state.user.userInformation,
    shallowEqual
  );
  const { pathname } = useLocation();
  const currentPath = pathname.split('/')[1];
  const isPaymentRoute = currentPath === RouteEnum.PAYMENT.slice(1);

  const isLoggedUserCondition = !!userInformation || isAdmin;
  const structure = [
    ...useSelector((state: RootState) => state.structure.structure || []),
    ...(isLoggedUserCondition ? [SETTINGS_MENU_ELEMENT] : []),
  ];
  const [expandedNavElements, setExpandedNavElements] = useState<boolean[]>(
    structure?.map((_) => false)
  );

  return (
    <MobileDrawer>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
        sx={{ marginTop: '1rem' }}
      >
        <HeaderLogoComponent />
      </Stack>
      <List disablePadding sx={{ padding: '1rem 1rem' }}>
        {structure.map((e: IStructureMacrosection, index: number) => (
          <div key={index}>
            {!!e.topDivider && (
              <Divider
                sx={{ backgroundColor: (theme) => theme.palette.divider }}
              />
            )}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{
                borderRadius: '0.5rem',
                ...(`/${currentPath}` === e.path
                  ? { backgroundColor: COMMON_COLORS.primary }
                  : {}),
              }}
            >
              <Link
                to={e.path}
                style={{
                  all: 'unset',
                  flex: '1 1 0',
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => dispatch(setOpenMobileMenu(false))}
              >
                <ListItemButton
                  sx={{
                    textAlign: 'left',
                  }}
                >
                  <Box
                    sx={{
                      marginRight: '2rem',
                      marginTop: '0.5rem',
                    }}
                  >
                    <SvgIcon>
                      <path fill="currentColor" d={e.icon} />
                    </SvgIcon>
                  </Box>
                  <ListItemText
                    sx={{
                      '& span': {
                        fontSize: '1.2rem',
                      },
                    }}
                    primary={e.displayName}
                  />
                </ListItemButton>
              </Link>
              {e.sections.length > 1 && (
                <TooltipIconButton
                  tooltip="Expand menu"
                  icon={
                    <ArrowRight
                      sx={{
                        transform: `scale(1.5) rotate(${
                          expandedNavElements[index] ? -90 : 90
                        }deg)`,
                      }}
                    />
                  }
                  onClick={() => {
                    toggleExpandedNavElement(index);
                  }}
                />
              )}
            </Stack>
            {e.sections.length > 1 && expandedNavElements[index] && (
              <List disablePadding>
                {e.sections.map((section: IStructureSection) => {
                  const isDisabled =
                    !hasActiveLicence &&
                    !!e.path &&
                    PREMIUM_ROUTES.get(e.path as RouteEnum)?.includes(
                      section.path as string
                    );

                  const innerLinkSection = (
                    <ListItemButton
                      key={e.path}
                      sx={{
                        textAlign: 'left',
                        marginLeft: '3.2rem',
                        paddingTop: '0 !important',
                        paddingBottom: '0 !important',
                        ...(isDisabled ? selectStyles.disabledMenuItem : {}),
                      }}
                    >
                      <ListItemText
                        sx={{
                          '& span': {
                            fontSize: '1rem',
                          },
                        }}
                        primary={
                          <Stack direction="row" alignItems="center">
                            {section.displayName}
                            {isDisabled && (
                              <BrandFlame
                                sx={{
                                  marginLeft: '0.5rem',
                                  transform: 'scale(0.9)',
                                }}
                              />
                            )}
                          </Stack>
                        }
                      />
                    </ListItemButton>
                  );

                  return (
                    <>
                      {isDisabled ? (
                        <EnhancedTooltip
                          key={e.path}
                          title={PREMIUM_FEATURE_TOOLTIP}
                          onInternalClick={() => {
                            dispatch(openActiveFreeTrialDialog());
                          }}
                        >
                          {innerLinkSection}
                        </EnhancedTooltip>
                      ) : (
                        <Link
                          key={e.path}
                          to={`${e.path}/${section.path}`}
                          style={{
                            all: 'unset',
                            flex: '1 1 0',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                          onClick={() => {
                            console.log(`${e.path}/${section.path}`);
                            dispatch(setOpenMobileMenu(false));
                          }}
                        >
                          {innerLinkSection}
                        </Link>
                      )}
                    </>
                  );
                })}
              </List>
            )}
          </div>
        ))}
      </List>

      {/* Login buttons */}
      {!userInformation && !isAdmin && !isPaymentRoute && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          spacing={3}
          sx={{ marginTop: '1.5rem' }}
        >
          <LogInButtonComponent
            sx={{
              fontSize: '0.9rem',
              paddingRight: '1.5rem',
              paddingLeft: '1.5rem',
              backgroundColor: 'transparent',
            }}
          />
          <SignUpButtonComponent
            sx={{
              fontSize: '0.9rem',
              paddingRight: '1.5rem',
              paddingLeft: '1.5rem',
              backgroundColor: COMMON_COLORS.primary,
              color: COMMON_COLORS.contrastPrimaryText,
            }}
          />
        </Stack>
      )}

      {/* Sign out button */}
      {isLoggedUserCondition && (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ marginTop: '1.5rem' }}
        >
          <SignOutButtonComponent
            sx={{
              fontSize: '0.9rem',
              paddingRight: '1.5rem',
              paddingLeft: '1.5rem',
              backgroundColor: 'transparent',
            }}
          />
        </Stack>
      )}
    </MobileDrawer>
  );
};

export default MobileMenuComponent;
