import {
  Box,
  Palette,
  Stack,
  SvgIcon,
  Typography,
  useTheme,
} from '@mui/material';
import { useMediaQueryPortraitMobile } from '../hooks/responsive-design.hook';
import AnimatedLogo from './animated-logo';

const getStyles = (palette: Palette, isPortraitMobile: boolean) => ({
  container: {
    backgroundColor: palette.background.paper,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  mockText: {
    color: palette.text.primary,
    fontSize: isPortraitMobile ? '3rem' : '4rem',
    letterSpacing: '0.2rem',
    paddingTop: '1.5rem',
  },
  version: {
    color: palette.text.primary,
    paddingTop: '4rem',
  },
  logo: {
    width: '20rem',
    height: '20rem',
  },
});

const SplashScreenComponent = () => {
  const theme = useTheme();
  const isPortraitMobile = useMediaQueryPortraitMobile();
  const styles = getStyles(theme.palette, isPortraitMobile);

  return (
    <Box sx={styles.container}>
      <SvgIcon sx={styles.logo}>
        <AnimatedLogo />
      </SvgIcon>
      <Stack
        sx={{ marginBottom: '2rem' }}
        direction="row"
        alignItems="flex-end"
      >
        <Typography
          variant={isPortraitMobile ? 'h5' : 'h4'}
          noWrap
          sx={{
            ...styles.mockText,
          }}
        >
          PROMETEUS
        </Typography>
        <Typography variant="subtitle1">BETA</Typography>
      </Stack>
    </Box>
  );
};

export default SplashScreenComponent;
