import { COMPANY_LABEL_PLACEHOLDER } from '../../../constants/charts/chart.constants';
import {
  ChartTypeEnum,
  IVisualAggregatedConfig,
  IVisualConfig,
} from '../../../models/visuals.model';
import {
  ROA_DISTRIBUTION,
  ROA_QUARTERLY,
  ROA_YEARLY,
  ROE_DISTRIBUTION,
  ROE_QUARTERLY,
  ROE_YEARLY,
  ROIC_DISTRIBUTION,
  ROIC_QUARTERLY,
  ROIC_YEARLY,
  ROS_DISTRIBUTION,
  ROS_QUARTERLY,
  ROS_YEARLY,
} from './visuals.model';

/***************************************
 *               ROS & ROE             *
 ***************************************/
const ROS_AND_ROE_YEARLY_CONFIG: IVisualConfig[] = [
  {
    id: 'rosYearly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'ROS',
    dataAction: ROS_YEARLY,
    stateLabel: 'rosData',
    isPercentage: true,
    columns: ['Fiscal Year', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
  {
    id: 'roeYearly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'ROE adj',
    dataAction: ROE_YEARLY,
    stateLabel: 'roeData',
    isPercentage: true,
    columns: ['Fiscal Year', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
];

const ROS_AND_ROE_QUARTERLY_CONFIG: IVisualConfig[] = [
  {
    id: 'rosQuarterly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'ROS',
    dataAction: ROS_QUARTERLY,
    stateLabel: 'rosData',
    isPercentage: true,
    columns: ['Date', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
  {
    id: 'roeQuarterly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'ROE adj',
    dataAction: ROE_QUARTERLY,
    stateLabel: 'roeData',
    isPercentage: true,
    columns: ['Date', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
];

export const ROS_AND_ROE_CONFIG: IVisualConfig[] = [
  {
    id: 'rosDistribution',
    type: ChartTypeEnum.DISTR_CHART,
    item: 'ROS',
    dataAction: ROS_DISTRIBUTION,
    stateLabel: 'rosDistributionData',
    isPercentage: true,
    isDistr: true,
    isXPercentage: true,
    columns: ['Value', 'Peer Distribution', 'Company Average', 'Peer Average'],
    datasetLabels: [
      { label: 'Peers Distribution' },
      { label: `${COMPANY_LABEL_PLACEHOLDER} Average` },
      { label: 'Peers Average' },
    ],
  },
  {
    id: 'roeDistribution',
    type: ChartTypeEnum.DISTR_CHART,
    item: 'ROE adj',
    dataAction: ROE_DISTRIBUTION,
    stateLabel: 'roeDistributionData',
    isPercentage: true,
    isDistr: true,
    isXPercentage: true,
    columns: ['Value', 'Peer Distribution', 'Company Average', 'Peer Average'],
    datasetLabels: [
      { label: 'Peers Distribution' },
      { label: `${COMPANY_LABEL_PLACEHOLDER} Average` },
      { label: 'Peers Average' },
    ],
  },
];

export const ROS_AND_ROE_CONFIG_AGGREGATED: IVisualAggregatedConfig = {
  quarterly: ROS_AND_ROE_QUARTERLY_CONFIG,
  yearly: ROS_AND_ROE_YEARLY_CONFIG,
};

/***************************************
 *              ROA & ROIC             *
 ***************************************/
const ROA_AND_ROIC_YEARLY_CONFIG: IVisualConfig[] = [
  {
    id: 'roaYearly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'ROA adj',
    dataAction: ROA_YEARLY,
    stateLabel: 'roaData',
    isPercentage: true,
    columns: ['Fiscal Year', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
  {
    id: 'roicYearly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'ROIC',
    dataAction: ROIC_YEARLY,
    stateLabel: 'roicData',
    isPercentage: true,
    columns: ['Fiscal Year', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
];

const ROA_AND_ROIC_QUARTERLY_CONFIG: IVisualConfig[] = [
  {
    id: 'roaQuarterly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'ROA adj',
    dataAction: ROA_QUARTERLY,
    stateLabel: 'roaData',
    isPercentage: true,
    columns: ['Date', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
  {
    id: 'roicQuarterly',
    type: ChartTypeEnum.TIME_CHART,
    item: 'ROIC',
    dataAction: ROIC_QUARTERLY,
    stateLabel: 'roicData',
    isPercentage: true,
    columns: ['Date', 'Company', 'Peers'],
    datasetLabels: [{ label: COMPANY_LABEL_PLACEHOLDER }, { label: 'Peers' }],
  },
];

export const ROA_AND_ROIC_CONFIG: IVisualConfig[] = [
  {
    id: 'roaDistribution',
    type: ChartTypeEnum.DISTR_CHART,
    item: 'ROA adj',
    dataAction: ROA_DISTRIBUTION,
    stateLabel: 'roaDistributionData',
    isPercentage: true,
    isDistr: true,
    isXPercentage: true,
    columns: ['Value', 'Peer Distribution', 'Company Average', 'Peer Average'],
    datasetLabels: [
      { label: 'Peers Distribution' },
      { label: `${COMPANY_LABEL_PLACEHOLDER} Average` },
      { label: 'Peers Average' },
    ],
  },
  {
    id: 'roicDistribution',
    type: ChartTypeEnum.DISTR_CHART,
    item: 'ROIC',
    dataAction: ROIC_DISTRIBUTION,
    stateLabel: 'roicDistributionData',
    isPercentage: true,
    isDistr: true,
    isXPercentage: true,
    columns: ['Value', 'Peer Distribution', 'Company Average', 'Peer Average'],
    datasetLabels: [
      { label: 'Peers Distribution' },
      { label: `${COMPANY_LABEL_PLACEHOLDER} Average` },
      { label: 'Peers Average' },
    ],
  },
];

export const ROA_AND_ROIC_CONFIG_AGGREGATED: IVisualAggregatedConfig = {
  quarterly: ROA_AND_ROIC_QUARTERLY_CONFIG,
  yearly: ROA_AND_ROIC_YEARLY_CONFIG,
};

/***************************************
 *             Title Labels            *
 ***************************************/
export const ROA_ROIC_TITLE_LABELS = {
  roa: 'ROA',
  roic: 'ROIC',
  roaDistribution: 'ROA Distribution',
  roicDistribution: 'ROIC Distribution',
};

export const ROS_ROE_TITLE_LABELS = {
  ros: 'ROS',
  roe: 'ROE',
  rosDistribution: 'ROS Distribution',
  roeDistribution: 'ROE Distribution',
};
