import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingComponent from '../../../components/loading.component';
import ResponsiveRow from '../../../components/responsive-row.component';
import ScrollFillerComponent from '../../../components/scroll-filler.component';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { PageIdEnum } from '../../../models/filters.model';
import { setCurrentPage } from '../../../store/actions/filters.action';
import { RootState } from '../../../store/reducers/root.reducer';
import EpsComponent from '../components/company-stocks/eps.component';
import MarketCapRankComponent from '../components/company-stocks/market-cap-rank.component';
import MarketCapComponent from '../components/company-stocks/market-cap.component';
import PeRatioComponent from '../components/company-stocks/pe-ratio.component';
import PriceComponent from '../components/company-stocks/price.component';
import SharesComponent from '../components/company-stocks/shares.component';

const CompanyStocksTab = () => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  /* Charts Data */
  const priceDataUpdating = useSelector(
    (state: RootState) => state.visuals.stock.priceDataUpdating
  );
  const epsDataUpdating = useSelector(
    (state: RootState) => state.visuals.stock.epsDataUpdating
  );
  const sharesDataUpdating = useSelector(
    (state: RootState) => state.visuals.stock.sharesDataUpdating
  );
  const peRatioDataUpdating = useSelector(
    (state: RootState) => state.visuals.stock.peRatioDataUpdating
  );
  const marketCapRankDataUpdating = useSelector(
    (state: RootState) => state.visuals.stock.marketCapRankDataUpdating
  );
  const marketCapDataUpdating = useSelector(
    (state: RootState) => state.visuals.stock.marketCapDataUpdating
  );

  /* Change Report Page */
  useEffect(() => {
    dispatch(setCurrentPage(PageIdEnum.COMPANY_STOCKS));
  }, [dispatch]);

  return (
    <>
      {priceDataUpdating &&
        sharesDataUpdating &&
        epsDataUpdating &&
        peRatioDataUpdating &&
        marketCapDataUpdating &&
        marketCapRankDataUpdating && <LoadingComponent transparent={true} />}
      <ResponsiveRow>
        <PriceComponent />
        <SharesComponent />
      </ResponsiveRow>
      <ResponsiveRow>
        <EpsComponent />
        <PeRatioComponent />
      </ResponsiveRow>
      <ResponsiveRow noMarginBottom paddingBottom={8}>
        <MarketCapComponent />
        <MarketCapRankComponent />
      </ResponsiveRow>
      {!isPortraitMobile && <ScrollFillerComponent fill={4} />}
    </>
  );
};

export default CompanyStocksTab;
