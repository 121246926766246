import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { CASH_FLOW_TITLE_LABELS } from '../../utils/visuals.config';

const CashComponent = () => {
  return (
    <VisualChartWrapper
      type="line"
      dataLabel="cash"
      pageLabel="cashflow"
      titleLabel={CASH_FLOW_TITLE_LABELS.cash}
      useDollarsCurrency
      useFiscalYear
      isTime
      hideLegend
    />
  );
};

export default CashComponent;
