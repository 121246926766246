import { Box, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQueryPortraitMobile } from '../../hooks/responsive-design.hook';
import { setOpenMobileFilterDialog } from '../../store/actions/structure.action';
import { RootState } from '../../store/reducers/root.reducer';
import { COMMON_COLORS } from '../../ui/colors';
import CustomDialogComponent from '../dialog.component';
import FiltersFabComponent from './filters-fab.component';

type Props = {
  filtersComponent: JSX.Element;
  showSplashScreen: boolean;
  path: string;
};

const PageSkeletonFilters = ({
  filtersComponent,
  showSplashScreen,
  path,
}: Props) => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  /* Side filters mobile */
  const openMobileFilterDialog = useSelector(
    (state: RootState) => state.structure.openMobileFilterDialog
  );

  const setOpenFiltersDialog = (open: boolean) => {
    dispatch(setOpenMobileFilterDialog(open));
  };

  return (
    <>
      {isPortraitMobile ? (
        <>
          {!showSplashScreen && <FiltersFabComponent />}
          <CustomDialogComponent
            maxWidth="lg"
            dialogTitle={
              <Typography
                variant="h5"
                sx={{
                  fontSize: '1.8rem',
                  marginTop: '1rem',
                  marginBottom: '1rem',
                  textTransform: 'uppercase',
                  color: COMMON_COLORS.lightBlue,
                }}
              >
                Apply Filters
              </Typography>
            }
            dialogContent={filtersComponent}
            open={!!openMobileFilterDialog}
            onClose={() => setOpenFiltersDialog(false)}
            paperSx={{
              height: '100%',
              padding: '0.5rem',
              borderRadius: '2rem',
              width: 'calc(100% - 2rem) !important',
              margin: 0,
            }}
          />
        </>
      ) : (
        <Box
          sx={{
            flex: '1 1 0',
            position: 'sticky' as 'sticky',
            top: 0,
            height: '99.4%',
          }}
        >
          {filtersComponent}
        </Box>
      )}
    </>
  );
};

export default PageSkeletonFilters;
