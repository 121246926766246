import {
  SET_DISPLAY_NAME,
  SET_OPEN_MOBILE_FILTER_DIALOG,
  SET_OPEN_MOBILE_MENU,
  SET_SCREEN_SIZE,
  SET_STRUCTURE_ERROR,
} from '../../models/structure.model';

export const setDisplayName = (displayName: string) => ({
  type: SET_DISPLAY_NAME,
  payload: displayName,
});

export const setStructureError = (error: string | undefined) => ({
  type: SET_STRUCTURE_ERROR,
  payload: error,
});

export const setScreenSize = (screenSize: [number, number]) => ({
  type: SET_SCREEN_SIZE,
  payload: screenSize,
});

export const setOpenMobileMenu = (open: boolean) => ({
  type: SET_OPEN_MOBILE_MENU,
  payload: open,
});

export const setOpenMobileFilterDialog = (open: boolean) => ({
  type: SET_OPEN_MOBILE_FILTER_DIALOG,
  payload: open,
});