import { numberFormatter } from '@prometeus/common';
import {
  Chart,
  ChartDataset,
  ChartMeta,
  ChartOptions,
  Color,
  LegendItem,
  TimeUnit,
  TooltipItem,
  TooltipLabelStyle
} from 'chart.js';
import { ICrosshairPluginOptions } from 'react-chartjs3-wrapper/types';
import { ChartTextSizeEnum } from '../../models/structure.model';
import { IRadarRawData, ITooltipSymbol } from '../../models/visuals.model';
import { COMMON_COLORS } from '../../ui/colors';
import { DEFAULT_WIDTH } from '../general.constants';
import { MIN_TICK_ROTATION, getSizeRem, getTextSize } from './utils.constants';

const TICK_FONT_INCREMENT_PORTRAIT_MOBILE = 6.5;
const LEGEND_FONT_INCREMENT_PORTRAIT_MOBILE = 5;

/***************************************
 *                Objects              *
 ***************************************/
export const CROSSHAIR_OPTIONS: ICrosshairPluginOptions = {
  dashed: true,
  dashedSegments: [6.5],
  strokeStyle: COMMON_COLORS.chartLabel,
  lineWidth: 1,
};

export const BAR_CHART_PLUGINS: ChartOptions = {
  plugins: {
    legend: {
      labels: {
        generateLabels: (chart: Chart<'bar'>): LegendItem[] => {
          const datasets: ChartDataset<'bar', number[]>[] = chart.data.datasets;
          return datasets?.map(
            (dataset: ChartDataset<'bar', number[]>, index: number) => {
              const meta: ChartMeta = chart.getDatasetMeta(index);
              const color: string = dataset.backgroundColor
                ? (Array.isArray(dataset.backgroundColor)
                  ? dataset.backgroundColor[0]
                  : dataset.backgroundColor
                )?.substring(0, 7)
                : 'transparent';
              return {
                hidden: !meta.visible,
                datasetIndex: index, // datasets[meta.index].label
                text: dataset.label || '',
                strokeStyle: color,
                fillStyle: color,
              };
            }
          );
        },
        usePointStyle: true,
      },
    },
    tooltip: {
      callbacks: {
        // Remove opacity
        labelColor: (tooltipItem: TooltipItem<any>): TooltipLabelStyle => {
          const dataset = tooltipItem.dataset;
          const dataIndex = tooltipItem.dataIndex;

          const borderColor: string = Array.isArray(dataset.borderColor)
            ? ((dataset.borderColor as string[])[dataIndex] as string)
            : (dataset.borderColor as string);

          const backgroundColor: string = Array.isArray(dataset.backgroundColor)
            ? ((dataset.backgroundColor as string[])[dataIndex] as string)
            : (dataset.backgroundColor as string);

          return {
            borderColor: borderColor?.substring(0, 7) as Color,
            backgroundColor: backgroundColor?.substring(0, 7) as Color,
          };
        },
      },
      usePointStyle: true,
    },
  },
};

/***************************************
 *                 Radar               *
 ***************************************/
export const RADAR_CHART_OPTIONS = (
  screenWidth: number,
  overviewData?: IRadarRawData,
  isPortraitMobile?: boolean
): ChartOptions => ({
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
    },
    r: {
      // beginAtZero: true,
      min: 0,
      max: 100,
      grid: {
        color: COMMON_COLORS.chartGrid,
      },
      ticks: {
        showLabelBackdrop: false,
        color: COMMON_COLORS.chartLabel,
        font: {
          size: getSizeRem(
            getTextSize(ChartTextSizeEnum.MEDIUM) + (isPortraitMobile ? 20 : 2),
            screenWidth
          ),
        },
        textStrokeWidth: 20,
        stepSize: 20,
      },
      pointLabels: {
        color: COMMON_COLORS.chartLabel,
        font: {
          size: getSizeRem(
            getTextSize(ChartTextSizeEnum.MEDIUM) + (isPortraitMobile ? 30 : 4),
            screenWidth
          ),
        },
      },
    },
  },
  plugins: {
    underline: {
      yOffset: 0,
      lineWidth: 0.5,
      lineColor: COMMON_COLORS.chartLabel,
    },
    tooltip: {
      enabled: false,
      position: 'nearest',
      displayColors: false,
      intersect: true,
      // mode: 'index',
      external: externalTooltipHandler(!!isPortraitMobile, overviewData),
      callbacks: {
        title: (tooltipItems: TooltipItem<any>[]) => {
          const tooltipItem = tooltipItems[0];
          return `${tooltipItem.label}@@@${(tooltipItem.raw as number).toFixed(
            2
          )}`;
        },
      },
    },
  } as any,
});

const getOrCreateTooltip = (chart: any) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = 'white';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler =
  (isPortraitMobile: boolean, overviewData?: IRadarRawData) =>
    (context: any) => {
      if (!!overviewData) {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }

        const makeFlexRow = (): HTMLDivElement => {
          const row = document.createElement('div');
          row.style.display = 'flex';
          row.style.flexDirection = 'row';
          row.style.justifyContent = 'space-between';
          row.style.borderWidth = '0';
          row.style.gap = '1rem';

          return row;
        };

        const appendRowCell = (row: HTMLDivElement, value: string) => {
          const cell = document.createElement('div');
          cell.style.borderWidth = '0';
          const text = document.createTextNode(value);
          cell.appendChild(text);

          row.appendChild(cell);
        };

        // Set Text
        if (tooltip.body) {
          // Titles
          const titleLines = tooltip.title || [];
          const tableHead = document.createElement('div');
          tableHead.style.fontSize = isPortraitMobile ? '1rem' : '1.3rem';
          tableHead.style.fontWeight = '700';

          titleLines.forEach((title: string) => {
            const row = makeFlexRow();

            title.split('@@@').forEach((e: string) => {
              appendRowCell(row, e);
            });

            tableHead.appendChild(row);
          });

          // Body
          const label = tooltip.title[0].split('@@@')[0];
          const bodyLines = Object.keys((overviewData as any)[label]);
          const tableBody = document.createElement('div');

          if (isPortraitMobile) {
            tableBody.style.fontSize = '0.7rem';
          }

          bodyLines.forEach((bodyLine: string, i: number) => {
            if (bodyLine !== 'score') {
              const row = makeFlexRow();

              [
                bodyLine,
                ((overviewData as any)[label][bodyLine] * 100).toFixed(2),
              ].forEach((e: string) => {
                appendRowCell(row, e);
              });

              tableBody.appendChild(row);
            }
          });

          const tableRoot = tooltipEl.querySelector('table');

          // Remove old children
          while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
          }

          // Add new children
          tableRoot.appendChild(tableHead);
          tableRoot.appendChild(tableBody);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        tooltipEl.style.top = positionY + tooltip.caretY + 'px';
        // tooltipEl.style.font = tooltip.options.bodyFont.string;
        tooltipEl.style.fontFamily =
          "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";
        tooltipEl.style.padding =
          tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
        tooltipEl.style.zIndex = 1000;
      }
    };

/***************************************
 *               Functions             *
 ***************************************/

export const tuneOptions = (config: {
  screenWidth: number;
  options?: ChartOptions;
  showLegend?: boolean;
  xType?: any;
  timeUnit?: TimeUnit;
  tooltipFormat?: string;
  tooltipSymbol?: ITooltipSymbol;
  isRadar?: boolean;
  isPortraitMobile?: boolean;
  isDistr?: boolean;
}): ChartOptions => {
  const {
    screenWidth,
    options,
    showLegend,
    xType,
    timeUnit,
    tooltipFormat,
    tooltipSymbol,
    isRadar,
    isPortraitMobile,
    isDistr,
  } = config;

  // Needed because ChartGeo fucked up the options object
  const OPTIONS_SCALES_Y = {
    ticks: {
      callback: (value: number | string): string =>
        numberFormatter(value) +
        (!!tooltipSymbol && tooltipSymbol.symbol === '%'
          ? ` ${tooltipSymbol?.symbol}`
          : ''),
      color: COMMON_COLORS.chartLabel,
      font: {
        size:
          getSizeRem(
            getTextSize(ChartTextSizeEnum.MEDIUM),
            screenWidth || DEFAULT_WIDTH
          ) + (isPortraitMobile ? TICK_FONT_INCREMENT_PORTRAIT_MOBILE : 0),
      },
    },
    grid: {
      color: COMMON_COLORS.chartGrid,
      display: false,
    },
  };

  const returnOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,

    /* Performance */
    normalized: true,
    hover: {
      mode: 'index',
      intersect: false,
    },
    onHover: (e: any) => {
      // Fix legend pointer cursor
      if (e?.native?.explicitOriginalTarget?.style) {
        e.native.explicitOriginalTarget.style.cursor = 'default';
      }
    },

    ...options,
    line: {
      ...options?.line,
      datasets: {
        spanGaps: true,
        capBezierPoints: false, // to increase performance
        ...options?.line?.datasets,
      },
    },

    /* Display */
    plugins: {
      ...options?.plugins,
      tooltip: {
        enabled: true,
        mode: 'index',
        position: 'average',
        intersect: false,
        titleFont: {
          size: getSizeRem(isPortraitMobile ? 50 : 18, screenWidth),
        },
        bodyFont: {
          weight: '700',
          size: getSizeRem(isPortraitMobile ? 46 : 14, screenWidth),
        },
        caretPadding: 20, // Distance from the point
        usePointStyle: true,

        ...options?.plugins?.tooltip,

        callbacks: {
          label: (tooltipItem: TooltipItem<any>): string | string[] => {
            let formattedValue: string = tooltipItem.formattedValue;
            if (tooltipSymbol) {
              if (tooltipSymbol.left) {
                formattedValue = tooltipSymbol.symbol + ' ' + formattedValue;
              } else {
                formattedValue = formattedValue + ' ' + tooltipSymbol.symbol;
              }
            }
            return (isRadar ? '  ' : ' ') + formattedValue;
          },
          ...options?.plugins?.tooltip?.callbacks,
        },
      },
      legend: {
        display: !!showLegend,
        onHover: (e: any) => {
          if (e?.native?.explicitOriginalTarget?.style) {
            e.native.explicitOriginalTarget.style.cursor = 'pointer';
          }
        },
        ...options?.plugins?.legend,
        labels: {
          usePointStyle: true,
          color: COMMON_COLORS.chartLabel,
          font: {
            size:
              getSizeRem(14, screenWidth) +
              (isPortraitMobile ? LEGEND_FONT_INCREMENT_PORTRAIT_MOBILE : 0),
          },
          ...options?.plugins?.legend?.labels,
        },
      },
    },
    scales: {
      ...options?.scales,
      y: {
        ...OPTIONS_SCALES_Y,
        ...(isDistr
          ? {
            ticks: {
              display: false, // This hides the y-axis labels
            },
          }
          : {}),
        ...options?.scales?.y,
      },
      x: {
        type: !!xType ? xType : 'time',
        time: {
          unit: !!timeUnit ? timeUnit : 'year',
          tooltipFormat: !!tooltipFormat ? tooltipFormat : 'DD MMM YYYY',
        },
        grid: {
          color: COMMON_COLORS.chartGrid,
          display: false,
        },

        ...options?.scales?.x,
        ticks: {
          autoSkip: true,
          autoSkipPadding: getSizeRem(30, screenWidth),
          minRotation: MIN_TICK_ROTATION, // Increase performance
          maxRotation: MIN_TICK_ROTATION, // Increase performance
          color: COMMON_COLORS.chartLabel,
          font: {
            size:
              getSizeRem(
                getTextSize(ChartTextSizeEnum.MEDIUM),
                screenWidth || DEFAULT_WIDTH
              ) + (isPortraitMobile ? TICK_FONT_INCREMENT_PORTRAIT_MOBILE : 0),
          },

          ...(options?.scales?.x as any)?.ticks,
        },
      },
    },
  };

  if (xType === 'time' || !xType) {
    returnOptions.parsing = false;
  }

  return returnOptions;
};

export const getRankChartOptions = (
  rotation: number,
  screenWidth: number
): ChartOptions => ({
  scales: {
    x: {
      ticks: {
        autoSkipPadding: 0, // disabled
        padding: rotation ? 0 : getSizeRem(8, screenWidth),
        minRotation: rotation,
        maxRotation: rotation,
      },
    },
  },
  plugins: {
    ...BAR_CHART_PLUGINS.plugins,
    tooltip: {
      ...BAR_CHART_PLUGINS.plugins?.tooltip,
      callbacks: {
        title: (tooltipItems: TooltipItem<'bar'>[]): string | string[] => {
          let title = '';
          tooltipItems.forEach((item: TooltipItem<'bar'>) => {
            const split = item.label.split(',');
            title = split[1] + ' ' + split[0];
          });
          return title;
        },
        ...BAR_CHART_PLUGINS.plugins?.tooltip?.callbacks,
      },
    },
  },
});
