import { Box } from '@mui/material';
import {
  IScreenerTab,
  ScreenerFilterCardType,
  ScreenerFilterNameEnum,
  ScreenerStockFilterCardEnum,
} from '@prometeus/common';
import { useDispatch, useSelector } from 'react-redux';
import ResponsiveRow from '../../../components/responsive-row.component';
import ScrollFillerComponent from '../../../components/scroll-filler.component';
import { useMediaQueryPortraitMobile } from '../../../hooks/responsive-design.hook';
import { ScreenerTabNameEnum } from '../../../models/screener/structure.model';
import { setFilterValue } from '../../../store/actions/screener/structure.action';
import { RootState } from '../../../store/reducers/root.reducer';
import CommonBlockComponent from '../components/common-block.component';
import DividendFilterComponent from '../components/stock/dividend-filter.component';
import MarketCapFilterComponent from '../components/stock/market-cap-filter.component';
import PriceEarningsRatioFilterComponent from '../components/stock/price-earnings-ratio-filter.component';
import PriceFilterComponent from '../components/stock/price-filter.component';

const StockFiltersTab = () => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  const tabData: IScreenerTab | null = useSelector((state: RootState) =>
    state.screener.structure.screenerStructure
      ? state.screener.structure.screenerStructure.stock
      : null
  );

  const stockSetFilterValue = (
    block: ScreenerFilterCardType,
    filter: ScreenerFilterNameEnum,
    value: any
  ): void => {
    dispatch(setFilterValue(ScreenerTabNameEnum.STOCK, block, filter, value));
  };

  return (
    <>
      <ResponsiveRow
        height={24.5}
        sx={isPortraitMobile ? { marginBottom: '1.5rem' } : {}}
      >
        {isPortraitMobile ? (
          <Box sx={{ height: '24rem !important' }}>
            <PriceEarningsRatioFilterComponent />
          </Box>
        ) : (
          <PriceEarningsRatioFilterComponent />
        )}
        {isPortraitMobile ? (
          <Box sx={{ height: '13rem !important' }}>
            <PriceFilterComponent />
          </Box>
        ) : (
          <PriceFilterComponent />
        )}
      </ResponsiveRow>

      <ResponsiveRow
        height={26}
        sx={isPortraitMobile ? { marginBottom: '1.5rem' } : {}}
      >
        {isPortraitMobile ? (
          <Box sx={{ height: '25rem !important' }}>
            <DividendFilterComponent />
          </Box>
        ) : (
          <DividendFilterComponent />
        )}
        {isPortraitMobile ? (
          <Box sx={{ height: '14rem !important' }}>
            <MarketCapFilterComponent />
          </Box>
        ) : (
          <MarketCapFilterComponent />
        )}
      </ResponsiveRow>

      <ResponsiveRow height={38} mobileHeight={32}>
        <CommonBlockComponent
          tabName={ScreenerTabNameEnum.STOCK}
          cardBlock={ScreenerStockFilterCardEnum.DIVIDEND_YIELD}
          data={tabData?.dividendYield}
          setFilterValueHandler={stockSetFilterValue}
          isStock
        />
        <CommonBlockComponent
          tabName={ScreenerTabNameEnum.STOCK}
          cardBlock={ScreenerStockFilterCardEnum.PAYOUT_RATIO}
          data={tabData?.payoutRatio}
          setFilterValueHandler={stockSetFilterValue}
          isStock
        />
      </ResponsiveRow>
      {!!isPortraitMobile && <ScrollFillerComponent />}
    </>
  );
};

export default StockFiltersTab;
