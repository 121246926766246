import { Box } from '@mui/material';
import {
  ChartData,
  ChartDataset,
  ChartOptions,
  ChartTypeRegistry,
} from 'chart.js';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createChartDatasetsConfig,
  IChartDatasetConfigParams,
} from '../../constants/charts/chart.constants';
import {
  GENERAL_ERROR_MESSAGE,
  GENERAL_NO_DATA_MESSAGE,
} from '../../constants/charts/utils.constants';
import { extractChartMetadata } from '../../constants/charts/visuals.constants';
import { B_PAGES, DOLLAR_ST } from '../../constants/general.constants';
import { useMediaQueryPortraitMobile } from '../../hooks/responsive-design.hook';
import {
  CreateChartDatasetOptionsType,
  IOpenLargeChart,
} from '../../models/visuals.model';
import { openLargeChartDialog } from '../../store/actions/modals.action';
import { RootState } from '../../store/reducers/root.reducer';
import { COMMON_COLORS } from '../../ui/colors';
import VisualChartComponent from './visual-chart.component';

type Props = {
  type: keyof ChartTypeRegistry;
  titleLabel: string;
  pageLabel: string;
  dataLabel: string;
  hideLegend?: boolean;
  chartOptions?: ChartOptions;
  xAxisType?: any;
  smallChart?: boolean;
  useDatasetLabels?: boolean;
  mobileHeight?: number;
  // Options
  useDollarsCurrency?: boolean;
  isTime?: boolean;
  isRadar?: boolean;
  isPie?: boolean;
  isBar?: boolean;
  customLineColor?: string;
  useFiscalYear?: boolean;
  highlightNegatives?: boolean;
};

const VisualChartWrapper = ({
  type,
  pageLabel,
  dataLabel,
  titleLabel,
  hideLegend,
  chartOptions,
  xAxisType,
  smallChart,
  useDatasetLabels,
  mobileHeight,
  //
  useDollarsCurrency,
  isTime,
  isRadar,
  isPie,
  isBar,
  customLineColor,
  useFiscalYear,
  highlightNegatives,
}: Props) => {
  const dispatch = useDispatch();
  const isPortraitMobile = useMediaQueryPortraitMobile();

  const currentPage = useSelector(
    (state: RootState) => state.filters.currentPage
  );

  const [isBPage, setIsBPage] = useState<boolean>(false);
  useEffect(() => {
    const value = !!currentPage && B_PAGES.has(currentPage);
    setIsBPage(value);
  }, [currentPage]);

  /* Metric */
  const [metric, setMetric] = useState<ChartData>();

  /* State */
  const metricData = useSelector(
    (state: RootState) => (state.visuals as any)[pageLabel][`${dataLabel}Data`]
  );
  const metricDataUpdating = useSelector(
    (state: RootState) =>
      (state.visuals as any)[pageLabel][`${dataLabel}DataUpdating`]
  );
  const { isDistr } = metricData || { isDistr: false };
  const { isXPercentage } = metricData || { isXPercentage: false };

  /* Common state */
  const screenWidth = useSelector(
    (state: RootState) => state.structure.screenWidth
  );
  const useDollars = useSelector(
    (state: RootState) => state.filters.useDollars
  );
  const activeQYFilter = useSelector(
    (state: RootState) => state.filters.activeQYFilter
  );
  const fiscalYear = useSelector(
    (state: RootState) => state.filters.fiscalYear
  );
  const currencySt: string = useSelector(
    (state: RootState) => state.companies.currentCompany?.currencySt || ''
  );
  const currentCompanyTickerId = useSelector(
    (state: RootState) => state.companies.currentCompany?.tickerId
  );

  /* Chart data */
  const makeChartDatasetConfigOptions =
    useCallback((): IChartDatasetConfigParams => {
      const options: IChartDatasetConfigParams = {
        isTime: !!isTime,
        isRadar: !!isRadar,
        isPie: !!isPie,
        isBar: !!isBar,
        customLineColor: customLineColor,
        isDistr: !!isDistr,
        isXPercentage: !!isXPercentage,
        highlightNegatives: !!highlightNegatives,
        isPortraitMobile,
      };

      if (useDollarsCurrency) {
        options.useDollars = !!useDollars;
      }
      if (useFiscalYear) {
        options.fiscalYear = fiscalYear;
      }

      return options;
    }, [
      customLineColor,
      fiscalYear,
      highlightNegatives,
      isBar,
      isDistr,
      isXPercentage,
      isPie,
      isPortraitMobile,
      isRadar,
      isTime,
      useDollarsCurrency,
      useFiscalYear,
      useDollars,
    ]);

  const [metricHasData, metricHasLabels, metricError] =
    extractChartMetadata(metricData);
  useEffect(() => {
    const datasets = createChartDatasetsConfig(
      metricData,
      screenWidth,
      makeChartDatasetConfigOptions(),
      currentCompanyTickerId
    );

    if (isPie) {
      setMetric({
        labels: metricData?.datasetLabels?.map(
          (e: CreateChartDatasetOptionsType) => e.label
        ),
        datasets: [
          {
            data: datasets.map(
              (e: ChartDataset) => e.data as unknown as number
            ),
            backgroundColor: datasets.map(
              (_, i: number) =>
                COMMON_COLORS[
                  `chartColor${i + 1}` as keyof typeof COMMON_COLORS
                ]
            ),
            borderColor: datasets.map(
              (_, i: number) =>
                COMMON_COLORS[
                  `chartColor${i + 1}` as keyof typeof COMMON_COLORS
                ]
            ),
            borderWidth: 1,
          },
        ],
      });
    } else if (useDatasetLabels) {
      setMetric({
        labels: metricData?.isXPercentage
          ? metricData?.labels?.map(
              (label: string) => `${(+label * 100).toFixed(1)} %`
            )
          : metricData?.labels,
        datasets,
      });
    } else {
      setMetric({ datasets });
    }
  }, [
    metricData,
    useDollars,
    fiscalYear,
    screenWidth,
    currentCompanyTickerId,
    makeChartDatasetConfigOptions,
    isPie,
    useDatasetLabels,
  ]);

  /* Large chart */
  const onOpenChartDialogClickHandler = (data: IOpenLargeChart) => {
    dispatch(openLargeChartDialog(data));
  };

  const chart = (
    <VisualChartComponent
      type={type}
      data={metric}
      error={
        !!metricError
          ? GENERAL_ERROR_MESSAGE
          : !metricHasLabels
          ? GENERAL_NO_DATA_MESSAGE
          : undefined
      }
      titleLabel={titleLabel}
      hasData={metricHasData}
      exportData={metricData}
      showLegend={!hideLegend}
      xAxisType={xAxisType}
      currencySymbol={
        !!metricData?.isCurrency
          ? useDollars
            ? DOLLAR_ST
            : currencySt
          : undefined
      }
      options={chartOptions}
      isPercentage={!!metricData?.isPercentage}
      isDistr={!!metricData?.isDistr}
      timeTooltipFormat={
        activeQYFilter === 'yearly' && !isBPage ? 'YYYY' : 'DD MMM YYYY'
      }
      updating={!!metricDataUpdating}
      onOpenChartDialogClick={onOpenChartDialogClickHandler}
      smallChart={smallChart}
    />
  );

  if (isPortraitMobile && !!mobileHeight) {
    return (
      <Box
        sx={{
          height: `${mobileHeight}rem !important`,
          marginBottom: '2rem',
        }}
      >
        {chart}
      </Box>
    );
  } else {
    return chart;
  }
};

export default VisualChartWrapper;
