import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { CURRENT_QUICK_RATIO_CAPEX_TITLE_LABELS } from '../../utils/visuals.config';

const QuickRatioComponent = () => {
  return (
    <VisualChartWrapper
      type="line"
      dataLabel="quickRatio"
      pageLabel="financialStrength"
      titleLabel={CURRENT_QUICK_RATIO_CAPEX_TITLE_LABELS.quickRatio}
      isTime
      useFiscalYear
    />
  );
};

export default QuickRatioComponent;
