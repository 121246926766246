import { Card, CardContent, Palette, Stack, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useMediaQueryPortraitMobile } from '../../hooks/responsive-design.hook';
import CloseFiltersDialogComponent from './close-filters-dialog.component';

type Props = {
  children: React.ReactNode;
};

const getStyles = (palette: Palette, isPortraitMobile: boolean) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '98%',
  },
  cardRoot: {
    margin: isPortraitMobile ? 0 : '1rem',
    color: palette.text.primary,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderRadius: 0,
    // overflow: 'auto',
    height: '100%',
  },
  cardContentRoot: {
    padding: '0.5rem',
    paddingTop: 0,
    height: '100%',
    // display: 'flex',
    // flexDirection: 'column',
  },
});

const FiltersCardComponent = (props: Props) => {
  const theme = useTheme();
  const isPortraitMobile = useMediaQueryPortraitMobile();
  const styles = getStyles(theme.palette, isPortraitMobile);

  return (
    <Box sx={styles.container}>
      <Card
        sx={{
          ...styles.cardRoot,
        }}
      >
        <CardContent sx={{ ...styles.cardContentRoot }}>
          <Stack
            direction="column"
            height="100%"
            spacing={4}
            sx={{ paddingTop: isPortraitMobile ? '1rem' : 0 }}
          >
            {props.children}
            <CloseFiltersDialogComponent />
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

export default FiltersCardComponent;
