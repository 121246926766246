import VisualChartWrapper from '../../../../components/charts/visual-chart-wrapper.component';
import { COMPANY_DIVIDENDS_TITLE_LABELS } from '../../utils/visuals.config';

const DividendYieldComponent = () => {
  return (
    <VisualChartWrapper
      dataLabel="dividendYield"
      pageLabel="stock"
      titleLabel={COMPANY_DIVIDENDS_TITLE_LABELS.dividendYield}
      type="line"
      useDollarsCurrency
      isTime
      useFiscalYear
    />
  );
};

export default DividendYieldComponent;
