import SideFiltersComponent from '../../components/filters/aggregated-filters/side-filters.component';
import PageSkeletonComponent, {
  ITab,
} from '../../components/page-skeleton/page-skeleton.component';
import { RouteEnum } from '../../navigation/routes.constants';
import OperatingExpensesTab from './tabs/operating-expenses.tab';
import ProfitMarginAndGrossMarginTab from './tabs/profit-margin-and-gross-margin.tab';
import RevenueAndProfitTab from './tabs/revenue-and-profit.tab';
import ISStatementsTab from './tabs/statements.tab';

export enum IncomeStatementAnalysisRouteEnum {
  REVENUE_AND_PROFIT = 'revenue-and-profit',
  PROFIT_MARGIN_AND_GROSS_MARGIN = 'profit-margin-and-gross-margin',
  OPERATING_EXPENSES = 'operating-expenses',
  INCOME_STATEMENT = 'income-statement',
}

const tabs: ITab[] = [
  {
    label: 'Revenue & Profit',
    route: IncomeStatementAnalysisRouteEnum.REVENUE_AND_PROFIT,
    component: RevenueAndProfitTab,
  },
  {
    label: 'Profit Margin & Gross Margin',
    route: IncomeStatementAnalysisRouteEnum.PROFIT_MARGIN_AND_GROSS_MARGIN,
    component: ProfitMarginAndGrossMarginTab,
  },
  {
    label: 'Operating Expenses',
    route: IncomeStatementAnalysisRouteEnum.OPERATING_EXPENSES,
    component: OperatingExpensesTab,
  },
  {
    label: 'Income Statement',
    route: IncomeStatementAnalysisRouteEnum.INCOME_STATEMENT,
    component: ISStatementsTab,
  },
];

const IncomeStatementAnalysisPage = () => {
  return (
    <PageSkeletonComponent
      pageRoute={RouteEnum.INCOME_STATEMENT_ANALYSIS}
      tabs={tabs}
      filtersComponent={SideFiltersComponent}
    />
  );
};

export default IncomeStatementAnalysisPage;
