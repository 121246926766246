import SideFiltersComponent from '../../components/filters/aggregated-filters/side-filters.component';
import PageSkeletonComponent, {
  ITab,
} from '../../components/page-skeleton/page-skeleton.component';
import { RouteEnum } from '../../navigation/routes.constants';
// const CurrentAssetAndLiabilitiesTab = lazy(
//   () => import('./tabs/current-asset-and-liabilities.tab')
// );
// const EquityTab = lazy(() => import('./tabs/equity.tab'));
// const NoncurrentAssetAndLiabilitiesTab = lazy(
//   () => import('./tabs/noncurrent-asset-and-liabilities.tab')
// );
// const OverviewTab = lazy(() => import('./tabs/overview.tab'));
// const BSStatementsTab = lazy(() => import('./tabs/statements.tab'));

import CurrentAssetAndLiabilitiesTab from './tabs/current-asset-and-liabilities.tab';
import EquityTab from './tabs/equity.tab';
import NoncurrentAssetAndLiabilitiesTab from './tabs/noncurrent-asset-and-liabilities.tab';
import OverviewTab from './tabs/overview.tab';
import BSStatementsTab from './tabs/statements.tab';

export enum BalanceSheetAnalysisRouteEnum {
  OVERVIEW = 'overview',
  CURRENT_ASSET_AND_LIABILITIES = 'current-asset-and-liabilities',
  NON_CURRENT_ASSET_AND_LIABILITIES = 'noncurrent-asset-and-liabilities',
  EQUITY = 'equity',
  BALANCE_SHEET = 'balance-sheet',
}

const tabs: ITab[] = [
  {
    label: 'Overview',
    route: BalanceSheetAnalysisRouteEnum.OVERVIEW,
    component: OverviewTab,
  },
  {
    label: 'Current Asset & Liabilities',
    route: BalanceSheetAnalysisRouteEnum.CURRENT_ASSET_AND_LIABILITIES,
    component: CurrentAssetAndLiabilitiesTab,
  },
  {
    label: 'Noncurrent Asset & Liabilities',
    route: BalanceSheetAnalysisRouteEnum.NON_CURRENT_ASSET_AND_LIABILITIES,
    component: NoncurrentAssetAndLiabilitiesTab,
  },
  {
    label: 'Equity',
    route: BalanceSheetAnalysisRouteEnum.EQUITY,
    component: EquityTab,
  },
  {
    label: 'Balance Sheet',
    route: BalanceSheetAnalysisRouteEnum.BALANCE_SHEET,
    component: BSStatementsTab,
  },
];

const BalanceSheetAnalysisPage = () => {
  return (
    <PageSkeletonComponent
      pageRoute={RouteEnum.BALANCE_SHEET_ANALYSIS}
      tabs={tabs}
      filtersComponent={SideFiltersComponent}
    />
  );
};

export default BalanceSheetAnalysisPage;
